import { useLingui } from "@lingui/react";
import { msg } from "@lingui/core/macro";
import styles from "./ActivitiesList.module.scss";
import NoResults from "@/components/NoResults/NoResults";
import ActivityTable from "./ActivityTable";
import { endOfDay, isPast } from "date-fns";
import { dateName } from "@/Utils/DateUtils";
import { Heading } from "@components/Heading/Heading";
import { format } from "@models/date-and-time";
import type { IActivityOccurrenceOrGroupWithPatient } from "@models/activities";

type IActivitiesList = {
  activityOccurrencesAndGroups: IActivityOccurrenceOrGroupWithPatient[];
  displayedDays: Date[];
  isPlaceholderData: boolean;
  patientName: string;
};

const ActivitiesList = ({
  activityOccurrencesAndGroups,
  displayedDays,
  isPlaceholderData,
  patientName,
}: IActivitiesList) => {
  const { _ } = useLingui();

  return (
    <ul
      aria-label={_(msg`Lista med aktiviteter`)}
      className={styles.activityOccurrencesTableList}
    >
      {displayedDays.map((day, index) => {
        const nameOfDay = dateName(day);
        const activityOccurrencesOnDay = activityOccurrencesAndGroups.filter(
          (activityOccurrencesAndGroups) =>
            format(activityOccurrencesAndGroups.start, "yyyy-MM-dd") ===
            format(day, "yyyy-MM-dd"),
        );

        // While fetching the previous day, we don't want to show the "no activities" message until we know.
        if (isPlaceholderData && index === 0) {
          return null;
        }

        // Only show "no activities" message for past days.
        if (activityOccurrencesOnDay.length === 0 && isPast(endOfDay(day))) {
          return (
            <li key={format(day, "yyyy-MM-dd")}>
              <NoResults
                message={_(
                  msg`${patientName} hade inga aktiviteter ${nameOfDay}`,
                )}
              />
            </li>
          );
        }

        // For later days with no activities, don't show anything.
        if (activityOccurrencesOnDay.length === 0) {
          return null;
        }

        return (
          <li
            key={format(day, "yyyy-MM-dd")}
            aria-labelledby={format(day, "yyyy-MM-dd")}
          >
            <Heading
              level="h2"
              id={format(day, "yyyy-MM-dd")}
              className={styles.dayHeading}
            >{`${dateName(day)} (${activityOccurrencesOnDay.length})`}</Heading>
            <ActivityTable
              activityOccurrencesAndGroups={activityOccurrencesAndGroups.filter(
                ({ start }) =>
                  format(start, "yyyy-MM-dd") === format(day, "yyyy-MM-dd"),
              )}
            />
          </li>
        );
      })}
    </ul>
  );
};

export { ActivitiesList };
