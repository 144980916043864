import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import {
  useActivity,
  activityStatusSchema,
  closeActivity,
  activityOccurrenceAndGroupKeys,
} from "@/api/Activities";
import { PlainButton } from "@components/Button/Button";
import CalendarEditIcon from "@components/icons/CalendarEditIcon";
import CalendarDeleteIcon from "@components/icons/CalendarDeleteIcon";
import RecurrenceIcon from "@components/icons/RecurrenceIcon";
import { deducedError, displayErrorMessageAlert } from "@/Utils/ErrorUtils";
import { useState } from "react";
import { useNavigate, useParams } from "react-router";
import { z } from "zod";
import styles from "./RecurringActivitySeries.module.scss";
import Chip from "@/components/Chips/Chip";
import { ActivityEndDateForm } from "@/forms/ActivityEndDateForm";
import { Dialog } from "@components/Dialog/Dialog";
import { ActivityInformation } from "./ActivityInformation";
import { Loading } from "@components/Loading/Loading";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import * as Sentry from "@sentry/react";
import { Text } from "@components/Text/Text";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const RecurringActivitySeries = () => {
  const [activityEndDateDialogIsOpen, setActivityEndDateDialogIsOpen] =
    useState(false);

  const { activityId, occurrenceId } = z
    .object({
      activityId: z.string(),
      occurrenceId: z.string(),
    })
    .parse(useParams());

  const { data: activity, isPending, isError, error } = useActivity(activityId);

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutate: closeActivityMutation, isPending: isClosingActivity } =
    useMutation({
      mutationFn: ({ activityId }: { activityId: string }) =>
        closeActivity(activityId),
      onError: (error) => {
        displayErrorMessageAlert(
          `${t`Gick inte att stänga aktiviteten.`} ${deducedError(error)}`,
        );
      },
      onSuccess: async () => {
        // This component is always rendered in the context of a list.
        // Once removal is done, we want to refresh the list, and then navigate to it.
        // Notably, we don't invalidate the activity occurrence detail, since that causes NotFound errors.
        await queryClient.invalidateQueries({
          queryKey: activityOccurrenceAndGroupKeys.lists(),
        });
        // Important to navigate back to the list URL since we can't display the detail view any more.
        // See `index.tsx` for route structure.
        navigate("../..");
      },
    });

  if (isPending) {
    return <Loading message={t`Hämtar aktivitet`} padding={24} />;
  }

  if (isError) {
    Sentry.captureException(error);
    return <ErrorMessage message={deducedError(error)} padding={24} />;
  }

  return (
    <>
      <>
        <div className={styles.activityInfoHeader}>
          <Chip iconStart={<RecurrenceIcon />}>
            <Trans>Aktivitetsserie</Trans>
          </Chip>
          {activity.status === activityStatusSchema.Values.active ? (
            <>
              <PlainButton
                size="small"
                weight="light"
                onClick={() => setActivityEndDateDialogIsOpen(true)}
              >
                <CalendarEditIcon />
                <Trans>Ändra slutdatum</Trans>
              </PlainButton>
              <PlainButton
                size="small"
                weight="light"
                onClick={() => closeActivityMutation({ activityId })}
                disabled={isClosingActivity}
              >
                <CalendarDeleteIcon />
                <Trans>Avsluta nu</Trans>
              </PlainButton>
            </>
          ) : (
            <Text element="div" size="small">
              <Trans>Aktivitetsserien är avslutad</Trans>
            </Text>
          )}
        </div>
      </>
      <ActivityInformation
        activityId={activityId}
        occurrenceId={occurrenceId}
        isRecurringTab={true}
      />
      <Dialog
        isOpen={activityEndDateDialogIsOpen}
        onClose={() => setActivityEndDateDialogIsOpen(false)}
        title={t`Slutdatum för aktivitetsserie`}
      >
        {activityEndDateDialogIsOpen ? (
          <ActivityEndDateForm
            activityId={activityId}
            onSubmitSuccess={() => setActivityEndDateDialogIsOpen(false)}
          />
        ) : (
          <></>
        )}
      </Dialog>
    </>
  );
};

export default RecurringActivitySeries;
