import { t } from "@lingui/core/macro";
import type {
  IActivityCategory,
  IActivityOccurrence,
  IActivityOccurrenceStatus,
  IHomeVisitActivityOccurrenceWithoutPatientAndAssignees,
} from "@models/activities";
import {
  activityOccurrenceStatusSchema,
  categorySchema,
  getActivityOccurrenceStatusTimestamp,
  timeOfDaySchema,
  visitStatusSchema,
} from "@models/activities";
import { DetailsPopover } from "../DetailsPopover/DetailsPopover";
import styles from "./TimelineTile.module.scss";
import clsx from "clsx";
import { ActivityDetails, Details } from "./Details";
import type { IVisit, IVisitStatus } from "@models/visits";
import { calculateVisitTimes, getVisitStatusTimestamp } from "@models/visits";
import { StatusTag } from "@/components/StatusTag/StatusTag";
import { ActivityTitle } from "@/components/ActivityTitle/ActivityTitle";
import { useLocation } from "react-router";
import type { IStatus } from "@/components/StatusTag/statusTagUtils";
import type { IAllocatableItemType } from "../Planning";
import { DragHandle } from "@/components/DragAndDrop/DragHandle";
import { useRef } from "react";
import { useLingui } from "@lingui/react";
import { patientStatusDictionary, patientStatusSchema } from "@models/patients";
import { getUnfulfilledRequirements } from "@/pages/commandcenter/helpers";
import { CategoryIcon } from "@/components/CategoryIcon/CategoryIcon";
import { Text } from "@components/Text/Text";
import { TimeSpan } from "@/components/Time/TimeSpan";
import { i18n } from "@lingui/core";
import WarningTriangleIcon from "@components/icons/WarningTriangleIcon";
import { Trans } from "@lingui/react/macro";

type IVariant = "default" | "compact";

type ITile = {
  category: IActivityCategory;
  variant: IVariant;
  status: IStatus;
  statusTimestamp?: Date;
  patientName?: string;
  title?: string;
  timespan:
    | { start: Date; end: Date }
    | typeof timeOfDaySchema.Values.Any
    | undefined;
  showIndicator?: boolean;
  activities?:
    | IActivityOccurrence[]
    | IHomeVisitActivityOccurrenceWithoutPatientAndAssignees[];
};

const Tile = ({
  category,
  variant,
  status,
  statusTimestamp,
  patientName,
  title,
  timespan,
  showIndicator,
  activities,
}: ITile) => {
  const { _ } = useLingui();

  const statusesToShow = [
    visitStatusSchema.Values.travellingTo,
    visitStatusSchema.Values.ongoing,
    visitStatusSchema.Values.finished,
    activityOccurrenceStatusSchema.Values.ongoing,
    activityOccurrenceStatusSchema.Values.finished,
  ];
  const showStatus = statusesToShow.find((item) => item === status);

  const queryParams = useLocation().search;

  return (
    <div className={styles.innerTileContainer}>
      <div className={styles.category}>
        <CategoryIcon category={category} size="tiny" />
      </div>
      <div className={styles.patientOrTitleAndWarning}>
        {patientName ? (
          <Text element="div" size="small">
            {patientName}
          </Text>
        ) : title ? (
          <Text element="div" size="small" color="faded">
            {title}
          </Text>
        ) : null}
        {showIndicator && (
          <div className={styles.warningIcon}>
            <WarningTriangleIcon />
          </div>
        )}
      </div>
      {variant === "default" && activities && activities.length > 0 ? (
        <ul className={styles.activities}>
          {activities.map((activity) => (
            <li key={activity.id}>
              <ActivityTitle
                activityOccurrence={activity}
                linkTo={`activities/${activity.activityId}/occurrences/${activity.id}${queryParams}`}
                weight="regular"
                size="small"
                showStatus={
                  activity.status ===
                  activityOccurrenceStatusSchema.Values.expired
                }
              />
            </li>
          ))}
        </ul>
      ) : null}
      <div className={styles.status}>
        {showStatus ? (
          <StatusTag
            status={status}
            variant="icon"
            timestamp={statusTimestamp}
            size="tiny"
          />
        ) : null}
      </div>
      <div className={styles.time}>
        {showStatus && statusTimestamp ? (
          <Text
            element="div"
            size={variant === "default" ? "small" : "smallest"}
          >
            {i18n.date(statusTimestamp, { timeStyle: "short" })}
          </Text>
        ) : timespan ? (
          <TimeSpan
            timespan={timespan}
            size={variant === "default" ? "small" : "smallest"}
            color="faded"
          />
        ) : (
          <Trans>Tid saknas</Trans>
        )}
      </div>
    </div>
  );
};

const tileClassName = (
  status: IActivityOccurrenceStatus | IVisitStatus,
  variant: IVariant,
  itemType: Exclude<IAllocatableItemType, undefined>,
) => {
  return clsx(
    styles.timeLineTile,
    styles[`variant-${variant}`],
    status === activityOccurrenceStatusSchema.Values.finished && styles.faded,
    styles[itemType],
  );
};

export const TimelineVisitTile = ({
  visit,
  variant,
  routeId,
  isExamining,
  stopExamining,
  isAloneInRoute,
}: {
  visit: IVisit;
  variant: IVariant;
  routeId: string;
  isExamining: boolean;
  stopExamining: (isOpen: boolean) => void;
  isAloneInRoute: boolean;
}) => {
  const { _ } = useLingui();
  const { status, occurrences, patient } = visit;
  const statusTimestamp = getVisitStatusTimestamp(visit);
  const { start, end, isAnyTimeOfDay } = calculateVisitTimes(visit);
  const visitHasWellDefinedTime = start && end;
  const visitHasExpiredActivities = occurrences.some(
    (activity) => activity.status === "expired",
  );
  const triggerRef = useRef(null);
  const patientName =
    patient.status === patientStatusSchema.Values.deleted
      ? _(patientStatusDictionary.deleted.singular)
      : patient.name;
  const unfulfilledRequirements = getUnfulfilledRequirements(visit);

  const hasUnfulfilledRequirementsToShow =
    unfulfilledRequirements.length > 0 &&
    status !== visitStatusSchema.Values.finished;

  return (
    <>
      <article
        ref={triggerRef}
        className={tileClassName(status, variant, "group")}
      >
        {!isAloneInRoute && <DragHandle />}
        <Tile
          category={categorySchema.Values.HomeVisit}
          variant={variant}
          status={status}
          statusTimestamp={statusTimestamp}
          patientName={patientName}
          timespan={
            visitHasWellDefinedTime
              ? { start, end }
              : isAnyTimeOfDay
                ? timeOfDaySchema.Values.Any
                : undefined
          }
          showIndicator={
            visitHasExpiredActivities || hasUnfulfilledRequirementsToShow
          }
          activities={occurrences}
        />
      </article>
      <DetailsPopover
        dialogProps={{ "aria-label": t`Hembesök för ${patientName}` }}
        popoverProps={{
          placement: "start",
          triggerRef: triggerRef,
          isOpen: isExamining,
          onOpenChange: stopExamining,
        }}
      >
        <Details visit={visit} routeId={routeId} />
      </DetailsPopover>
    </>
  );
};

export const TimelineActivityTile = ({
  activityOccurrence,
  variant,
  isExamining,
  stopExamining,
}: {
  activityOccurrence: IActivityOccurrence;
  variant: IVariant;
  isExamining: boolean;
  stopExamining: (isOpen: boolean) => void;
}) => {
  const { _ } = useLingui();
  const triggerRef = useRef(null);

  const { category, start, end, status, patient, timeOfDay } =
    activityOccurrence;
  const statusTimestamp =
    getActivityOccurrenceStatusTimestamp(activityOccurrence);
  const patientName = patient
    ? patient.status === patientStatusSchema.Values.deleted
      ? _(patientStatusDictionary.deleted.singular)
      : patient.name
    : undefined;

  const unfulfilledRequirements =
    "assignees" in activityOccurrence
      ? getUnfulfilledRequirements({
          assignees: activityOccurrence.assignees,
          occurrences: [activityOccurrence],
        })
      : null;

  const hasUnfulfilledRequirementsToShow =
    unfulfilledRequirements !== null &&
    unfulfilledRequirements.length > 0 &&
    status !== activityOccurrenceStatusSchema.Values.finished;

  return (
    <>
      <article
        ref={triggerRef}
        className={tileClassName(status, variant, "activityOccurrence")}
      >
        <Tile
          category={category}
          variant={variant}
          status={status}
          statusTimestamp={statusTimestamp}
          patientName={patientName}
          title={activityOccurrence.title}
          timespan={
            timeOfDay === timeOfDaySchema.Values.Any
              ? timeOfDay
              : { start, end }
          }
          activities={[activityOccurrence]}
          showIndicator={
            activityOccurrence.status ===
              activityOccurrenceStatusSchema.Values.expired ||
            hasUnfulfilledRequirementsToShow
          }
        />
      </article>
      <DetailsPopover
        dialogProps={{
          "aria-label": patientName
            ? t`Öppna aktivitet för ${patientName}`
            : t`Öppna aktivitet`,
        }}
        popoverProps={{
          placement: "start",
          triggerRef: triggerRef,
          isOpen: isExamining,
          onOpenChange: stopExamining,
        }}
      >
        <ActivityDetails activityOccurrence={activityOccurrence} />
      </DetailsPopover>
    </>
  );
};
