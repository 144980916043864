import type { IActivityOccurrenceOrGroup } from "@models/activities";
import { isGroup as isGroupCheck, timeOfDaySchema } from "@models/activities";
import styles from "./AllocatableItemTile.module.scss";
import { CompetenceChip } from "@/components/Chips/CompetenceChip";
import { Text } from "@components/Text/Text";
import { TimeSpan } from "@/components/Time/TimeSpan";
import { DragHandle } from "@/components/DragAndDrop/DragHandle";
import clsx from "clsx";
import { getPatientNameWithStatus } from "@/api/Patients";
import { RequirementChip } from "@/components/Chips/RequirementChip";
import { activityRequirementSchema } from "@/api/Activities";
import { CategoryIcon } from "@/components/CategoryIcon/CategoryIcon";
import CaretDownIcon from "@components/icons/CaretDownIcon";
import { Trans } from "@lingui/react/macro";

export const AllocatableItemTile = ({
  activityOccurrenceOrGroup,
  isSelected,
}: {
  activityOccurrenceOrGroup: IActivityOccurrenceOrGroup;
  isSelected: boolean;
}) => {
  const { start, end, timeOfDay, category } = activityOccurrenceOrGroup;
  const isGroup = isGroupCheck(activityOccurrenceOrGroup);

  const requiredCompetences =
    "requiredCompetences" in activityOccurrenceOrGroup
      ? activityOccurrenceOrGroup.requiredCompetences
      : undefined;

  const doubleStaffing =
    "doubleStaffing" in activityOccurrenceOrGroup
      ? activityOccurrenceOrGroup.doubleStaffing
      : undefined;

  const occurrences = isGroup
    ? activityOccurrenceOrGroup.occurrences
    : undefined;

  const groupHasMoreThanOneOccurrence = occurrences
    ? occurrences.length > 1
    : false;

  const patientName = isGroup
    ? getPatientNameWithStatus(activityOccurrenceOrGroup.patient)
    : activityOccurrenceOrGroup.patient
      ? getPatientNameWithStatus(activityOccurrenceOrGroup.patient)
      : null;

  const activityOccurrenceLabel = isGroup
    ? occurrences
      ? occurrences.length > 1
        ? `${occurrences.length} aktiviteter`
        : occurrences[0]
          ? occurrences[0].title
          : null
      : null
    : activityOccurrenceOrGroup.title;

  return (
    <div
      className={clsx(
        styles.tileContainer,
        isSelected ? styles.selected : undefined,
      )}
    >
      <DragHandle />
      <div className={styles.innerTileContainer}>
        <div className={styles.category}>
          <CategoryIcon category={category} size="tiny" />
        </div>
        {activityOccurrenceLabel ? (
          <div className={styles.title}>
            <Text element="span" size="small" color="faded">
              {activityOccurrenceLabel}
            </Text>
          </div>
        ) : null}
        <div className={styles.patient}>
          <Text element="span" size="small">
            {patientName ?? <Trans>Ingen patient</Trans>}
          </Text>
        </div>
        {(requiredCompetences && requiredCompetences.length > 0) ||
        doubleStaffing ? (
          <ul className={styles.competences}>
            {requiredCompetences && requiredCompetences.length > 0
              ? requiredCompetences.map((competence) => (
                  <li key={competence}>
                    <CompetenceChip
                      competence={competence}
                      state="neutral"
                      key={competence}
                      size="tiny"
                    />
                  </li>
                ))
              : null}
            {doubleStaffing ? (
              <li>
                <RequirementChip
                  requirement={activityRequirementSchema.Values.DoubleStaffing}
                  state="neutral"
                  size="tiny"
                />
              </li>
            ) : null}
          </ul>
        ) : null}
        <div className={styles.time}>
          <TimeSpan
            timespan={
              timeOfDay === timeOfDaySchema.Values.Any
                ? timeOfDay
                : { start, end }
            }
          />
        </div>
        {/* Show an arrow as an indicator that activities are displayed on click */}
        {/* Note: Click event is handled higher up */}
        {isGroup && groupHasMoreThanOneOccurrence ? (
          <div className={styles.arrow}>
            <CaretDownIcon />
          </div>
        ) : null}
        {isSelected ? (
          occurrences && groupHasMoreThanOneOccurrence ? (
            <ul className={styles.occurrencesList}>
              {occurrences.map((occurrence) => (
                <li key={`${occurrence.id} ${occurrence.activityId}`}>
                  <Text
                    element="span"
                    size="small"
                    key={occurrence.id}
                    color="faded"
                  >
                    {occurrence.title}
                  </Text>
                </li>
              ))}
            </ul>
          ) : null
        ) : null}
      </div>
    </div>
  );
};
