import { FormProvider, useForm } from "react-hook-form";
import Form from "@/components/Form/Form";
import { FilledButton } from "@components/Button/Button";
import { Trans } from "@lingui/react/macro";
import {
  DateInput,
  type IDateInputFields,
} from "./AddActivityForm/Scheduling/DateInput";
import {
  TimeSlotsPicker,
  type ITimeSlotsPickerFields,
} from "./AddActivityForm/Scheduling/TimeSlotsPicker";
import {
  timeOfDaySchema,
  type IGroupOfHomeVisitActivityOccurrence,
  type ITimeOfDay,
} from "@models/activities";
import Checkbox from "@/components/Checkbox/Checkbox";
import { t } from "@lingui/core/macro";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  ANY_TIME_OF_DAY,
  rescheduleActivityOccurrence,
  type IRescheduledActivityOccurrence,
} from "@/api/Activities";
import { getTimespan } from "./AddActivityForm/activityTimeUtils";
import { deducedError, displayErrorMessageAlert } from "@/Utils/ErrorUtils";
import { Spinner } from "@components/Spinner/Spinner";
import { format } from "@models/date-and-time";
import { Loading } from "@components/Loading/Loading";
import { useState } from "react";

type IEditGroupTimeFormFields = IDateInputFields &
  ITimeSlotsPickerFields & { editTime: string };

const EditGroupTimeForm = ({
  occurrences,
  currentTimeCategory,
}: {
  occurrences: IGroupOfHomeVisitActivityOccurrence["occurrences"];
  currentTimeCategory: ITimeOfDay;
}) => {
  const methods = useForm<IEditGroupTimeFormFields>({
    defaultValues: {
      timeCategory: currentTimeCategory,
      recurrencesPerDay: "1", // Required to show a time slot input
    },
  });

  const [occurrencesRemaining, setOccurrencesRemaining] = useState(
    occurrences.length,
  );

  const queryClient = useQueryClient();
  const { mutate: editDateAndTime, isPending } = useMutation({
    mutationFn: ({
      activityId,
      occurrenceId,
      newTimeFields,
    }: {
      activityId: string;
      occurrenceId: string;
      newTimeFields: IRescheduledActivityOccurrence;
    }) =>
      rescheduleActivityOccurrence(activityId, occurrenceId, newTimeFields, {
        delayRequestByMs: 5000,
      }),
    onError: (error, { activityId, occurrenceId }) => {
      const occurrence = occurrences.find(
        (occurrence) =>
          occurrence.id === occurrenceId &&
          occurrence.activityId === activityId,
      );
      const occurrenceTitle = occurrence?.title;
      const errorDisplayText = occurrenceTitle
        ? t`Gick inte att byta tid på "${occurrenceTitle}"`
        : t`Gick inte att byta tid på aktivitetstillfället`;

      displayErrorMessageAlert(`${errorDisplayText}. ${deducedError(error)}`);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries();
      setOccurrencesRemaining((prev) => prev - 1);
    },
    // Setting a 'scope' makes mutations run in serial, see:
    // https://tanstack.com/query/latest/docs/framework/react/guides/mutations#mutation-scopes
    // MED-5093: At this point, groups can't handle multiple acitivty occurrences
    // being rescheduled right after each other, so we need to run them in serial
    // This is a temporary fix until the backend is fixed.
    scope: { id: "editGroupTime" },
  });

  if (isPending) {
    return (
      <Loading
        message={t`Flyttar aktiviteter till ny tid (${occurrencesRemaining} återstår)`}
      />
    );
  }

  const { register, watch, handleSubmit } = methods;

  const showTimeFields = watch("editTime");

  const validateAndSubmit = handleSubmit((formData) => {
    const { startDate, timeCategory, timeslots, timeSensitivity, editTime } =
      formData;

    // If we're not editing the time, we just want to update the date, and keep all time configurations as they are.
    if (!editTime) {
      occurrences.forEach(({ activityId, id, start, timeOfDay }) => {
        const time =
          timeOfDay === timeOfDaySchema.Values.Any
            ? ANY_TIME_OF_DAY
            : /* eslint-disable-next-line lingui/no-unlocalized-strings */
              format(start, "HH:mm");

        editDateAndTime({
          activityId,
          occurrenceId: id,
          newTimeFields: { date: startDate, time },
        });
      });

      return;
    }

    const isAnyTimeOfDay = timeCategory === timeOfDaySchema.Values.Any;
    const timespan = getTimespan({
      isAnyTimeOfDay,
      timeSensitivity,
    });

    const timeFields = {
      time: isAnyTimeOfDay
        ? ANY_TIME_OF_DAY
        : // @ts-expect-error we know that there is exactly one timeslot, but TS doesn't.
          timeslots[0].time,
    }; // We only have one timeslot if it's not recurring.

    occurrences.forEach(({ activityId, id }) => {
      editDateAndTime({
        activityId,
        occurrenceId: id,
        newTimeFields: { date: startDate, ...timeFields, span: timespan },
      });
    });
  });

  return (
    <FormProvider {...methods}>
      <Form onSubmit={validateAndSubmit}>
        <DateInput hasEndDate={false} />
        <Checkbox
          {...register("editTime")}
          label={{ text: t`Justera tiden` }}
        />
        {showTimeFields ? <TimeSlotsPicker isRecurring={false} /> : null}
        <FilledButton type="submit" disabled={isPending}>
          {isPending ? <Spinner /> : null}
          <Trans>Spara ändringar</Trans>
        </FilledButton>
      </Form>
    </FormProvider>
  );
};

export default EditGroupTimeForm;
