import { useEffect, useRef, useState } from "react";

const useResizablePanels = ({
  layout = "horizontal",
  initialLeadingPanelSize = "50%",
  minPanelSize = 300,
}: {
  layout: "horizontal" | "vertical";
  initialLeadingPanelSize?: string | number;
  minPanelSize?: number;
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const leadingPanelRef = useRef<HTMLDivElement>(null);
  const handleRef = useRef<HTMLDivElement>(null);
  const isResizingRef = useRef(false);
  const [leadingPanelSize, setLeadingPanelSize] = useState<string | number>(
    initialLeadingPanelSize,
  );

  useEffect(() => {
    const container = containerRef.current;
    const leadingPanel = leadingPanelRef.current;
    const handle = handleRef.current;

    if (!container || !leadingPanel || !handle) return;

    const startResizing = (event: MouseEvent) => {
      event.preventDefault(); // Prevents text selection
      isResizingRef.current = true;
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", stopResizing);
    };

    const handleMouseMove = (event: MouseEvent) => {
      if (!isResizingRef.current || !container) return;

      const containerRect = container.getBoundingClientRect();
      let newSize =
        layout === "vertical"
          ? event.clientY - containerRect.top
          : event.clientX - containerRect.left;

      newSize = Math.max(
        minPanelSize,
        Math.min(
          (layout === "vertical" ? containerRect.height : containerRect.width) -
            minPanelSize,
          newSize,
        ),
      );

      setLeadingPanelSize(newSize);
    };

    const stopResizing = () => {
      isResizingRef.current = false;
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", stopResizing);
    };

    handle.addEventListener("mousedown", startResizing);

    return () => {
      handle.removeEventListener("mousedown", startResizing);
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", stopResizing);
    };
  }, [minPanelSize, layout]);

  useEffect(() => {
    if (leadingPanelRef.current) {
      if (layout === "vertical") {
        leadingPanelRef.current.style.height =
          typeof leadingPanelSize === "string"
            ? leadingPanelSize
            : `${leadingPanelSize}px`;
        leadingPanelRef.current.style.width = "100%"; // Ensure full width in vertical layout
      } else {
        leadingPanelRef.current.style.width =
          typeof leadingPanelSize === "string"
            ? leadingPanelSize
            : `${leadingPanelSize}px`;
        leadingPanelRef.current.style.height = "100%"; // Ensure full height in horizontal layout
      }
    }
  }, [leadingPanelSize, layout]);

  // Reset the initial size when the layout changes
  useEffect(() => {
    setLeadingPanelSize(initialLeadingPanelSize);
  }, [layout, initialLeadingPanelSize]);

  return { containerRef, leadingPanelRef, handleRef, leadingPanelSize };
};

export default useResizablePanels;
