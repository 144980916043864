import { plural } from "@lingui/core/macro";
import { activityFrequencySchema } from "@/forms/AddActivityForm/Scheduling/schedulingModels";
import type { IUnitAlerisAhcTemplate } from "./templatesAlerisAhc";
import { RecurrenceChip } from "@/components/Chips/RecurrenceChip";
import Chip from "@/components/Chips/Chip";
import { CategoryIcon } from "@/components/CategoryIcon/CategoryIcon";
import styles from "./TemplateHeadingAlerisAhc.module.scss";
import { Heading } from "@components/Heading/Heading";
import { Text } from "@components/Text/Text";
import {
  categorySchema,
  measurementsDictionary,
  timeOfDayDictionary,
  timeOfDaySchema,
} from "@models/activities";
import HiddenIcon from "@components/icons/HiddenIcon";
import { PatientChip } from "@/components/Chips/PatientChip";
import { RequirementChip } from "@/components/Chips/RequirementChip";
import { useLingui } from "@lingui/react/macro";
import InvoicingBillIcon from "@components/icons/InvoicingBillIcon";
import { getRecurrenceLabel } from "../templateUtils";

const formatter = new Intl.ListFormat(navigator.language, {
  style: "long",
  type: "conjunction",
});

const TimeDetails = ({ template }: { template: IUnitAlerisAhcTemplate }) => {
  const { t } = useLingui();
  const isRecurring =
    template.frequency === activityFrequencySchema.Values.recurring;
  const isAnyTimeOfDay = template.timeCategory === timeOfDaySchema.Values.Any;

  const timeLabel = isAnyTimeOfDay
    ? t(timeOfDayDictionary.Any.long)
    : undefined;

  const recurrenceLabelText = getRecurrenceLabel({
    recurrence: template.recurrence,
    customRecurrenceInterval: template.customRecurrenceInterval,
    customRecurrenceIntervalUnit: template.customRecurrenceIntervalUnit,
    weekdays: template.weekdays,
  });

  if (isRecurring && recurrenceLabelText) {
    return <RecurrenceChip recurrenceLabel={recurrenceLabelText} />;
  }

  if (timeLabel !== undefined) {
    return <Chip>{timeLabel}</Chip>;
  }

  return null;
};

const NumberOfOccurrencesInHeader = ({
  numberOfTimeSlots,
}: {
  numberOfTimeSlots: number;
}) => {
  return (
    <Text element="span">
      (
      {plural(numberOfTimeSlots, {
        one: "# tillfälle",
        other: "# tillfällen",
      })}
      )
    </Text>
  );
};

export const TemplateHeadingAlerisAhc = ({
  template,
  variant = "title-and-info",
}: {
  template: IUnitAlerisAhcTemplate;
  variant?: "title-only" | "title-and-info";
}) => {
  const hasRequirements = template.requiredCompetences.length > 0;
  const isPerformedByPatient =
    template.category === categorySchema.Values.PatientTask ||
    template.category === categorySchema.Values.PatientMeasurementTask;

  const { t } = useLingui();

  return (
    <div className={styles.heading}>
      <CategoryIcon category={template.category} size="small" />
      <div className={styles.titleAndChips}>
        <div className={styles.titleAndDescription}>
          <Heading level="h3" weight="regular">
            {template.title}
          </Heading>
          {/* Special cases where title needs extra description */}
          {"measurements" in template && template.measurements.length > 0 ? (
            <Text element="span">
              (
              {formatter.format(
                template.measurements.map((measurement) =>
                  t(measurementsDictionary[measurement]),
                ),
              )}
              )
            </Text>
          ) : null}
          {template.templateOptions.showNumberOfOccurrencesInHeader &&
          "timeslots" in template ? (
            <NumberOfOccurrencesInHeader
              // With better types we wouldn't need this length check, but we haven't made TS aware that "showNumberOfOccurrencesInHeader" and "timeslots" should always be in sync
              numberOfTimeSlots={template.timeslots?.length ?? 0}
            />
          ) : null}
          {/* End */}
          {template.hidden ? <HiddenIcon /> : null}
          {"invoicingCodes" in template ? <InvoicingBillIcon /> : null}
        </div>
        {variant === "title-and-info" ? (
          <div className={styles.chips}>
            <TimeDetails template={template} />
            {isPerformedByPatient ? <PatientChip /> : null}
            {hasRequirements ? (
              template.requiredCompetences.map((competence) => (
                <RequirementChip
                  key={competence}
                  requirement={competence}
                  state="neutral"
                />
              ))
            ) : (
              <></>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export const GroupTemplateHeadingAleris = ({ title }: { title: string }) => (
  <div className={styles.heading}>
    <CategoryIcon category="MultipleCategories" size="small" />
    <div className={styles.titleAndDescription}>
      <Heading level="h3" weight="regular">
        {title}
      </Heading>
    </div>
  </div>
);
