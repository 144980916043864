import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import { deducedError } from "@/Utils/ErrorUtils";
import {
  activityRequirementSchema,
  type IActivityRequirement,
} from "@/api/Activities";
import { useScheduledShifts } from "@/api/Shifts";
import DropdownMenu from "@/components/DropdownMenu/DropdownMenu";
import NoResults from "@/components/NoResults/NoResults";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import { Loading } from "@components/Loading/Loading";
import AddPersonIcon from "@components/icons/AddPersonIcon";
import type { IMedicalCompetence, IScheduledShift } from "@models/shifts";
import {
  calculateAvailableShifts,
  medicalCompetenceDictionary,
  shiftName,
  shiftTimeDisplayValues,
} from "@models/shifts";
import styles from "./ShiftDropdownMenu.module.scss";
import * as Sentry from "@sentry/react";
import { formattedTimeSpan } from "@/components/Time/timeUtils";
import { Text } from "@components/Text/Text";
import { useLingui } from "@lingui/react";
import type { IBaseButton } from "@components/Button/Button";

const Shift = ({
  employee,
  competence,
  startDateTime,
  endDateTime,
  selectedDate,
}: Pick<
  IScheduledShift,
  "employee" | "competence" | "startDateTime" | "endDateTime"
> & { selectedDate: Date }) => {
  const {
    startedDayBefore,
    hasEndTimeAfterToday,
    formattedDayBefore,
    formattedDayToday,
  } = shiftTimeDisplayValues({
    selectedDate,
    startDateTime,
    endDateTime,
  });

  const additionalContext = `${startedDayBefore ? t`Start ${formattedDayBefore}` : hasEndTimeAfterToday ? t`Start ${formattedDayToday}` : ""}`;

  return (
    <>
      <span className={styles.shift}>
        <Text element="p" size="base">{`${shiftName({
          employee,
          competence,
          options: { length: "long" },
        })}`}</Text>
        <Text element="p" color="faded" size="small">
          {`${additionalContext}${additionalContext ? " " : ""}${formattedTimeSpan(startDateTime, endDateTime)} `}
        </Text>
      </span>
    </>
  );
};

export const ShiftDropdownMenu = ({
  assignedShiftIds,
  unfulfilledRequirements,
  onAllocate,
  isAllocating = false,
  shiftsFromDate,
  shiftsUntilDate = shiftsFromDate,
  size = "medium",
  triggerType = "icon",
}: {
  assignedShiftIds: number[];
  unfulfilledRequirements: IActivityRequirement[];
  onAllocate(shiftIdToAdd: number): void;
  isAllocating?: boolean;
  shiftsFromDate: Date;
  shiftsUntilDate?: Date;
  size?: IBaseButton["size"];
  triggerType?: "icon" | "icon-and-text";
}) => {
  const { _ } = useLingui();
  const {
    data: scheduledShifts,
    isPending,
    isError,
    error,
  } = useScheduledShifts(shiftsFromDate, shiftsUntilDate);

  const availableShifts = calculateAvailableShifts({
    scheduledShifts: scheduledShifts ?? [],
    assignedShiftIds,
  });

  const unfulfilledMedicalRequirements = unfulfilledRequirements.filter(
    (unfulfilledRequirement) =>
      unfulfilledRequirement !==
      activityRequirementSchema.Values.DoubleStaffing,
  );

  const otherShifts = availableShifts.filter(
    ({ competence }) => !unfulfilledRequirements.includes(competence),
  );

  const ErrorDropdownItem = () => {
    Sentry.captureException(error);
    return (
      <DropdownMenu.Item
        content={
          <ErrorMessage
            message={`${t`Gick inte att hämta arbetspassen.`} ${deducedError(error)}`}
          />
        }
      />
    );
  };

  return (
    <DropdownMenu
      trigger={{
        icon: <AddPersonIcon />,
        ...(triggerType === "icon-and-text"
          ? { text: t`Lägg till` }
          : { ariaLabel: t`Lägg till personal` }),
        disabled: isAllocating,
        size: size,
      }}
    >
      {isPending ? (
        <DropdownMenu.Item
          content={<Loading message={t`Laddar arbetspass`} />}
        />
      ) : isError ? (
        <ErrorDropdownItem />
      ) : availableShifts.length === 0 ? (
        <DropdownMenu.Item
          content={<NoResults message={t`Ingen tillgänglig personal`} />}
        />
      ) : (
        <>
          {unfulfilledMedicalRequirements.map(
            (unfulfilledRequirement, index) => {
              const shiftsMatchingUnfulfilledMedicalRequirement =
                availableShifts.filter(
                  ({ competence }) => competence === unfulfilledRequirement,
                );
              return (
                <DropdownMenu.Group key={unfulfilledRequirement}>
                  <DropdownMenu.Label>
                    {`${
                      // `filter` ensures `as` is safe
                      _(
                        medicalCompetenceDictionary[
                          unfulfilledRequirement as IMedicalCompetence
                        ].long,
                      )
                    } (${shiftsMatchingUnfulfilledMedicalRequirement.length})`}
                  </DropdownMenu.Label>
                  <>
                    {shiftsMatchingUnfulfilledMedicalRequirement.map(
                      ({
                        id,
                        employee,
                        startDateTime,
                        endDateTime,
                        competence,
                      }) => {
                        return (
                          <DropdownMenu.Item
                            action={() => {
                              onAllocate(id);
                            }}
                            content={
                              <Shift
                                competence={competence}
                                endDateTime={endDateTime}
                                startDateTime={startDateTime}
                                employee={employee}
                                selectedDate={shiftsFromDate}
                              />
                            }
                            key={id}
                          />
                        );
                      },
                    )}
                  </>
                  {index !== unfulfilledMedicalRequirements.length - 1 ||
                  otherShifts.length >= 1 ? (
                    <DropdownMenu.Separator />
                  ) : (
                    <></>
                  )}
                </DropdownMenu.Group>
              );
            },
          )}
          {otherShifts.length >= 1 ? (
            <DropdownMenu.Group>
              <>
                <DropdownMenu.Label>
                  <Trans>Övriga</Trans>
                </DropdownMenu.Label>
                {otherShifts.map(
                  ({
                    id,
                    employee,
                    startDateTime,
                    endDateTime,
                    competence,
                  }) => (
                    <DropdownMenu.Item
                      action={() => {
                        onAllocate(id);
                      }}
                      content={
                        <Shift
                          competence={competence}
                          endDateTime={endDateTime}
                          startDateTime={startDateTime}
                          employee={employee}
                          selectedDate={shiftsFromDate}
                        />
                      }
                      key={id}
                    />
                  ),
                )}
              </>
            </DropdownMenu.Group>
          ) : (
            <></>
          )}
        </>
      )}
    </DropdownMenu>
  );
};
