import type { IWeekday } from "@/api/Activities";
import { ANY_TIME_OF_DAY } from "@/api/Activities";
import type {
  ICustomRecurrenceInterval,
  ICustomRecurrenceIntervalUnit,
  IRecurringOption,
} from "./Scheduling/schedulingModels";
import {
  customRecurrenceIntervalUnitSchema,
  recurrenceToNumberDictionary,
  recurringOptionSchema,
  timespanSchema,
} from "./Scheduling/schedulingModels";
import type { ITimeSensitivity } from "./Scheduling/TimeSensitivityPicker";
import { hoursToTimespan } from "./Scheduling/timeSlotsUtils";

const getCustomInterval = ({
  customRecurrenceInterval,
  customRecurrenceIntervalUnit,
}: {
  customRecurrenceInterval: ICustomRecurrenceInterval;
  customRecurrenceIntervalUnit: ICustomRecurrenceIntervalUnit;
}) => {
  if (
    customRecurrenceIntervalUnit ===
    customRecurrenceIntervalUnitSchema.Values.weeks
  ) {
    return parseInt(customRecurrenceInterval) * 7;
  }

  return parseInt(customRecurrenceInterval);
};

export const getTimespan = ({
  isAnyTimeOfDay,
  timeSensitivity,
}: {
  isAnyTimeOfDay: boolean;
  timeSensitivity: ITimeSensitivity | undefined;
}) => {
  // If anyTimeOfDay, backend controls the timespan. FE can pass any string atm.
  // If no timeSensitivity, default to 23:59:59
  if (isAnyTimeOfDay || !timeSensitivity) {
    return timespanSchema.parse("23:59:59");
  }

  return hoursToTimespan(timeSensitivity);
};

export const getTimeFields = ({
  isRecurringAsInterval,
  isAnyTimeOfDay,
  timeslots,
  weekdays,
  recurrence,
  customRecurrenceInterval,
  customRecurrenceIntervalUnit,
}: {
  isRecurringAsInterval: boolean;
  weekdays: IWeekday[] | undefined;
  recurrence: IRecurringOption;
  customRecurrenceInterval: ICustomRecurrenceInterval | null;
  customRecurrenceIntervalUnit: ICustomRecurrenceIntervalUnit | null;
} & (
  | {
      isAnyTimeOfDay: true;
      timeslots: undefined;
    }
  | {
      isAnyTimeOfDay: false;
      timeslots: { time: string }[];
    }
)) => {
  const timeFields = isRecurringAsInterval
    ? {
        times: isAnyTimeOfDay
          ? ANY_TIME_OF_DAY
          : timeslots.map(({ time }) => time),
        interval:
          recurrence === recurringOptionSchema.Values.custom &&
          customRecurrenceInterval &&
          customRecurrenceIntervalUnit
            ? getCustomInterval({
                customRecurrenceInterval,
                customRecurrenceIntervalUnit,
              })
            : recurrenceToNumberDictionary[recurrence],
      }
    : {
        times: isAnyTimeOfDay
          ? ANY_TIME_OF_DAY
          : timeslots.map(({ time }) => time),
        days: weekdays ?? [],
      };

  return timeFields;
};
