import { useFormContext } from "react-hook-form";
import { Trans, useLingui as useLinguiMacro } from "@lingui/react/macro";
import { useLingui } from "@lingui/react";
import InputField from "@/components/InputField/InputField";
import { PlainButton } from "@components/Button/Button";
import { Text } from "@components/Text/Text";
import styles from "./TimeSensitivityPicker.module.scss";
import { hoursToHoursAndMinutes } from "./timeSlotsUtils";

export type ITimeSensitivity = string;

export type ITimeSensitivityFields = {
  timeSensitivity: ITimeSensitivity;
};

export const TimeSensitivityPicker = () => {
  const { t } = useLinguiMacro();
  const { _ } = useLingui();
  const {
    register,
    setValue,
    clearErrors,
    watch,
    formState: { errors },
  } = useFormContext<ITimeSensitivityFields>();

  const QuickOptionButtons = ({
    options,
  }: {
    options: { label: string; value: string }[];
  }) => {
    return (
      <div className={styles.quickOptionButtons}>
        <Text element="div" size="smallest" color="faded">
          <Trans>Snabbval:</Trans>
        </Text>
        <div className={styles.buttons}>
          {options.map((option, index) => (
            <PlainButton
              key={index}
              onClick={() => {
                setValue("timeSensitivity", option.value);
                // setValue doesn't trigger validation, so we need to clear the error manually
                // At this point, we know that the field is filled correctly, so we can clear the error
                clearErrors("timeSensitivity");
              }}
              size="small"
            >
              {option.label}
            </PlainButton>
          ))}
        </div>
      </div>
    );
  };

  const displayHoursToMinutesConversion =
    parseFloat(watch("timeSensitivity")?.replace(",", ".")) >= 0;

  return (
    <div className={styles.timeSensitivityPicker}>
      <div className={styles.inputAndQuickOptions}>
        <InputField
          {...register("timeSensitivity", {
            required: {
              value: true,
              message: t`Du behöver ange en tidsflexibilitet`,
            },
            pattern: {
              value: /^0|[1-9]\d*(?:[.,]\d+)?$/,
              message: t`Använd endast siffror och decimaltecken`,
            },
            validate: (value) => {
              // Backend doesn't handle values >= 24 hours, so we can't allow that input
              if (parseFloat(value.replace(",", ".")) >= 24) {
                return t`Tidsflexibiliteten får inte överstiga 24 timmar`;
              }
              // We don't allow negative values
              if (parseFloat(value.replace(",", ".")) < 0) {
                return t`Tidsflexibiliteten kan inte vara mindre än 0 timmar`;
              }
              // We don't allow 0
              if (parseFloat(value.replace(",", ".")) === 0) {
                return t`Tidsflexibiliteten behöver vara mer än 0 timmar`;
              }
              return true;
            },
          })}
          label={t`Ska genomföras inom`}
          suffix={t`timmar`}
          showOptionalLabel={false}
          outerWidth="fit"
          inputWidth="8ch"
          errorMessage={errors.timeSensitivity?.message}
        />
        <QuickOptionButtons
          options={[
            { label: t`15 min`, value: "0,25" },
            { label: t`30 min`, value: "0,5" },
            { label: t`1 h`, value: "1" },
            { label: t`2 h`, value: "2" },
            { label: t`4 h`, value: "4" },
            { label: t`8 h`, value: "8" },
          ]}
        />
      </div>
      {displayHoursToMinutesConversion ? (
        <Text element="span" size="smallest" color="faded">
          = {hoursToHoursAndMinutes(watch("timeSensitivity"))}
        </Text>
      ) : undefined}
    </div>
  );
};
