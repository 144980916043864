import { Trans } from "@lingui/react/macro";
import styles from "./Reporting.module.scss";
import { Heading } from "@components/Heading/Heading";
import { useLingui } from "@lingui/react";
import { Outlet } from "react-router";
import { Tab, TabList, TabPanel } from "@components/Tabs";

const Header = () => {
  const { _ } = useLingui();

  return (
    <header className={styles.reportingPageHeader}>
      <Heading level="h1">
        <Trans>Rapportering</Trans>
      </Heading>

      <TabList>
        <Tab to="to-handle">
          <Trans>Att hantera</Trans>
        </Tab>
        <Tab to="pending">
          <Trans>På väg till GVR</Trans>
        </Tab>
      </TabList>
    </header>
  );
};

export const Reporting = () => {
  return (
    <div className={styles.reportingPage}>
      <Header />
      <div className={styles.reportingPageContent}>
        <TabPanel>
          <Outlet />
        </TabPanel>
      </div>
    </div>
  );
};
