import { t } from "@lingui/core/macro";
import RadioButton from "@/components/RadioButton/RadioButton";
import RadioButtons from "@/components/RadioButton/RadioButtons";
import { useFormContext } from "react-hook-form";
import { useLingui } from "@lingui/react";
import {
  activityFrequencyDictionary,
  activityFrequencySchema,
  type IActivityFrequency,
} from "./schedulingModels";

export type IFrequencyPickerFields = {
  frequency: IActivityFrequency;
};

export const FrequencyPicker = () => {
  const {
    formState: { errors },
    register,
  } = useFormContext<IFrequencyPickerFields>();

  const { _ } = useLingui();

  return (
    <RadioButtons
      legend={t`Frekvens`}
      errorMessage={errors.frequency?.message}
      orientation="horizontal"
    >
      <RadioButton
        label={{ text: _(activityFrequencyDictionary.oneTime) }}
        {...register("frequency", {
          required: {
            value: true,
            message: t`Upprepningsmönster måste sättas`,
          },
        })}
        value={activityFrequencySchema.Values.oneTime}
        visualStyle="framed"
      />
      <RadioButton
        label={{ text: _(activityFrequencyDictionary.recurring) }}
        {...register("frequency")}
        value={activityFrequencySchema.Values.recurring}
        visualStyle="framed"
      />
    </RadioButtons>
  );
};
