import { ActivityDescriptionPopover } from "@/components/ActivityDescriptionPopover/ActivityDescriptionPopover";
import styles from "./ActivityTitle.module.scss";
import { ActivityIsHiddenPopover } from "@/components/ActivityIsHiddenPopover/ActivityIsHiddenPopover";
import RecurrenceIcon from "@components/icons/RecurrenceIcon";
import { categorySchema, type IActivityOccurrence } from "@models/activities";
import { Link } from "@components/Link/Link";
import type { IText } from "@components/Text/Text";
import { Text, TextWithLineBreaks } from "@components/Text/Text";
import { StatusTag } from "../StatusTag/StatusTag";

// Should accept all types of activity occurrences.
// We omit some fields from the activity occurrence type to avoid
// unnecessary typing errors when using this component.
// If needed, omit more fields from the activity occurrence type.
type IActivityTitle = {
  activityOccurrence: Omit<IActivityOccurrence, "assignees">;
  linkTo?: string;
  showStatus?: boolean;
  alwaysShowDescription?: boolean;
} & Pick<IText, "weight" | "size">;

export const ActivityTitle = ({
  activityOccurrence,
  linkTo,
  weight,
  size,
  showStatus,
  alwaysShowDescription = false,
}: IActivityTitle) => {
  const {
    activityId,
    category,
    description,
    hidden: isHidden,
    recurring: isRecurring,
    title,
    status,
  } = activityOccurrence;

  const hasDescription =
    description !== undefined && description !== null && description !== "";

  const shouldShowTextDescription = hasDescription && alwaysShowDescription;
  const shouldShowIconDescription = hasDescription && !alwaysShowDescription;

  return (
    <>
      <div className={styles.titleAndIcons}>
        {showStatus ? <StatusTag status={status} size="tiny" /> : null}
        {linkTo ? (
          <Link
            to={linkTo}
            weight={weight}
            size={size}
            className={styles.linkText}
          >
            {title}
          </Link>
        ) : (
          <Text element="span" weight={weight} size={size}>
            {title}
          </Text>
        )}
        {isRecurring && <RecurrenceIcon />}
        {shouldShowIconDescription && (
          <ActivityDescriptionPopover description={description} />
        )}
        {isHidden && category !== categorySchema.Values.AdminTask && (
          <ActivityIsHiddenPopover
            activityId={activityId}
            isRecurring={isRecurring}
          />
        )}
      </div>
      {shouldShowTextDescription ? (
        <TextWithLineBreaks color="faded" size="small">
          {description}
        </TextWithLineBreaks>
      ) : null}
    </>
  );
};
