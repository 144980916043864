import React from "react";
import { createRoot } from "react-dom/client";
import "./index.scss";
import "mapbox-gl/dist/mapbox-gl.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { HelmetProvider } from "react-helmet-async";
import { isUnauthenticatedError } from "./Utils/ErrorUtils";
import { userManager, ENVIRONMENT } from "./Utils/EnvUtils";
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider,
} from "react-router";
import { Login as IdsLogin } from "./pages/login/IdsLogin";
import StartPage from "./pages";
import CommandCenterContainer from "./pages/commandcenter/CommandCenterContainer";
import Patients from "./pages/commandcenter/Patients/Patients";
import Patient from "./pages/commandcenter/Patients/Patient/Patient";
import PatientActivities from "./pages/commandcenter/Patients/Patient/Activities/Activities";
import { Discharge } from "./pages/commandcenter/Patients/Patient/Discharge/Discharge";
import { PatientAuthentication } from "./pages/commandcenter/Patients/Patient/Authentication/PatientAuthentication";
import Information from "./pages/commandcenter/Patients/Patient/Information/Information";
import SingleActivityOccurrence from "./pages/commandcenter/Patients/Patient/Activities/ActivityOccurrence/SingleActivityOccurrence";
import RecurringActivitySeries from "./pages/commandcenter/Patients/Patient/Activities/ActivityOccurrence/RecurringActivitySeries";
import { AddActivity } from "./pages/commandcenter/Patients/Patient/Activities/AddActivity/AddActivity";
import { VideoMeeting as CenterVideo } from "./pages/commandcenter/VideoMeeting";
import { Activities } from "@/pages/commandcenter/Activities";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { AuthProvider } from "react-oidc-context";
import RequireIdsAuth from "./components/RequireAuth/RequireIdsAuth";
import { AddActivityDialog } from "./pages/commandcenter/Activities/AddActivityDialog/AddActivityDialog";
import { Communication } from "./pages/commandcenter/Patients/Patient/Communication/Communication";
import { Measurements } from "./pages/commandcenter/Patients/Patient/Measurements/Measurements";
import { ActivityDetailsDialog } from "./pages/commandcenter/Activities/ActivityDetailsDialog/ActivityDetailsDialog";
import { ProspectPatients } from "./pages/commandcenter/Patients/PatientsTable/ProspectPatients";
import { DischargedPatients } from "./pages/commandcenter/Patients/PatientsTable/DischargedPatients";
import { AdmittedPatients } from "./pages/commandcenter/Patients/PatientsTable/AdmittedPatients";
import { RegisterProspectPatient } from "./pages/commandcenter/Patients/RegisterProspectPatient/RegisterProspectPatient";
import { AdmitPatient } from "./pages/commandcenter/Patients/Patient/AdmitPatient";
import { AddActivityForm } from "./forms/AddActivityForm/AddActivityForm";
import { ActivityTemplates } from "./pages/commandcenter/Patients/Patient/Activities/AddActivity/Templates/ActivityTemplates";
import CenterChat from "./pages/commandcenter/Chat/Chat";
import CenterChatWithGo from "./pages/commandcenter/Chat/ChatWithGo";
import * as Sentry from "@sentry/react";
import { Planning } from "./pages/commandcenter/Planning/Planning";
import { PatientNote } from "./pages/commandcenter/Patients/Patient/PatientNote/PatientNote";
import { PatientsContainer } from "./pages/commandcenter/Patients/PatientsContainer";
import { GroupDetails } from "./pages/commandcenter/Patients/Patient/Activities/GroupDetails/GroupDetails";
import { AllPatients } from "./pages/commandcenter/Patients/PatientsTable/AllPatients";
import ActivityOccurrence from "./pages/commandcenter/Patients/Patient/Activities/ActivityOccurrence/ActivityOccurrence";
import { I18nApp } from "./I18nApp";
import { Trans } from "@lingui/react/macro";
import { Reporting } from "./pages/commandcenter/Reporting/Reporting";
import {
  ToHandleTable,
  PendingTable,
} from "./pages/commandcenter/Reporting/ReportingTable/ReportingTables";
import { Shifts } from "./pages/commandcenter/Shifts/Shifts";
import { ShiftDetails } from "./pages/commandcenter/Shifts/ShiftDetails";

Sentry.init({
  debug: import.meta.env.VITE_SENTRY,
  enabled: import.meta.env.VITE_SENTRY || import.meta.env.PROD,
  dsn: "https://168acba32934d682ced94c15895fa43a@o4506739699220480.ingest.sentry.io/4506846606524416",
  environment: ENVIRONMENT,
  integrations: [Sentry.replayIntegration()],
  // Session Replay
  replaysSessionSampleRate: 0, // This sets the sample rate at 0%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

if (import.meta.env.DEV && import.meta.env.VITE_MSW) {
  const { worker } = await import("./mocks/browser");
  worker.start({
    onUnhandledRequest(req) {
      if (req?.url?.includes("medoma"))
        console.error(
          "Found an unhandled %s request to %s",
          req.method,
          req.url,
        );
    },
  });
  worker.listHandlers();
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (count, error) => {
        return count < 3 && !isUnauthenticatedError(error);
      },
    },
  },
});

const centerElement = (
  <RequireIdsAuth>
    <CommandCenterContainer />
  </RequireIdsAuth>
);

const centerChildren = [
  {
    index: true,
    element: <Navigate to="patients" replace />,
  },
  {
    path: "patients",
    element: (
      <PatientsContainer>
        <Outlet />
      </PatientsContainer>
    ),
    children: [
      {
        element: <Patients />,
        children: [
          {
            index: true,
            element: <Navigate to="admitted" replace />,
          },
          {
            path: "admitted",
            element: <AdmittedPatients />,
          },
          {
            path: "discharged",
            element: <DischargedPatients />,
          },
          {
            path: "prospect",
            element: <ProspectPatients />,
          },
          {
            path: "all",
            element: <AllPatients />,
          },
        ],
      },
      {
        path: "new-prospect",
        element: <RegisterProspectPatient />,
      },
      {
        path: ":patientId",
        element: <Patient />,
        children: [
          {
            index: true,
            element: <Navigate to="activities" replace />,
          },
          {
            path: "admit",
            element: <AdmitPatient />,
          },
          {
            path: "activities",
            element: <PatientActivities />,
            children: [
              {
                path: ":activityId/occurrences/:occurrenceId",
                element: <ActivityOccurrence />,
                children: [
                  {
                    index: true,
                    element: <Navigate to="singleOccurrence" replace />,
                  },
                  {
                    path: "singleOccurrence",
                    element: <SingleActivityOccurrence />,
                  },
                  {
                    path: "recurringOccurrence",
                    element: <RecurringActivitySeries />,
                  },
                ],
              },
              {
                path: "templates",
                element: <ActivityTemplates />,
              },
              {
                path: "new",
                element: <AddActivity />,
                children: [
                  {
                    index: true,
                    element: <Navigate to="custom" replace />,
                  },
                  {
                    path: "custom",
                    element: <AddActivityForm />,
                  },
                ],
              },
              {
                path: "occurrence-groups/:groupId",
                element: <GroupDetails />,
              },
            ],
          },
          {
            path: "information",
            element: <Information />,
          },
          {
            path: "communication",
            element: (
              <Communication />
              /* NOTE: If you plan to add more sub routes here, ensure that the alert logic still holds up.
                2023-06-28: const hasChatPanelOpen = pathname.endsWith("communication");
                */
            ),
          },
          {
            path: "discharge",
            element: <Discharge />,
          },
          {
            path: "patient-note",
            element: <PatientNote />,
          },
          {
            path: "measurements",
            element: <Measurements />,
          },
          {
            path: "authentication",
            element: <PatientAuthentication />,
          },
        ],
      },
    ],
  },
  {
    path: "activities",
    element: (
      <>
        <Activities />
      </>
    ),
    children: [
      {
        path: "new",
        element: <AddActivity />,
        children: [
          {
            index: true,
            element: <Navigate to="custom" replace />,
          },
          {
            path: "custom",
            element: <AddActivityForm />,
          },
        ],
      },
      {
        path: ":activityId/occurrences/:occurrenceId",
        element: <ActivityOccurrence />,
        children: [
          {
            index: true,
            element: <Navigate to="singleOccurrence" replace />,
          },
          {
            path: "singleOccurrence",
            element: <SingleActivityOccurrence />,
          },
          {
            path: "recurringOccurrence",
            element: <RecurringActivitySeries />,
          },
        ],
      },

      {
        path: "occurrence-groups/:groupId",
        element: <GroupDetails />,
      },
    ],
  },
  {
    path: "planning",
    element: (
      <>
        <Planning />
        {/* CAVEAT: This works great for Dialog children, but will not work well for other potential children. */}
        <Outlet />
      </>
    ),
    children: [
      {
        path: "activities/:activityId/occurrences/:occurrenceId",
        element: (
          <ActivityDetailsDialog redirectPathRelativeToCenterRootOnClose="planning" />
        ),
        children: [
          {
            index: true,
            element: <Navigate to="singleOccurrence" replace />,
          },
          {
            path: "singleOccurrence",
            element: <SingleActivityOccurrence />,
          },
          {
            path: "recurringOccurrence",
            element: <RecurringActivitySeries />,
          },
        ],
      },
      {
        path: "activities/new",
        element: (
          <AddActivityDialog redirectPathRelativeToCenterRootOnClose="planning" />
        ),
      },
    ],
  },
  {
    path: "shifts",
    element: <Shifts />,
    children: [
      {
        path: ":shiftId",
        element: <ShiftDetails />,
      },
    ],
  },
  {
    path: "reporting",
    element: <Reporting />,
    children: [
      {
        index: true,
        element: <Navigate to="to-handle" replace />,
      },
      {
        path: "to-handle",
        element: (
          <>
            <ToHandleTable />
            {/* CAVEAT: This works great for Dialog children, but will not work well for other potential children. */}
            <Outlet />
          </>
        ),
        children: [
          {
            path: "activities/:activityId/occurrences/:occurrenceId",
            element: (
              <ActivityDetailsDialog redirectPathRelativeToCenterRootOnClose="reporting/to-handle" />
            ),
            children: [
              {
                index: true,
                element: <Navigate to="singleOccurrence" replace />,
              },
              {
                path: "singleOccurrence",
                element: <SingleActivityOccurrence />,
              },
              {
                path: "recurringOccurrence",
                element: <RecurringActivitySeries />,
              },
            ],
          },
        ],
      },
      {
        path: "pending",
        element: (
          <>
            <PendingTable />
            {/* CAVEAT: This works great for Dialog children, but will not work well for other potential children. */}
            <Outlet />
          </>
        ),
        children: [
          {
            path: "activities/:activityId/occurrences/:occurrenceId",
            element: (
              <ActivityDetailsDialog redirectPathRelativeToCenterRootOnClose="reporting/pending" />
            ),
            children: [
              {
                index: true,
                element: <Navigate to="singleOccurrence" replace />,
              },
              {
                path: "singleOccurrence",
                element: <SingleActivityOccurrence />,
              },
              {
                path: "recurringOccurrence",
                element: <RecurringActivitySeries />,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "chat",
    element: <CenterChat />,
    children: [
      {
        path: ":employeeId",
        element: <CenterChatWithGo />,
      },
    ],
  },
];

const router = createBrowserRouter([
  {
    path: "/",
    children: [
      // Used for Center by the main Center user, including chat.
      {
        path: "commandcenter",
        element: centerElement,
        children: centerChildren,
      },
      // Used for Center by other users, excluding chat.
      {
        path: "center",
        element: centerElement,
        children: centerChildren,
      },
      {
        path: "login",
        element: <IdsLogin />,
      },
      {
        path: "*",
        element: <Navigate to="/" />,
      },
      {
        index: true,
        element: <StartPage />,
      },
      {
        path: "video/:patientId",
        element: <CenterVideo />,
      },
      {
        path: "status",
        element: <p>OK</p>,
      },
    ],
    errorElement: (
      <div>
        <Trans>
          <h1>Något gick fel</h1>
          <p>Ladda om sidan</p>
        </Trans>
      </div>
    ),
  },
]);

const root = createRoot(document.getElementById("root")!);
root.render(
  <React.StrictMode>
    <AuthProvider userManager={userManager}>
      <QueryClientProvider client={queryClient}>
        <HelmetProvider>
          <I18nApp>
            <RouterProvider router={router} />
          </I18nApp>
        </HelmetProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </AuthProvider>
  </React.StrictMode>,
);
