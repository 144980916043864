import {
  type IPersonalIdentityNumberType,
  personalIdentityNumberTypeSchema,
} from "@models/patients";

export const PersonalIdentityNumber = ({
  personalIdentityNumber,
  personalIdentityNumberType,
  shouldRemoveLast4Digits = false,
}: {
  personalIdentityNumber: string;
  personalIdentityNumberType: IPersonalIdentityNumberType;
  shouldRemoveLast4Digits?: boolean;
}) => {
  if (
    personalIdentityNumberType ===
    personalIdentityNumberTypeSchema.Values.SwedishPersonalIdentityNumber
  ) {
    const formatSwedishPersonalIdentityNumber = (number: string) => {
      if (shouldRemoveLast4Digits) {
        return number.slice(0, -4);
      }
      return `${number.substring(0, 8)}-${number.substring(8, 12)}`;
    };

    return <>{formatSwedishPersonalIdentityNumber(personalIdentityNumber)}</>;
  }

  return <>{personalIdentityNumber}</>;
};
