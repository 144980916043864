import type {
  INewActivity,
  INewAdminTaskActivity,
  INewHomeVisitActivity,
  INewPatientMeasurementTaskActivity,
  INewPatientTaskActivity,
  INewVideoActivity,
} from "@/api/Activities";
import { ANY_TIME_OF_DAY } from "@/api/Activities";
import {
  activityFrequencySchema,
  recurringOptionSchema,
} from "@/forms/AddActivityForm/Scheduling/schedulingModels";
import { generateRandomUUID } from "@/Utils/UniqueId";
import {
  getTimespan,
  getTimeFields,
} from "@/forms/AddActivityForm/activityTimeUtils";
import {
  categorySchema,
  timeOfDaySchema,
  videoTypeSchema,
} from "@models/activities";
import { type ITemplate } from "../templateModels";

export const newActivityFromTemplateSivNsvt = ({
  template,
  startDate,
  endDate,
  patientId,
}: {
  template: ITemplate;
  startDate: string;
  endDate?: string;
  patientId: string;
}): INewActivity => {
  const {
    title,
    category,
    description,
    duration,
    doubleStaffing,
    frequency,
    recurrence,
    customRecurrenceInterval,
    customRecurrenceIntervalUnit,
    requiredCompetences,
    timeCategory,
    weekdays,
    hidden,
    measurements,
  } = template;

  const timeSensitivity =
    "timeSensitivity" in template ? template.timeSensitivity : undefined;

  const timeslots = "timeslots" in template ? template.timeslots : [];

  const isAnyTimeOfDay = timeCategory === timeOfDaySchema.Values.Any;
  const isRecurring =
    frequency === activityFrequencySchema.Values.recurring &&
    recurrence !== null;
  const isRecurringAsInterval =
    recurrence !== recurringOptionSchema.Values.onSelectedWeekdays;

  const commonActivityFields: Pick<
    INewActivity,
    | "activityId"
    | "category"
    | "description"
    | "duration"
    | "hidden"
    | "startDate"
    | "timespan"
    | "title"
  > = {
    activityId: generateRandomUUID(),
    category,
    description,
    duration: duration ?? 15,
    hidden,
    startDate,
    timespan: getTimespan({
      isAnyTimeOfDay,
      timeSensitivity,
    }),
    title,
  };

  const recurringTimeOfDayFields = isAnyTimeOfDay
    ? {
        isAnyTimeOfDay: true as const,
        timeslots: undefined,
      }
    : {
        isAnyTimeOfDay: false as const,
        timeslots: timeslots as {
          time: string;
        }[],
      };

  const timeFields = isRecurring
    ? {
        ...getTimeFields({
          isRecurringAsInterval,
          weekdays,
          recurrence,
          customRecurrenceInterval,
          customRecurrenceIntervalUnit,
          ...recurringTimeOfDayFields,
        }),
        endDate: endDate === "" || endDate === undefined ? undefined : endDate,
      }
    : // @ts-expect-error we know that there is exactly one timeslot, but TS doesn't.
      { time: isAnyTimeOfDay ? ANY_TIME_OF_DAY : timeslots[0].time }; // We only have one timeslot if it's not recurring.

  if (category === categorySchema.Values.VideoCall) {
    const newActivity: INewVideoActivity = {
      ...commonActivityFields,
      patientId,
      category: categorySchema.Values.VideoCall,
      requiredCompetences,
      ...timeFields,
      type: videoTypeSchema.Values.DigitalVisit,
    };

    return newActivity;
  } else if (category === categorySchema.Values.HomeVisit) {
    const newActivity: INewHomeVisitActivity = {
      ...commonActivityFields,
      patientId,
      category: categorySchema.Values.HomeVisit,
      requiredCompetences,
      ...timeFields,
      doubleStaffing,
    };

    return newActivity;
  } else if (category === categorySchema.Values.PatientTask) {
    const newActivity: INewPatientTaskActivity = {
      ...commonActivityFields,
      patientId,
      category: categorySchema.Values.PatientTask,
      ...timeFields,
    };

    return newActivity;
  } else if (category === categorySchema.Values.AdminTask) {
    const newActivity: INewAdminTaskActivity = {
      ...commonActivityFields,
      patientId: patientId ? patientId : null, // Represent lack of patient id as null.
      category: categorySchema.Values.AdminTask,
      requiredCompetences: requiredCompetences ? requiredCompetences : [],
      ...timeFields,
    };

    return newActivity;
  } else {
    const newActivity: INewPatientMeasurementTaskActivity = {
      ...commonActivityFields,
      patientId,
      category: categorySchema.Values.PatientMeasurementTask,
      measurements: measurements,
      ...timeFields,
    };

    return newActivity;
  }
};
