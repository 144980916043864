import { t } from "@lingui/core/macro";
import { Collection, Header } from "react-aria-components";
import styles from "@/pages/commandcenter/Planning/SideBar/PlanningSideBar.module.scss";
import { MedomaGridListItem } from "@/components/DragAndDrop/DragAndDrop";
import { AllocatableItemTile } from "@/pages/commandcenter/Planning/AllocatableItem/AllocatableItemTile";
import type { Row } from "@tanstack/react-table";
import {
  categorySchema,
  getUniqueId,
  type IActivityCategory,
  type IActivityOccurrenceOrGroup,
} from "@models/activities";
import {
  type IMedicalCompetence,
  medicalCompetenceDictionary,
} from "@models/shifts";
import { activityCategoryDictionary } from "@/api/Activities";
import { z } from "zod";
import { type Selection } from "react-aria-components";
import { i18n } from "@lingui/core";
import { patientStatusDictionary, patientStatusSchema } from "@models/patients";
import { getPatientNameWithStatus } from "@/api/Patients";

type IActivityTableItem = {
  activityOccurrenceOrGroup: IActivityOccurrenceOrGroup;
  category: IActivityCategory;
  patient: IActivityOccurrenceOrGroup["patient"];
};

const groupingSchema = z.union([
  z.object({ id: z.literal("category"), value: categorySchema }),
  z.object({ id: z.literal("patient"), value: z.string() }),
  z.object({ id: z.literal("requiredCompetences"), value: z.string() }),
  z.object({ id: z.literal("timespan"), value: z.string() }),
  z.object({ id: z.literal("area"), value: z.string() }),
]);
type IGrouping = z.infer<typeof groupingSchema>;

const requiredCompetences = (
  activityOccurrenceOrGroup: IActivityOccurrenceOrGroup,
): IMedicalCompetence[] | undefined =>
  "requiredCompetences" in activityOccurrenceOrGroup
    ? activityOccurrenceOrGroup.requiredCompetences
    : undefined;

const typeSafeGrouping = (groupRow: Row<IActivityTableItem>) => {
  return groupingSchema.parse({
    id: groupRow.groupingColumnId,
    value: groupValue(groupRow),
  });
};
const groupHeaderDisplayValue = (grouping: IGrouping) => {
  if (grouping.id === "category") {
    return i18n._(activityCategoryDictionary[grouping.value]);
  }

  return grouping.value;
};

const groupValue = (row: Row<IActivityTableItem>) => {
  // For patients we group on id, but we display the name (which in rare cases may be duplicated)
  if (row.groupingColumnId === "patient") {
    return row.original.patient
      ? row.original.patient.status === patientStatusSchema.Values.deleted
        ? i18n._(patientStatusDictionary.deleted.singular)
        : row.original.patient.name
      : // If there is no patient, fallback to a hyphen
        "-";
  }

  return row.groupingValue;
};

const getTextValue = (
  activityOccurrenceOrGroup: IActivityOccurrenceOrGroup,
): string => {
  const translatedCompetences = requiredCompetences(activityOccurrenceOrGroup)
    ?.map((competence) => i18n._(medicalCompetenceDictionary[competence].short))
    .join(" ");

  const patientName = activityOccurrenceOrGroup.patient
    ? getPatientNameWithStatus(activityOccurrenceOrGroup.patient)
    : t`Ingen patient`;

  const textValue = [
    i18n._(activityCategoryDictionary[activityOccurrenceOrGroup.category]),
    patientName,
    translatedCompetences,
  ];

  return textValue.filter(Boolean).join(" ");
};

export const GroupedRows = ({
  row,
  selectedKeys,
}: {
  row: Row<IActivityTableItem>;
  selectedKeys: Selection;
}) => {
  if (row.getIsGrouped()) {
    const groupRow = row;
    return (
      // TODO: add Section instead of fragment when https://github.com/adobe/react-spectrum/issues/5230 is fixed
      <>
        <Header className={styles.header}>
          {groupRow.groupingColumnId
            ? groupHeaderDisplayValue(typeSafeGrouping(groupRow))
            : null}
        </Header>
        <Collection items={groupRow.subRows}>
          {({ original }) => {
            const id = getUniqueId(original.activityOccurrenceOrGroup);
            return (
              <MedomaGridListItem
                textValue={getTextValue(original.activityOccurrenceOrGroup)}
                id={id}
              >
                <AllocatableItemTile
                  activityOccurrenceOrGroup={original.activityOccurrenceOrGroup}
                  isSelected={
                    selectedKeys &&
                    (selectedKeys === "all" || selectedKeys.has(id))
                  }
                />
              </MedomaGridListItem>
            );
          }}
        </Collection>
      </>
    );
  } else {
    const id = getUniqueId(row.original.activityOccurrenceOrGroup);
    return (
      <>
        <MedomaGridListItem
          textValue={getTextValue(row.original.activityOccurrenceOrGroup)}
          id={id}
        >
          <AllocatableItemTile
            activityOccurrenceOrGroup={row.original.activityOccurrenceOrGroup}
            isSelected={
              selectedKeys && (selectedKeys === "all" || selectedKeys.has(id))
            }
          />
        </MedomaGridListItem>
      </>
    );
  }
};
