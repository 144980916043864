import { msg, t } from "@lingui/core/macro";
import { Loading } from "@components/Loading/Loading";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import { deducedError, isKnownErrorCode } from "@/Utils/ErrorUtils";
import * as Sentry from "@sentry/react";

import { useLingui } from "@lingui/react";
import {
  approveInvoicingOccurrences,
  invoicingOccurrencesAndGroupsOptions,
  invoicingOccurrencesAndGroupsToApproveOptions,
} from "@/api/Invoicing";
import { getDataFromInvoicingOccurrencesAndGroups } from "./getReportingTableData";
import styles from "./ReportingTables.module.scss";
import { Heading } from "@components/Heading/Heading";
import { Trans } from "@lingui/react/macro";
import { FilledButton } from "@components/Button/Button";
import NoResults from "@/components/NoResults/NoResults";
import type {
  IInvoicingActivityOccurrenceOrGroup,
  IInvoicingCode,
} from "@models/invoicing";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  Cell,
  Column,
  Row,
  Table,
  TableBody,
  TableHeader,
} from "react-aria-components";
import type { I18n, MessageDescriptor } from "@lingui/core";
import { patientTypeDictionary } from "@models/patients";
import { medicalCompetenceDictionary } from "@models/shifts";
import { Time } from "@/components/Time/Time";
import { dateMonthAndYear } from "@/Utils/DateUtils";
import { Link } from "@components/Link/Link";
import { NotExecutedActivityOccurrencePopover } from "./NotExecutedActivityOccurrencePopover/NotExecutedActivityOccurrencePopover";
import { useId } from "react";

const isInvoicingGroup = (
  activityOccurrenceOrGroup: IInvoicingActivityOccurrenceOrGroup,
) => "occurrences" in activityOccurrenceOrGroup;

const getOccurrenceIds = (
  occurrencesAndGroups: IInvoicingActivityOccurrenceOrGroup[],
) => {
  return occurrencesAndGroups
    .map((occurrenceOrGroup) => {
      if (isInvoicingGroup(occurrenceOrGroup)) {
        return occurrenceOrGroup.occurrences.map(
          (occurrence) => occurrence.occurrenceId,
        );
      } else {
        return occurrenceOrGroup.occurrenceId;
      }
    })
    .flat();
};

const columns: {
  name: MessageDescriptor;
  id: keyof ReturnType<typeof getDataFromInvoicingOccurrencesAndGroups>[0];
  isRowHeader?: true;
}[] = [
  { name: msg`Namn`, id: "patient", isRowHeader: true },
  { name: msg`Pers.nr`, id: "personalIdentityNumber" },
  {
    name: msg`Patienttyp`,
    id: "patientType",
  },
  {
    name: msg`Kompetenser`,
    id: "competences",
  },
  { name: msg`Datum`, id: "finishedAt" },
  {
    name: msg`Aktivitet`,
    id: "title",
  },
  {
    name: msg`Diagnoskod`,
    id: "diagnosisCode",
  },
  {
    name: msg`KVÅ-kod`,
    id: "procedureCodes",
  },
  { id: "productCodes", name: msg`Produktkod` },
  {
    name: msg`Kommentar`,
    id: "comment",
  },
];

const hasInvoicingCode = ({
  procedureCodes,
  productCodes,
}: {
  procedureCodes: IInvoicingCode[];
  productCodes: IInvoicingCode[];
}) => {
  if (procedureCodes.length >= 1 || productCodes.length >= 1) {
    return true;
  }
  return false;
};

const formatCell =
  (translator: I18n["_"]) =>
  // This rule is just for simplifying debugging, OK to skip
  // eslint-disable-next-line react/display-name
  (
    item: ReturnType<typeof getDataFromInvoicingOccurrencesAndGroups>[0],
    key: keyof ReturnType<typeof getDataFromInvoicingOccurrencesAndGroups>[0],
  ) => {
    if (key === "patient") {
      const patientId = item[key].id;
      const patientName = item[key].name;
      return (
        <Link to={`/commandcenter/patients/${patientId}`} weight="regular">
          {patientName}
        </Link>
      );
    }
    if (key === "personalIdentityNumber") {
      return item[key].slice(0, -4);
    }
    if (key === "patientType") {
      return translator(patientTypeDictionary[item[key]]);
    }
    if (key === "competences") {
      const competences = item[key];
      // MED-5101: Always sort the CareGiverType list
      return competences
        .toSorted((competenceA, competenceB) => {
          if (competenceA === "MedicalDoctor") {
            return -1;
          }
          if (competenceB === "MedicalDoctor") {
            return 1;
          }
          return 0;
        })
        .map((competence) =>
          translator(medicalCompetenceDictionary[competence].short),
        )
        .join(", ");
    }
    if (key === "finishedAt") {
      return item[key] ? (
        <Time color="default">{dateMonthAndYear(item[key])}</Time>
      ) : null;
    }
    if (key === "title") {
      return item[key].map(
        ({
          activityId,
          occurrenceId,
          title,
          status,
          procedureCodes,
          productCodes,
        }) => (
          <div
            key={`${activityId}-${occurrenceId}`}
            className={styles.activityOccurrence}
          >
            <Link
              to={`activities/${activityId}/occurrences/${occurrenceId}`}
              weight="regular"
            >
              <span>{title}</span>
            </Link>
            {status === "notExecuted" &&
            hasInvoicingCode({ procedureCodes, productCodes }) ? (
              <NotExecutedActivityOccurrencePopover
                activityId={activityId}
                occurrenceId={occurrenceId}
                title={title}
              />
            ) : null}
          </div>
        ),
      );
    }
    if (key === "diagnosisCode") {
      return item[key];
    }
    if (key === "procedureCodes") {
      return item[key].map((procedureCode) => (
        <div
          key={procedureCode.code}
        >{`${procedureCode.code} - ${procedureCode.name}`}</div>
      ));
    }
    if (key === "productCodes") {
      return item[key].map((productCode) => (
        <div
          key={productCode.code}
        >{`${productCode.code} - ${productCode.name}`}</div>
      ));
    }
    if (key === "comment") {
      const error = item[key];
      if (!error) return "";
      if (isKnownErrorCode(error)) {
        return deducedError(error);
      }
      return error;
    }
    return item[key];
  };

const ReportingTable = ({
  headingId,
  rows,
}: {
  headingId: string;
  rows: ReturnType<typeof getDataFromInvoicingOccurrencesAndGroups>;
}) => {
  const { _ } = useLingui();
  return (
    <Table className={styles.reportingTable} aria-labelledby={headingId}>
      <TableHeader columns={columns}>
        {(column) => (
          <Column isRowHeader={column.isRowHeader}>{t(column.name)}</Column>
        )}
      </TableHeader>
      <TableBody items={rows}>
        {(item) => {
          return (
            <Row id={item.title[0]?.occurrenceId} columns={columns}>
              {(column) => <Cell>{formatCell(_)(item, column.id)}</Cell>}
            </Row>
          );
        }}
      </TableBody>
    </Table>
  );
};

export const PendingTable = () => {
  const headingId = useId();
  const { _ } = useLingui();
  const {
    data: invoicingOccurrencesAndGroups,
    isError,
    isPending,
    error,
  } = useQuery(
    invoicingOccurrencesAndGroupsOptions({
      invoicingStatuses: ["pending"],
    }),
  );

  if (isError) {
    Sentry.captureException(error);
  }

  const noData = invoicingOccurrencesAndGroups?.length === 0;

  // `rows` are not rendered until `isPending` or `isError` is resolved, so this is safe
  const rows = isPending
    ? []
    : isError
      ? []
      : getDataFromInvoicingOccurrencesAndGroups(invoicingOccurrencesAndGroups);

  return (
    <div className={styles.tableContainer}>
      {isPending ? (
        <div className={styles.systemMessage}>
          <Loading message={t`Hämtar underlag för rapportering`} />
        </div>
      ) : isError ? (
        <div className={styles.systemMessage}>
          <ErrorMessage
            message={`${t`Kunde inte hämta underlag för rapportering.`} ${deducedError(error)}`}
          />
        </div>
      ) : noData ? (
        <div className={styles.systemMessage}>
          <NoResults message={t`Inget underlag att visa`} />
        </div>
      ) : (
        <ReportingTable headingId={headingId} rows={rows} />
      )}
    </div>
  );
};

const ErrorTable = () => {
  const headingId = useId();
  const { _ } = useLingui();
  const queryClient = useQueryClient();

  const {
    data: invoicingOccurrencesAndGroups,
    isError,
    isPending,
    error,
  } = useQuery(
    invoicingOccurrencesAndGroupsOptions({
      invoicingStatuses: ["error"],
    }),
  );

  if (isError) {
    Sentry.captureException(error);
  }

  const shouldNotShowInvoicingApprovalButton =
    !invoicingOccurrencesAndGroups ||
    invoicingOccurrencesAndGroups.length === 0;

  const {
    mutate: mutateApproveInvoicingOccurrences,
    isPending: isPendingApprove,
  } = useMutation({
    mutationFn: (
      invoicingOccurrencesAndGroups: IInvoicingActivityOccurrenceOrGroup[],
    ) =>
      approveInvoicingOccurrences(
        getOccurrenceIds(invoicingOccurrencesAndGroups),
      ),
    onSuccess: async () => {
      return queryClient.invalidateQueries();
    },
  });

  // `rows` are not rendered until `isPending` or `isError` is resolved, so this is safe
  const rows = isPending
    ? []
    : isError
      ? []
      : getDataFromInvoicingOccurrencesAndGroups(invoicingOccurrencesAndGroups);

  return (
    <div className={styles.tableContainer}>
      <div className={styles.tableHeading}>
        <Heading level="h2" id={headingId}>
          <Trans>Avvisade</Trans>
        </Heading>
        {shouldNotShowInvoicingApprovalButton ? null : (
          <FilledButton
            size="small"
            onClick={() =>
              mutateApproveInvoicingOccurrences(invoicingOccurrencesAndGroups)
            }
            disabled={isPendingApprove}
          >
            {isPendingApprove ? (
              <Loading message={t`Godkänner`} />
            ) : (
              <Trans>Godkänn och skicka till GVR</Trans>
            )}
          </FilledButton>
        )}
      </div>
      {isPending ? (
        <div className={styles.systemMessage}>
          <Loading message={t`Hämtar underlag för rapportering`} />
        </div>
      ) : isError ? (
        <div className={styles.systemMessage}>
          <ErrorMessage
            message={`${t`Kunde inte hämta underlag för rapportering.`} ${deducedError(error)}`}
          />
        </div>
      ) : shouldNotShowInvoicingApprovalButton ? (
        <div className={styles.systemMessage}>
          <NoResults message={t`Inget underlag att visa`} />
        </div>
      ) : (
        <ReportingTable headingId={headingId} rows={rows} />
      )}
    </div>
  );
};

const AwaitingApprovalTable = () => {
  const headingId = useId();
  const { _ } = useLingui();
  const queryClient = useQueryClient();

  const {
    data: invoicingOccurrencesAndGroups,
    isError,
    isPending,
    error,
  } = useQuery({
    ...invoicingOccurrencesAndGroupsToApproveOptions,
    // This is used to achieve somewhat responsive data, despite async flows in the backend
    refetchInterval: 5000,
  });

  if (isError) {
    Sentry.captureException(error);
  }

  const shouldNotShowInvoicingApprovalButton =
    !invoicingOccurrencesAndGroups ||
    invoicingOccurrencesAndGroups.length === 0;
  const {
    mutate: mutateApproveInvoicingOccurrences,
    isPending: isPendingApprove,
  } = useMutation({
    mutationFn: (
      invoicingOccurrencesAndGroups: IInvoicingActivityOccurrenceOrGroup[],
    ) =>
      approveInvoicingOccurrences(
        getOccurrenceIds(invoicingOccurrencesAndGroups),
      ),
    onSuccess: async () => {
      return queryClient.invalidateQueries();
    },
  });

  // `rows` are not rendered until `isPending` or `isError` is resolved, so this is safe
  const rows = isPending
    ? []
    : isError
      ? []
      : getDataFromInvoicingOccurrencesAndGroups(invoicingOccurrencesAndGroups);

  return (
    <div className={styles.tableContainer}>
      <div className={styles.tableHeading}>
        <Heading level="h2">
          <Trans>Att godkänna</Trans>
        </Heading>
        {shouldNotShowInvoicingApprovalButton ? null : (
          <FilledButton
            size="small"
            onClick={() =>
              mutateApproveInvoicingOccurrences(invoicingOccurrencesAndGroups)
            }
            disabled={isPendingApprove}
          >
            {isPendingApprove ? (
              <Loading message={t`Godkänner...`} />
            ) : (
              <Trans>Godkänn och skicka till GVR</Trans>
            )}
          </FilledButton>
        )}
      </div>
      {isPending ? (
        <div className={styles.systemMessage}>
          <Loading message={t`Hämtar underlag för rapportering`} />
        </div>
      ) : isError ? (
        <div className={styles.systemMessage}>
          <ErrorMessage
            message={`${t`Kunde inte hämta underlag för rapportering.`} ${deducedError(error)}`}
          />
        </div>
      ) : shouldNotShowInvoicingApprovalButton ? (
        <div className={styles.systemMessage}>
          <NoResults message={t`Inget underlag att visa`} />
        </div>
      ) : (
        <ReportingTable headingId={headingId} rows={rows} />
      )}
    </div>
  );
};

const ToHandleTable = () => {
  return (
    <>
      <ErrorTable />
      <AwaitingApprovalTable />
    </>
  );
};

export { ToHandleTable };
