import { useParentRoutesPatient } from "@/api/Patients";
import { Heading } from "@components/Heading/Heading";
import { PlainButton } from "@components/Button/Button";
import { Trans, useLingui } from "@lingui/react/macro";
import styles from "./PatientAuthentication.module.scss";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  generateOtp as generateOtpOnServer,
  generateTemporaryPin as generateTemporaryPinOnServer,
  signOutPatient as signOutPatientOnServer,
  isPatientSignedInQueryOptions,
} from "@/api/Auth";
import { Text } from "@components/Text/Text";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import { useEffect, useState } from "react";
import type { IExistingPatient } from "@models/patients";
import { TextCode } from "@components/TextCode/TextCode";

const OTP = ({
  patientId,
  isPatientSignedIn,
}: {
  patientId: string;
  isPatientSignedIn: boolean;
}) => {
  const { t } = useLingui();
  const [otp, setOtp] = useState<string | null>(null);

  const {
    mutate: generateOtp,
    isPending: generatingOtp,
    isError: isErrorOtp,
  } = useMutation({
    mutationFn: () => generateOtpOnServer(patientId),
    onSuccess: (otp) => setOtp(otp),
  });

  // Ensure OTP is nullified when patient is signed out, to avoid showing stale data
  useEffect(() => {
    if (!isPatientSignedIn) {
      setOtp(null);
    }
  }, [isPatientSignedIn]);

  // Don't display OTP UI if patient is already signed in
  if (isPatientSignedIn) {
    return null;
  }

  return (
    <section>
      <Heading level="h2" margin="bottom">
        <Trans>Startkod</Trans>
      </Heading>
      <Text element="p">
        <Trans>Startkoden används vid första inloggningen</Trans>
      </Text>
      {otp ? (
        <div className={styles.textAndButton}>
          <PlainButton disabled={generatingOtp} onClick={() => generateOtp()}>
            <Trans>Skapa ny startkod</Trans>
          </PlainButton>
          <div className={styles.codeContainer}>
            <TextCode textCode={otp} />
            <Text element="div">
              <Trans>Giltig i 5 minuter</Trans>
            </Text>
          </div>
        </div>
      ) : (
        <PlainButton disabled={generatingOtp} onClick={() => generateOtp()}>
          <Trans>Skapa startkod</Trans>
        </PlainButton>
      )}
      {isErrorOtp ? (
        <ErrorMessage
          message={t`Det gick inte att skapa en startkod. Försök igen om en stund och kontakta teknisk support om problemet kvarstår.`}
        />
      ) : (
        <></>
      )}
    </section>
  );
};

const PinCode = ({
  patient,
}: {
  patient: Pick<IExistingPatient, "id" | "name">;
}) => {
  const { t } = useLingui();
  const {
    mutate: generateTemporaryPin,
    isPending: generatingTemporaryPin,
    isError: isErrorPin,
  } = useMutation({
    mutationFn: () => generateTemporaryPinOnServer(patient.id),
    onSuccess: (pin) => setTemporaryPin(pin),
  });

  const [temporaryPin, setTemporaryPin] = useState<string | null>(null);

  const patientName = patient.name;
  return (
    <section>
      <Heading level="h2" margin="top-and-bottom">
        <Trans>Glömt pinkod?</Trans>
      </Heading>
      <div className={styles.textAndButton}>
        <PlainButton
          disabled={generatingTemporaryPin}
          onClick={() => {
            if (
              window.confirm(
                t`Är du säker på att du vill skapa en ny pinkod för ${patientName}? Du behöver ge den tillfälliga pinkoden till patienten. Därefter får patienten själv välja sin nya kod.`,
              )
            ) {
              generateTemporaryPin();
            }
          }}
        >
          <Trans>Skapa tillfällig pinkod</Trans>
        </PlainButton>
        {temporaryPin ? (
          <>
            <div className={styles.codeContainer}>
              <TextCode textCode={temporaryPin} />
            </div>
            <Text element="p">
              <Trans>
                Patienten använder den tillfälliga pinkoden för att logga in och
                väljer därefter en ny personlig pinkod
              </Trans>
            </Text>
          </>
        ) : (
          <></>
        )}
        {isErrorPin ? (
          <ErrorMessage
            message={t`Det gick inte att skapa en tillfällig pinkod. Försök igen om en stund och kontakta teknisk support om problemet kvarstår.`}
          />
        ) : (
          <></>
        )}
      </div>
    </section>
  );
};

const PatientAuthentication = () => {
  const { t } = useLingui();
  const patient = useParentRoutesPatient();

  const { data: isPatientSignedIn } = useQuery(
    isPatientSignedInQueryOptions(patient.id),
  );

  const queryClient = useQueryClient();
  const {
    mutate: signOutPatient,
    isPending: signingOutPatient,
    isError: isErrorSigningOutPatient,
  } = useMutation({
    mutationFn: () => signOutPatientOnServer(patient.id),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: isPatientSignedInQueryOptions(patient.id).queryKey,
      }),
  });

  const patientName = patient.name;
  return (
    <div className={styles.container}>
      {isPatientSignedIn ? (
        <section>
          <div className={styles.signedIn}>
            <div className={styles.signedInIndicator}></div>
            <Text element="div" size="small" weight="bold">
              <Trans>{patientName} är inloggad</Trans>
            </Text>
          </div>
          <Text element="p">
            <Trans>
              Patienten loggas ut automatiskt vid utskrivning, men det kan göras
              här om det behövs
            </Trans>
          </Text>

          <PlainButton
            disabled={signingOutPatient}
            onClick={() => {
              if (
                window.confirm(
                  t`Är du säker på att du vill logga ut ${patientName}? Patienten behöver en ny startkod för att kunna logga in på nytt.`,
                )
              ) {
                signOutPatient();
              }
            }}
          >
            <Trans>Logga ut {patientName}</Trans>
          </PlainButton>

          {isErrorSigningOutPatient ? (
            <ErrorMessage
              message={t`Det gick inte att logga ut ${patientName}. Försök igen om en stund och kontakta teknisk support om problemet kvarstår.`}
            />
          ) : (
            <></>
          )}
        </section>
      ) : null}
      <section className={styles.sections}>
        <OTP
          patientId={patient.id}
          isPatientSignedIn={isPatientSignedIn ?? false}
        />
        <PinCode patient={patient} />
      </section>
    </div>
  );
};

export { PatientAuthentication };
