import { t } from "@lingui/core/macro";
import RadioButton from "@/components/RadioButton/RadioButton";
import RadioButtons from "@/components/RadioButton/RadioButtons";
import { useFormContext } from "react-hook-form";
import type { ITimeSlotsFields } from "./TimeSlots";
import { TimeSlots } from "./TimeSlots";
import { useEffect } from "react";
import type { IRecurrencesPerDayOption } from "./timeSlotsUtils";
import { recurrencesPerDayOptions } from "./timeSlotsUtils";
import {
  timeOfDayDictionary,
  timeOfDaySchema,
  type ITimeOfDay,
} from "@models/activities";
import { useLingui } from "@lingui/react";
import {
  TimeSensitivityPicker,
  type ITimeSensitivityFields,
} from "./TimeSensitivityPicker";

export type ITimeSlotsPickerFields = ITimeSlotsFields &
  ITimeSensitivityFields & {
    timeCategory: ITimeOfDay;
    recurrencesPerDay: IRecurrencesPerDayOption;
  };

export const TimeSlotsPicker = ({
  isRecurring = false,
}: {
  isRecurring: boolean;
}) => {
  const { _ } = useLingui();
  const {
    formState: { errors },
    register,
    resetField,
    watch,
  } = useFormContext<ITimeSlotsPickerFields>();

  const isAnyTimeOfDay = watch("timeCategory") === timeOfDaySchema.Values.Any;
  const recurrencesPerDay = Number(watch("recurrencesPerDay"));

  // Reset fields when changing time category
  useEffect(() => {
    if (!isRecurring) {
      resetField(`recurrencesPerDay`);
      resetField(`timeslots`);
    }
  }, [isRecurring, resetField]);

  return (
    <>
      <RadioButtons
        legend={t`Tid på dagen`}
        errorMessage={errors.timeCategory?.message}
        orientation="horizontal"
      >
        <RadioButton
          label={{ text: _(timeOfDayDictionary.Specific.short) }}
          visualStyle="framed"
          value={timeOfDaySchema.Values.Specific}
          {...register(`timeCategory`, {
            required: {
              value: true,
              message: t`Tid på dagen måste väljas`,
            },
            onChange: () => {
              resetField(`timeSensitivity`);
              resetField(`recurrencesPerDay`);
              resetField(`timeslots`);
            },
          })}
        />
        <RadioButton
          label={{ text: _(timeOfDayDictionary.Any.short) }}
          visualStyle="framed"
          value={timeOfDaySchema.Values.Any}
          {...register(`timeCategory`)}
        />
      </RadioButtons>
      {isRecurring && !isAnyTimeOfDay ? (
        <>
          <RadioButtons
            legend={t`Antal tillfällen per dygn`}
            orientation="horizontal"
          >
            {recurrencesPerDayOptions.map((recurrencesPerDay) => (
              <RadioButton
                key={recurrencesPerDay}
                label={{ text: recurrencesPerDay }}
                {...register(`recurrencesPerDay`)}
                value={recurrencesPerDay}
                visualStyle="framed"
              />
            ))}
          </RadioButtons>
        </>
      ) : (
        <></>
      )}
      {!isAnyTimeOfDay ? (
        <>
          <TimeSlots recurrences={recurrencesPerDay} />
          <TimeSensitivityPicker />
        </>
      ) : (
        <></>
      )}
    </>
  );
};
