import { Trans } from "@lingui/react/macro";
import { unitAlerisAhcGroupTemplateKeysSchema } from "../templatesAlerisAhc";
import { newActivityFromTemplateAlerisAhc } from "../newActivityFromTemplateAlerisAhc";
import { templates } from "../templatesAlerisAhc";
import { useParams } from "react-router";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import z from "zod";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import type { INewActivity } from "@/api/Activities";
import { activityKeys, addActivitiesFromGroupTemplate } from "@/api/Activities";
import { ExpansionPanel } from "@components/ExpansionPanel/ExpansionPanel";
import styles from "../ActivityTemplatesAlerisAhc.module.scss";
import Form from "@/components/Form/Form";
import { FilledButton } from "@components/Button/Button";
import Checkboxes from "@/components/Checkbox/Checkboxes";
import { TemplateHeadingAlerisAhc } from "../TemplateHeadingAlerisAhc";
import Checkbox from "@/components/Checkbox/Checkbox";
import { deducedError } from "@/Utils/ErrorUtils";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import { GroupTemplateHeadingAleris } from "../TemplateHeadingAlerisAhc";
import { medicalCompetenceSchema } from "@models/shifts";
import {
  templateUnitsSchema,
  type ITemplateId,
  type ITemplateRevision,
} from "../../templateModels";
import { generateTemplateId } from "../../templateUtils";
import type { ISchedulingFields } from "@/forms/AddActivityForm/Scheduling/Scheduling";
import { Scheduling } from "@/forms/AddActivityForm/Scheduling/Scheduling";

/**
 * Admission Template Id and revision number
 * NEVER change the Id.
 * Increment revision number when you make changes to the template.
 */
const TEMPLATE_ID: ITemplateId = await generateTemplateId({
  unit: templateUnitsSchema.Values.alerisahc,
  templateTitle: unitAlerisAhcGroupTemplateKeysSchema.Values.medications,
});
const TEMPLATE_REVISION: ITemplateRevision = 1;

const medicalProceduresActivities = {
  infusionIn: templates.infusionIn,
  infusionOut: templates.infusionOut,
  handOutEDos: templates.handOutEDos,
  handOutDosette: templates.handOutDosette,
  leaveMedication: templates.leaveMedication,
};

export const MedicationTemplateAlerisAhc = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { patientId } = z.object({ patientId: z.string() }).parse(useParams());
  const queryClient = useQueryClient();

  const methods = useForm<
    ISchedulingFields & {
      infusionOut: boolean;
      infusionIn: boolean;
      handOutEDos: boolean;
      leaveMedication: boolean;
      handOutDosette: boolean;
    }
  >({
    defaultValues: {
      ...templates.medications,
      infusionOut: false,
      infusionIn: false,
      handOutEDos: false,
      leaveMedication: false,
      handOutDosette: false,
    },
  });

  const {
    formState: { errors, isDirty },
    getValues,
    handleSubmit,
    register,
    reset,
    setError,
  } = methods;

  const { mutate, isPending } = useMutation({
    mutationFn: (activities: INewActivity[]) => {
      return addActivitiesFromGroupTemplate({
        templateId: TEMPLATE_ID,
        templateRevision: TEMPLATE_REVISION,
        newActivities: activities,
      });
    },
    onError: (error) => {
      setError("root.server", {
        message: deducedError(error),
      });
      // Reset `isDirty` to support only showing server error when the form is not changed.
      reset(getValues(), {
        keepErrors: true,
        keepIsSubmitted: true,
        keepTouched: true,
        keepIsValid: true,
        keepSubmitCount: true,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: activityKeys.all,
      });
      setIsExpanded(false);
    },
  });

  const validateAndSubmit = handleSubmit((formData) => {
    const {
      startDate,
      endDate,
      recurrencesPerDay,
      timeCategory,
      timeslots,
      timeSensitivity,
      frequency,
      recurrence,
      customRecurrenceInterval,
      customRecurrenceIntervalUnit,
      weekdays,
      infusionIn,
      infusionOut,
      handOutEDos,
      leaveMedication,
      handOutDosette,
    } = formData;

    const timeConfig = {
      recurrencesPerDay,
      timeCategory,
      timeslots,
      timeSensitivity,
      frequency,
      recurrence,
      customRecurrenceInterval,
      customRecurrenceIntervalUnit,
      weekdays,
    };

    const activities: INewActivity[] = [];

    if (infusionIn) {
      activities.push(
        newActivityFromTemplateAlerisAhc({
          template: {
            ...medicalProceduresActivities.infusionIn,
            ...timeConfig,
          },
          startDate,
          endDate,
          patientId,
        }),
      );
    }
    if (infusionOut) {
      activities.push(
        newActivityFromTemplateAlerisAhc({
          template: {
            ...medicalProceduresActivities.infusionOut,
            ...timeConfig,
          },
          startDate,
          endDate,
          patientId,
        }),
      );
    }
    if (handOutEDos) {
      activities.push(
        newActivityFromTemplateAlerisAhc({
          template: {
            ...medicalProceduresActivities.handOutEDos,
            ...timeConfig,
          },
          startDate,
          endDate,
          patientId,
        }),
      );
    }
    if (leaveMedication) {
      activities.push(
        newActivityFromTemplateAlerisAhc({
          template: {
            ...medicalProceduresActivities.leaveMedication,
            ...timeConfig,
          },
          startDate,
          endDate,
          patientId,
        }),
      );
    }
    if (handOutDosette) {
      activities.push(
        newActivityFromTemplateAlerisAhc({
          template: {
            ...medicalProceduresActivities.handOutDosette,
            ...timeConfig,
          },
          startDate,
          endDate,
          patientId,
        }),
      );
    }

    mutate(activities);
  });

  return (
    <ExpansionPanel
      // Don't translate user-generated content
      // eslint-disable-next-line lingui/no-unlocalized-strings
      trigger={<GroupTemplateHeadingAleris title={`Läkemedel`} />}
      // Don't translate user-generated content
      // eslint-disable-next-line lingui/no-unlocalized-strings
      triggerAriaLabel={`Läkemedel`}
      isExpanded={isExpanded}
      setIsExpanded={setIsExpanded}
    >
      <div className={styles.content}>
        <FormProvider {...methods}>
          <Form onSubmit={validateAndSubmit}>
            <div>
              <TemplateHeadingAlerisAhc
                template={{
                  ...templates.medications,
                  // TODO: Build a dynamic way to get required competences from the included activities.
                  // This will be needed for future batches of activities.
                  requiredCompetences: [
                    medicalCompetenceSchema.Values.NursePractitioner,
                  ],
                }}
              />
              <div className={styles.dayAndTime}>
                <Scheduling />
              </div>
              <div className={styles.checkboxes}>
                <Checkboxes>
                  <Checkbox
                    errorMessage={errors.leaveMedication?.message}
                    label={{
                      text: medicalProceduresActivities.leaveMedication.title,
                    }}
                    {...register("leaveMedication")}
                  />
                  <Checkbox
                    errorMessage={errors.handOutEDos?.message}
                    label={{
                      text: medicalProceduresActivities.handOutEDos.title,
                    }}
                    {...register("handOutEDos")}
                  />
                  <Checkbox
                    errorMessage={errors.handOutDosette?.message}
                    label={{
                      text: medicalProceduresActivities.handOutDosette.title,
                    }}
                    {...register("handOutDosette")}
                  />
                  <Checkbox
                    errorMessage={errors.infusionIn?.message}
                    label={{
                      text: medicalProceduresActivities.infusionIn.title,
                    }}
                    {...register("infusionIn")}
                  />
                  <Checkbox
                    errorMessage={errors.infusionOut?.message}
                    label={{
                      text: medicalProceduresActivities.infusionOut.title,
                    }}
                    {...register("infusionOut")}
                  />
                </Checkboxes>
              </div>
            </div>
            {errors.root?.server?.message && !isDirty ? (
              <ErrorMessage
                message={errors.root.server.message}
                weight="bold"
              />
            ) : null}
            <FilledButton type="submit" disabled={isPending}>
              <Trans>Lägg till aktiviteter</Trans>
            </FilledButton>
          </Form>
        </FormProvider>
      </div>
    </ExpansionPanel>
  );
};
