import { t } from "@lingui/core/macro";
import styles from "./ShiftsSection.module.scss";
import { shiftsWithContentsQueryOptions } from "@/api/Shifts";
import { useSelectedDate } from "@/Utils/useSelectedDate";
import { Loading } from "@components/Loading/Loading";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import NoResults from "@/components/NoResults/NoResults";
import * as Sentry from "@sentry/react";
import { deducedError } from "@/Utils/ErrorUtils";
import { ShiftRow } from "./ShiftsRow";
import { useQuery } from "@tanstack/react-query";

export const ShiftsSection = ({
  isExpanded,
  onPendingAddToShift,
}: {
  isExpanded: boolean;
  onPendingAddToShift: (onPending: boolean) => void;
}) => {
  const selectedDate = useSelectedDate();

  const {
    data: shiftsWithContents,
    isPending,
    isError,
    error,
  } = useQuery(shiftsWithContentsQueryOptions(selectedDate));

  if (isPending) {
    return <Loading message={t`Hämtar arbetspass`} padding={24} />;
  }

  if (isError) {
    Sentry.captureException(error);
    return (
      <ErrorMessage
        message={`${t`Gick inte att hämta arbetspassen.`} ${deducedError(error)}`}
        padding={24}
      />
    );
  }

  if (shiftsWithContents.length === 0) {
    return (
      <NoResults
        message={t`Inga arbetspass schemalagda för dagen.`}
        padding={24}
      />
    );
  }

  return (
    <section className={styles.shiftsSection}>
      <ul className={styles.shiftsList}>
        {shiftsWithContents.map((shift) => {
          return (
            <ShiftRow
              key={shift.shift.id}
              shiftWithContents={shift}
              isExpanded={isExpanded}
              onPendingAddToShift={onPendingAddToShift}
            />
          );
        })}
      </ul>
    </section>
  );
};
