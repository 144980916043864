import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import styles from "./Details.module.scss";
import { Text } from "@components/Text/Text";
import { formattedTimeSpan } from "@/components/Time/timeUtils";
import {
  activityOccurrenceStatusSchema,
  categorySchema,
  visitStatusSchema,
} from "@models/activities";
import {
  getActivityOccurrenceStatusTimestamp,
  timeOfDayDictionary,
} from "@models/activities";
import { StatusTag } from "@/components/StatusTag/StatusTag";
import { type IVisit } from "@models/visits";
import { calculateVisitTimes, getVisitStatusTimestamp } from "@models/visits";
import { ActivityGroupTag } from "./ActivityGroupTag/ActivityGroupTag";
import type { IActivityOccurrence } from "@models/activities";
import { ActivityTitle } from "@/components/ActivityTitle/ActivityTitle";
import { StatusTagWithDropdown } from "@/components/StatusTagWithDropdown/StatusTagWithDropdown";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { unassignGroupFromRoute } from "@/api/Routes";
import { deducedError, displayErrorMessageAlert } from "@/Utils/ErrorUtils";
import {
  allocateShiftsToActivityOccurrence,
  allocateShiftsToVisit,
  deallocateShiftsFromActivityOccurrence,
  deallocateShiftsFromVisit,
} from "@/api/Activities";
import { QuickActivityInDetails } from "@/pages/commandcenter/Planning/TimelineTile/QuickActivityInDetails";
import { AssignedShiftChip } from "@/components/Chips/AssignedShiftChip";
import { endOfDay, isPast, startOfDay, startOfToday } from "date-fns";
import { ShiftDropdownMenu } from "@/pages/commandcenter/Activities/ShiftDropdownMenu";
import { getUnfulfilledRequirements } from "@/pages/commandcenter/helpers";
import { useLocation } from "react-router";
import { useLingui } from "@lingui/react";
import { patientStatusDictionary, patientStatusSchema } from "@models/patients";
import { RequirementChip } from "@/components/Chips/RequirementChip";
import WarningChip from "@/components/Chips/WarningChip";
import { PlainButton } from "@components/Button/Button";
import CrossIcon from "@components/icons/CrossIcon";

type IDetails = {
  visit: IVisit;
  routeId: string;
};

const WarningInfo = ({
  shouldShowUnfulfilledRequirementsWarning,
  shouldShowExpiredActivitiesWarning,
}: {
  shouldShowUnfulfilledRequirementsWarning: boolean;
  shouldShowExpiredActivitiesWarning: boolean;
}) => {
  return (
    <div className={styles.warningChipsWrapper}>
      {shouldShowUnfulfilledRequirementsWarning ? (
        <WarningChip>
          <Trans>Kompetensbehov uppfylls inte</Trans>
        </WarningChip>
      ) : null}
      {shouldShowExpiredActivitiesWarning ? (
        <WarningChip>
          <Trans>Innehåller ej genomförda aktiviteter</Trans>
        </WarningChip>
      ) : null}
    </div>
  );
};

export const Details = ({ visit, routeId }: IDetails) => {
  const { _ } = useLingui();
  const queryClient = useQueryClient();
  const queryParams = useLocation().search;

  const {
    mutate: unassignGroupFromRouteMutation,
    isPending: isUnassigningGroupFromRoute,
  } = useMutation({
    mutationFn: ({ groupId }: { groupId: string }) =>
      unassignGroupFromRoute(groupId, routeId),
    onError: (error) => {
      displayErrorMessageAlert(
        `${t`Gick inte att ta bort besöket från rutten.`} ${deducedError(error)}`,
      );
    },
    onSuccess: () => {
      return queryClient.invalidateQueries();
    },
  });

  const { mutate: deallocateShiftsMutation, isPending: isDeallocatingVisit } =
    useMutation({
      mutationFn: ({ shiftIds }: { shiftIds: number[] }) =>
        deallocateShiftsFromVisit(shiftIds, routeId, visit.id),
      onError: (error) => {
        displayErrorMessageAlert(
          `${t`Gick inte att ta bort arbetspasset från besöket.`} ${deducedError(error)}`,
        );
      },
      onSuccess: () => {
        if (assignees.length === 1) {
          unassignGroupFromRouteMutation({ groupId: visit.id });
        } else {
          return queryClient.invalidateQueries();
        }
      },
    });

  const { mutate: allocateShiftsMutation, isPending: isAllocating } =
    useMutation({
      mutationFn: ({ shiftIds }: { shiftIds: number[] }) =>
        allocateShiftsToVisit(shiftIds, routeId, visit.id),
      onError: (error) => {
        displayErrorMessageAlert(
          `${t`Gick inte att lägga till arbetspasset på besöket.`} ${deducedError(error)}`,
        );
      },
      onSuccess: async () => {
        return queryClient.invalidateQueries();
      },
    });

  const appendShift = (shiftIdToAdd: number) => {
    allocateShiftsMutation({
      shiftIds: [shiftIdToAdd],
    });
  };

  const removeShifts = (shiftIdsToRemove: number[]) => {
    deallocateShiftsMutation({
      shiftIds: shiftIdsToRemove,
    });
  };

  const { patient, occurrences, status } = visit;
  const statusTimestamp = getVisitStatusTimestamp(visit);
  const { start, end, isAnyTimeOfDay } = calculateVisitTimes(visit);
  const visitHasWellDefinedTime = start && end;
  const hasEndDayPassed = end ? isPast(endOfDay(end)) : false;
  const canMakeChangesToVisit =
    status !== visitStatusSchema.Values.finished && !hasEndDayPassed;
  const { assignees } = visit;
  const assignedShiftIds = assignees.map(({ id }) => id);
  const unfulfilledRequirements = getUnfulfilledRequirements(visit);
  const visitHasExpiredActivities = occurrences.some(
    (occurrence) =>
      occurrence.status === activityOccurrenceStatusSchema.Values.expired,
  );

  const hasUnfulfilledRequirementsToShow =
    unfulfilledRequirements.length > 0 &&
    visit.status !== visitStatusSchema.Values.finished;

  const hasWarnings =
    hasUnfulfilledRequirementsToShow || visitHasExpiredActivities;

  return (
    <div className={styles.details}>
      <ActivityGroupTag
        category={categorySchema.Values.HomeVisit}
        title={
          patient?.status === patientStatusSchema.Values.deleted
            ? _(patientStatusDictionary.deleted.singular)
            : patient.name
        }
        patientId={
          patient.status !== patientStatusSchema.Values.deleted
            ? patient.id
            : undefined
        }
      />
      <div className={styles.titleTimeDescription}>
        <div className={styles.visitAndStatus}>
          <Text element="div" size="large" weight="medium">
            <Trans>Hembesök</Trans>
          </Text>
          <StatusTag
            status={status}
            timestamp={statusTimestamp}
            variant="icon-and-text-with-timestamp"
          />
        </div>

        {isAnyTimeOfDay
          ? _(timeOfDayDictionary.Any.long)
          : visitHasWellDefinedTime
            ? formattedTimeSpan(start, end)
            : "-"}
      </div>
      <ul title="Aktiviteter">
        {occurrences.map((occurrence) => (
          <li key={occurrence.id}>
            <ActivityTitle
              activityOccurrence={occurrence}
              linkTo={`activities/${occurrence.activityId}/occurrences/${occurrence.id}${queryParams}`}
              weight="regular"
              showStatus={occurrence.status === "expired"}
            />
          </li>
        ))}
      </ul>
      {canMakeChangesToVisit ? (
        <QuickActivityInDetails groupId={visit.id} visitStatus={status} />
      ) : null}

      <div className={styles.separator} />
      <div className={styles.assignShifts}>
        {assignees.map((shift) => (
          <div key={shift.id}>
            <AssignedShiftChip
              medicalCompetence={shift.competence}
              shift={shift}
              size="small"
              onRemove={() => {
                removeShifts([shift.id]);
              }}
              disabled={isDeallocatingVisit || isUnassigningGroupFromRoute}
            />
          </div>
        ))}
        {unfulfilledRequirements.length > 0 ? (
          <ul className={styles.competences}>
            {unfulfilledRequirements.map((unfulfilledRequirement) => (
              <li key={unfulfilledRequirement}>
                <RequirementChip
                  requirement={unfulfilledRequirement}
                  state="unfulfilled"
                  size="small"
                />
              </li>
            ))}
          </ul>
        ) : undefined}
      </div>
      {canMakeChangesToVisit ? (
        <div className={styles.addAndRemoveCompetencesButtons}>
          <ShiftDropdownMenu
            assignedShiftIds={assignedShiftIds}
            unfulfilledRequirements={unfulfilledRequirements}
            onAllocate={appendShift}
            isAllocating={isAllocating}
            shiftsFromDate={startOfDay(
              visit.occurrences[0]
                ? visit.occurrences[0].start
                : startOfToday(),
            )}
            triggerType="icon-and-text"
            size="small"
          />
          <>
            <PlainButton
              size="small"
              weight="light"
              onClick={() => removeShifts(assignees.map((shift) => shift.id))}
            >
              <CrossIcon />
              {t`Ta bort alla`}
            </PlainButton>
          </>
        </div>
      ) : null}
      {hasWarnings ? (
        <>
          <div className={styles.separator} />
          <WarningInfo
            shouldShowUnfulfilledRequirementsWarning={
              hasUnfulfilledRequirementsToShow
            }
            shouldShowExpiredActivitiesWarning={visitHasExpiredActivities}
          />
        </>
      ) : null}
    </div>
  );
};

export const ActivityDetails = ({
  activityOccurrence,
}: {
  activityOccurrence: IActivityOccurrence;
}) => {
  const { _ } = useLingui();
  const {
    category,
    title,
    start,
    end,
    status: visitStatus,
    patient,
    timeOfDay,
    activityId,
    id,
  } = activityOccurrence;

  const queryClient = useQueryClient();
  const queryParams = useLocation().search;

  const { mutate: allocateShiftsMutation, isPending: isAllocating } =
    useMutation({
      mutationFn: ({
        shiftIds,
        activityId,
        occurrenceId,
      }: {
        shiftIds: number[];
        activityId: string;
        occurrenceId: string;
      }) =>
        allocateShiftsToActivityOccurrence(shiftIds, activityId, occurrenceId),
      onError: (error) => {
        displayErrorMessageAlert(
          `${t`Gick inte att allokera arbetspasset till aktivitetstillfället.`} ${deducedError(error)}`,
        );
      },
      onSuccess: () => {
        return queryClient.invalidateQueries();
      },
    });

  const { mutate: deallocateShiftsMutation, isPending: isDeallocating } =
    useMutation({
      mutationFn: ({
        shiftIds,
        activityId,
        occurrenceId,
      }: {
        shiftIds: number[];
        activityId: string;
        occurrenceId: string;
      }) =>
        deallocateShiftsFromActivityOccurrence(
          shiftIds,
          activityId,
          occurrenceId,
        ),
      onError: (error) => {
        displayErrorMessageAlert(
          `${t`Gick inte att ta bort arbetspasset från aktivitetstillfället.`} ${deducedError(error)}`,
        );
      },
      onSuccess: () => {
        return queryClient.invalidateQueries();
      },
    });

  const appendShift = ({
    shiftIdToAdd,
    activityId,
    occurrenceId,
  }: {
    shiftIdToAdd: number;
    activityId: string;
    occurrenceId: string;
  }) => {
    allocateShiftsMutation({
      shiftIds: [shiftIdToAdd],
      activityId,
      occurrenceId,
    });
  };

  const removeShifts = ({
    shiftIdsToRemove,
    activityId,
    occurrenceId,
  }: {
    shiftIdsToRemove: number[];
    activityId: string;
    occurrenceId: string;
  }) => {
    deallocateShiftsMutation({
      shiftIds: shiftIdsToRemove,
      activityId,
      occurrenceId,
    });
  };

  const isAnyTimeOfDay = timeOfDay === "Any";
  if (
    category === categorySchema.Values.PatientMeasurementTask ||
    category === categorySchema.Values.PatientTask
  ) {
    return null;
  }

  const statusTimestamp =
    getActivityOccurrenceStatusTimestamp(activityOccurrence);
  const hasEndDayPassed = end ? isPast(endOfDay(end)) : false;
  const canMakeChangesToVisit =
    visitStatus !== visitStatusSchema.Values.finished && !hasEndDayPassed;
  const assignees = activityOccurrence.assignees;
  const assignedShiftIds = assignees.map(({ id }) => id);
  const unfulfilledRequirements = getUnfulfilledRequirements({
    assignees,
    occurrences: [activityOccurrence],
  });

  const hasUnfulfilledRequirementsToShow =
    unfulfilledRequirements.length > 0 &&
    activityOccurrence.status !==
      activityOccurrenceStatusSchema.Values.finished;

  const hasWarnings =
    hasUnfulfilledRequirementsToShow ||
    activityOccurrence.status === activityOccurrenceStatusSchema.Values.expired;

  const RequirementChips = () => {
    return (
      <ul className={styles.competences}>
        {unfulfilledRequirements.map((unfulfilledRequirement) => (
          <li key={unfulfilledRequirement}>
            <RequirementChip
              requirement={unfulfilledRequirement}
              state="unfulfilled"
              size="small"
            />
          </li>
        ))}
      </ul>
    );
  };

  const AssignedShiftChips = () => {
    return (
      <>
        {assignees.map((shift) => (
          <div key={shift.id}>
            <AssignedShiftChip
              medicalCompetence={shift.competence}
              shift={shift}
              size="small"
              onRemove={() => {
                removeShifts({
                  shiftIdsToRemove: [shift.id],
                  activityId: activityOccurrence.activityId,
                  occurrenceId: activityOccurrence.id,
                });
              }}
              disabled={isDeallocating}
            />
          </div>
        ))}
      </>
    );
  };

  return (
    <div className={styles.details}>
      <ActivityGroupTag
        category={category}
        title={
          patient?.status === patientStatusSchema.Values.deleted
            ? _(patientStatusDictionary.deleted.singular)
            : (patient?.name ?? title)
        }
        patientId={
          patient?.status && patient.status !== "deleted"
            ? patient?.id
            : undefined
        }
      />
      <div className={styles.titleTimeDescription}>
        <div className={styles.visitAndStatus}>
          <ActivityTitle
            activityOccurrence={activityOccurrence}
            linkTo={`activities/${activityOccurrence.activityId}/occurrences/${activityOccurrence.id}${queryParams}`}
            weight="medium"
          />
          <StatusTagWithDropdown
            status={visitStatus}
            category={category}
            activityId={activityId}
            occurrenceId={id}
            timestamp={statusTimestamp}
            variant="icon-and-text-with-timestamp"
          />
        </div>
        {isAnyTimeOfDay
          ? _(timeOfDayDictionary.Any.long)
          : formattedTimeSpan(start, end)}
      </div>
      <div className={styles.separator} />
      <div className={styles.assignShifts}>
        <AssignedShiftChips />
        {unfulfilledRequirements.length > 0 ? <RequirementChips /> : null}
      </div>
      {canMakeChangesToVisit ? (
        <div className={styles.addAndRemoveCompetencesButtons}>
          <ShiftDropdownMenu
            assignedShiftIds={assignedShiftIds}
            unfulfilledRequirements={unfulfilledRequirements}
            onAllocate={(shiftId: number) =>
              appendShift({
                shiftIdToAdd: shiftId,
                activityId: activityOccurrence.activityId,
                occurrenceId: activityOccurrence.id,
              })
            }
            isAllocating={isAllocating}
            shiftsFromDate={startOfDay(activityOccurrence.start)}
            triggerType="icon-and-text"
            size="small"
          />
          <PlainButton
            size="small"
            weight="light"
            onClick={() =>
              removeShifts({
                shiftIdsToRemove: assignees.map((shift) => shift.id),
                activityId: activityOccurrence.activityId,
                occurrenceId: activityOccurrence.id,
              })
            }
          >
            <CrossIcon />
            {t`Ta bort alla`}
          </PlainButton>
        </div>
      ) : null}
      {hasWarnings ? (
        <>
          <div className={styles.separator} />
          <WarningInfo
            shouldShowUnfulfilledRequirementsWarning={
              hasUnfulfilledRequirementsToShow
            }
            shouldShowExpiredActivitiesWarning={
              activityOccurrence.status ===
              activityOccurrenceStatusSchema.Values.expired
            }
          />
        </>
      ) : null}
    </div>
  );
};
