import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import { FilledButton } from "@components/Button/Button";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import Form from "@/components/Form/Form";
import { Loading } from "@components/Loading/Loading";
import { deducedError } from "@/Utils/ErrorUtils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { saveActivityInvoicingCodes, useInvoicingCodes } from "@/api/Invoicing";
import { invoicingCodeTypeOptionsSchema } from "@models/invoicing";
import Select from "@/components/Select/Select";
import { useLingui } from "@lingui/react";

export const EditInvoicingCodes = ({
  currentProcedureCode,
  currentProductCode,
  onSuccess,
  activityId,
  patientId,
}: {
  currentProcedureCode: string | null;
  currentProductCode: string | null;
  onSuccess: () => void;
  activityId: string;
  patientId: string;
}) => {
  const {
    formState: { errors, isDirty },
    getValues,
    handleSubmit,
    register,
    reset,
    setError,
  } = useForm({
    defaultValues: {
      procedureCode: currentProcedureCode,
      productCode: currentProductCode,
    },
  });

  const { _ } = useLingui();
  const queryClient = useQueryClient();
  const {
    data: invoicingCodes,
    isPending: isPendingInvoicingCodes,
    isError,
  } = useInvoicingCodes({
    patientId: patientId,
    codeType: null,
  });

  const { mutate: mutateActivity, isPending: isPendingActivity } = useMutation({
    mutationFn: ({
      activityId,
      patientId,
      procedureCodes,
      productCodes,
    }: {
      activityId: string;
      patientId: string;
      procedureCodes: string[];
      productCodes: string[];
    }) =>
      saveActivityInvoicingCodes({
        activityId,
        patientId,
        procedureCodes: procedureCodes,
        productCodes: productCodes,
      }),
    onError: (error) => {
      setError("root.server", {
        message: deducedError(error),
      });
      // Reset `isDirty` to support only showing server error when the form is not changed.
      reset(getValues(), {
        keepErrors: true,
        keepIsSubmitted: true,
        keepTouched: true,
        keepIsValid: true,
        keepSubmitCount: true,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries();
      onSuccess();
    },
  });

  if (isPendingInvoicingCodes) {
    return <Loading message={t`Hämtar faktureringskoder`} />;
  }

  if (isError) {
    return <ErrorMessage message={t`Kunde inte hämta faktureringskoder`} />;
  }

  const procedureCodes = invoicingCodes.filter(
    (code) => code.type === invoicingCodeTypeOptionsSchema.Values.procedureCode,
  );

  const productCodes = invoicingCodes.filter(
    (code) => code.type === invoicingCodeTypeOptionsSchema.Values.productCode,
  );

  const hasProductCodes = productCodes.length > 0;

  return (
    <Form
      onSubmit={handleSubmit((formData) => {
        const { procedureCode, productCode } = formData;
        return mutateActivity({
          activityId,
          patientId: patientId,
          procedureCodes: procedureCode ? [procedureCode] : [],
          productCodes: productCode ? [productCode] : [],
        });
      })}
    >
      {isPendingActivity ? (
        <Loading message={t`Ändrar faktureringskoder`} />
      ) : (
        <>
          {errors.root?.server?.message && !isDirty ? (
            <ErrorMessage message={errors.root.server.message} />
          ) : null}
          <Form.Row>
            <Select
              label={t`Åtgärd (KVÅ)`}
              {...register("procedureCode")}
              errorMessage={errors.procedureCode?.message}
            >
              <option value="">
                <Trans>Ingen</Trans>
              </option>
              {procedureCodes.map((procedureCode) => (
                <option key={procedureCode.id} value={procedureCode.id}>
                  {`${procedureCode.code}, ${_(procedureCode.name)}`}
                </option>
              ))}
            </Select>
          </Form.Row>
          {hasProductCodes ? (
            <Form.Row>
              <Select
                label={t`Produkt`}
                {...register("productCode")}
                errorMessage={errors.productCode?.message}
              >
                <option value="">
                  <Trans>Ingen</Trans>
                </option>
                {productCodes.map((productCode) => (
                  <option key={productCode.id} value={productCode.id}>
                    {`${productCode.code}, ${_(productCode.name)}`}
                  </option>
                ))}
              </Select>
            </Form.Row>
          ) : (
            <> </>
          )}
          {isDirty ? (
            <FilledButton type="submit">
              <Trans>Spara ändringar</Trans>
            </FilledButton>
          ) : (
            <></>
          )}
        </>
      )}
    </Form>
  );
};
