import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import {
  finishActivityOccurrence,
  removeActivityOccurrence,
} from "@/api/Activities";
import { Popover } from "@/components/Popover/Popover";
import { PlainButton } from "@components/Button/Button";
import { Loading } from "@components/Loading/Loading";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import styles from "./NotExecutedActivityOccurrencePopover.module.scss";
import WarningTriangleIcon from "@components/icons/WarningTriangleIcon";
import CrossIcon from "@components/icons/CrossIcon";
import CheckmarkIcon from "@components/icons/CheckmarkIcon";
import { Text } from "@components/Text/Text";

export const NotExecutedActivityOccurrencePopover = ({
  activityId,
  occurrenceId,
  title,
}: {
  activityId: string;
  occurrenceId: string;
  title: string;
}) => {
  const queryClient = useQueryClient();

  const { mutate: removeActivityOccurrenceMutation, isPending: isRemoving } =
    useMutation({
      mutationFn: () => removeActivityOccurrence(activityId, occurrenceId),
      onSuccess: () => {
        return queryClient.invalidateQueries();
      },
    });

  const { mutate: finishActivityOccurrenceMutation, isPending: isFinishing } =
    useMutation({
      mutationFn: () => finishActivityOccurrence(activityId, occurrenceId),
      onSuccess: () => {
        return queryClient.invalidateQueries();
      },
    });

  const Content = () => {
    const isPending = isRemoving || isFinishing;
    return (
      <>
        <Text element="p">
          <Trans>
            <em>{title}</em> är inte markerat som utfört. Välj hur du vill
            hantera detta.
          </Trans>
        </Text>
        <div className={styles.buttons}>
          <PlainButton
            disabled={isPending}
            onClick={() => removeActivityOccurrenceMutation()}
          >
            <CrossIcon />
            <Trans>Ta bort</Trans>
          </PlainButton>
          <PlainButton
            disabled={isPending}
            onClick={() => finishActivityOccurrenceMutation()}
          >
            <CheckmarkIcon />
            <Trans>Markera som utfört</Trans>
          </PlainButton>
        </div>
      </>
    );
  };

  const Status = () => {
    if (isRemoving) {
      return <Loading message={t`Tar bort ${title}`} />;
    }

    if (isFinishing) {
      return <Loading message={t`Markerar ${title} som utfört`} />;
    }
  };

  return (
    <Popover
      trigger={{
        icon: (
          <div className={styles.neutralIcon}>
            <WarningTriangleIcon />
          </div>
        ),
        ariaLabel: t`Ej utfört. Klicka för att hantera.`,
      }}
    >
      <div className={styles.content}>
        <Status />
        <Content />
      </div>
    </Popover>
  );
};
