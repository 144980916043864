import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import styles from "./Activities.module.scss";
import { Loading } from "@components/Loading/Loading";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import { usePatientsActivityOccurrencesAndGroups } from "@/api/Activities";
import NoResults from "@/components/NoResults/NoResults";
import { useParentRoutesPatient } from "@/api/Patients";
import {
  addDays,
  subDays,
  differenceInCalendarDays,
  startOfWeek,
  endOfWeek,
} from "date-fns";
import { dateName } from "@/Utils/DateUtils";
import { Outlet, useNavigate } from "react-router";
import { PlainButton } from "@components/Button/Button";
import { SplitPane } from "@/components/SplitPane/SplitPane";
import { deducedError } from "@/Utils/ErrorUtils";
import PlusIcon from "@components/icons/PlusIcon";
import type { IPatient } from "@models/patients";
import { activeStatuses } from "@models/patients";
import ArrowUpIcon from "@components/icons/ArrowUpIcon";
import ArrowDownIcon from "@components/icons/ArrowDownIcon";
import { useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import { format } from "@models/date-and-time";
import { knownFeatureFlagsSchema, useFeatureFlag } from "@/api/FeatureFlags";
import RadioButton from "@/components/RadioButton/RadioButton";
import BulletListIcon from "@components/icons/BulletListIcon";
import { Text } from "@components/Text/Text";
import CalendarIcon from "@components/icons/CalendarIcon";
import { z } from "zod";
import { ActivitiesCalendar } from "./ActivitiesCalendar/ActivitiesCalendar";
import { useLocalStorage } from "@/Utils/useLocalStorage";
import { ActivitiesList } from "./ActivitiesList/ActivitiesList";

// Enforced by backend
const MAXIMUM_ALLOWED_SPAN_DAYS = 100;
// The number 15 for list has its roots here: https://linear.app/medoma/issue/MED-4661/see-more-days-on-patient
export const DAYS_AHEAD_TO_SHOW_ACTIVITIES_IN_LIST = 14;
// IMPORTANT: May not be larger than (MAXIMUM_ALLOWED_SPAN_DAYS - 12 days) = 88 days
// due to `startOfWeek` and `endOfWeek` functions in calendarViewModeConfig below.
export const DAYS_AHEAD_TO_SHOW_ACTIVITIES_IN_CALENDAR = 28;

const canAddActivitiesToPatient = ({ status }: Pick<IPatient, "status">) =>
  activeStatuses.some((s) => s === status);

const viewModes = ["list", "calendar"] as const;
const viewModesSchema = z.enum(viewModes);
type IViewMode = z.infer<typeof viewModesSchema>;

const ViewSelectButton = ({ viewMode }: { viewMode: IViewMode }) => {
  return (
    <div className={styles.viewModeSelectorButton}>
      {viewMode === viewModesSchema.Values.list ? (
        <>
          <BulletListIcon />
          <Text element="div">
            <Trans>Lista</Trans>
          </Text>
        </>
      ) : (
        <>
          <CalendarIcon />
          <Text element="div">
            <Trans>Kalender</Trans>
          </Text>
        </>
      )}
    </div>
  );
};

const Activities = () => {
  const patient = useParentRoutesPatient();
  const navigate = useNavigate();

  const { data: calendarViewEnabled } = useFeatureFlag(
    knownFeatureFlagsSchema.Values.CalendarView,
  );

  const [selectedViewMode, setSelectedViewMode] = useLocalStorage(
    "patientActivitiesViewMode",
    viewModesSchema.Values.list,
  );

  // Make sure that the view mode is set to list when the CalendarView feature flag is disabled.
  // Clean this up when cleaning up the feature flag "CalendarView".
  useEffect(() => {
    if (calendarViewEnabled === false) {
      setSelectedViewMode(viewModesSchema.Values.list);
    }
  }, [calendarViewEnabled, setSelectedViewMode]);

  const today = new Date();

  const listViewModeConfig = {
    initialFromDate: today,
    initialToDate: addDays(today, DAYS_AHEAD_TO_SHOW_ACTIVITIES_IN_LIST),
    expandByDays: 1,
  };

  const calendarViewModeConfig = {
    initialFromDate: startOfWeek(today, { weekStartsOn: 1 }),
    initialToDate: endOfWeek(
      addDays(today, DAYS_AHEAD_TO_SHOW_ACTIVITIES_IN_CALENDAR),
      { weekStartsOn: 1 },
    ),
    expandByDays: 7,
  };

  const [from, setFrom] = useState<Date>(
    selectedViewMode === viewModesSchema.Values.list
      ? listViewModeConfig.initialFromDate
      : calendarViewModeConfig.initialFromDate,
  );
  const [to, setTo] = useState<Date>(
    selectedViewMode === viewModesSchema.Values.list
      ? listViewModeConfig.initialToDate
      : calendarViewModeConfig.initialToDate,
  );

  const handleViewModeChange = (viewMode: IViewMode) => {
    if (viewMode === viewModesSchema.Values.list) {
      setFrom(listViewModeConfig.initialFromDate);
      setTo(listViewModeConfig.initialToDate);
    } else {
      setFrom(calendarViewModeConfig.initialFromDate);
      setTo(calendarViewModeConfig.initialToDate);
    }

    setSelectedViewMode(viewMode);
  };

  const dateSpanInDays = differenceInCalendarDays(to, from) + 1; // +1 to include the end date

  const {
    data: activityOccurrencesAndGroups,
    isPending,
    isError,
    error,
    isPlaceholderData,
  } = usePatientsActivityOccurrencesAndGroups(
    patient.id,
    format(from, "yyyy-MM-dd"),
    format(to, "yyyy-MM-dd"),
  );

  if (isPending) {
    return (
      <Loading
        message={t`Laddar patientens aktivitetstillfällen`}
        padding={24}
      />
    );
  }

  if (isError) {
    Sentry.captureException(error);
    return <ErrorMessage message={deducedError(error)} padding={24} />;
  }

  const expandDaysBack = (numberOfDays: number) => {
    // We want to make sure we don't create a larger date span than the maximum allowed
    if (dateSpanInDays + numberOfDays > MAXIMUM_ALLOWED_SPAN_DAYS) {
      setTo((prev) => subDays(prev, numberOfDays));
    }
    setFrom((prev) => subDays(prev, numberOfDays));
  };

  const expandDaysAhead = (numberOfDays: number) => {
    // We want to make sure we don't create a larger date span than the maximum allowed
    if (dateSpanInDays + numberOfDays > MAXIMUM_ALLOWED_SPAN_DAYS) {
      setFrom((prev) => addDays(prev, numberOfDays));
    }
    setTo((prev) => addDays(prev, numberOfDays));
  };

  const displayedDays = Array.from({ length: dateSpanInDays }, (_, index) =>
    addDays(from, index),
  );

  const patientName = patient.name;
  const startDateName = dateName(from);
  const endDateName = dateName(to);
  const hasActivityOccurrencesOrGroups =
    activityOccurrencesAndGroups.length > 0;

  const ViewModeSelector = () => (
    <div className={styles.viewModeSelectorGroup}>
      <RadioButton
        label={{
          text: t`Lista`,
          component: (
            <ViewSelectButton viewMode={viewModesSchema.Values.list} />
          ),
        }}
        name="view—selector"
        value={viewModesSchema.Values.list}
        checked={selectedViewMode === viewModesSchema.Values.list}
        onChange={() => handleViewModeChange(viewModesSchema.Values.list)}
        visualStyle="framed"
      />
      <RadioButton
        label={{
          text: t`Kalender`,
          component: (
            <ViewSelectButton viewMode={viewModesSchema.Values.calendar} />
          ),
        }}
        name="view—selector"
        value={viewModesSchema.Values.calendar}
        checked={selectedViewMode === viewModesSchema.Values.calendar}
        onChange={() => handleViewModeChange(viewModesSchema.Values.calendar)}
        visualStyle="framed"
      />
    </div>
  );

  const ShowEarlierButton = () => {
    if (selectedViewMode === viewModesSchema.Values.calendar) {
      return (
        <PlainButton
          onClick={() => expandDaysBack(calendarViewModeConfig.expandByDays)}
          disabled={isPlaceholderData}
        >
          <ArrowUpIcon />
          <Trans>Visa föregående vecka</Trans>
        </PlainButton>
      );
    }

    return (
      <PlainButton
        onClick={() => expandDaysBack(listViewModeConfig.expandByDays)}
        disabled={isPlaceholderData}
      >
        <ArrowUpIcon />
        <Trans>Visa föregående dag</Trans>
      </PlainButton>
    );
  };

  const ShowLaterButton = () => {
    if (selectedViewMode === viewModesSchema.Values.calendar) {
      return (
        <PlainButton
          onClick={() => expandDaysAhead(calendarViewModeConfig.expandByDays)}
          disabled={isPlaceholderData}
        >
          <ArrowDownIcon />
          <Trans>Visa nästa vecka</Trans>
        </PlainButton>
      );
    }

    return null;
  };

  const ToolbarTop = () => (
    <div className={styles.activitiesControls}>
      {calendarViewEnabled ? <ViewModeSelector /> : null}
      <ShowEarlierButton />
      {canAddActivitiesToPatient(patient) ? (
        <PlainButton onClick={() => navigate("templates")}>
          <PlusIcon />
          <Trans>Ny aktivitet</Trans>
        </PlainButton>
      ) : undefined}
    </div>
  );

  const ToolbarBottom = () => (
    <div className={styles.activitiesControls}>
      <ShowLaterButton />
    </div>
  );

  return (
    <SplitPane>
      <div className={styles.activities}>
        <ToolbarTop />
        {hasActivityOccurrencesOrGroups ? (
          selectedViewMode === viewModesSchema.Values.list ? (
            <ActivitiesList
              activityOccurrencesAndGroups={activityOccurrencesAndGroups}
              displayedDays={displayedDays}
              isPlaceholderData={isPlaceholderData}
              patientName={patientName}
            />
          ) : (
            <ActivitiesCalendar
              activityOccurrencesAndGroups={activityOccurrencesAndGroups}
              displayedDays={displayedDays}
              isPlaceholderData={isPlaceholderData}
            />
          )
        ) : (
          <NoResults
            message={t`${patientName} har inga aktiviteter mellan ${startDateName} och ${endDateName}`}
          />
        )}
        <ToolbarBottom />
      </div>
      <Outlet />
    </SplitPane>
  );
};

export default Activities;
