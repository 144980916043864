import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import styles from "./Header.module.scss";
import Person from "@/components/Person/Person";
import { useUserProfile } from "@/api/Users";
import { LoadingOverlay } from "@components/Loading/Loading";
import DropdownMenu from "@/components/DropdownMenu/DropdownMenu";
import LogoutIcon from "@components/icons/SVG/logout.svg?react";
import { Link, NavLink } from "react-router";
import LogoText from "@components/icons/LogoText";
import {
  SmsEmployeeNotificationDropdownToggleItem,
  SmsPatientNotificationDropdownToggleItem,
} from "@/components/SmsNotificationDropdownToggleItem/SmsNotificationDropdownToggleItem";
import { useCurrentEmployee } from "@/api/Employee";
import { DropdownMenuGroup } from "@radix-ui/react-dropdown-menu";
import { useContext, useEffect } from "react";
import * as Sentry from "@sentry/react";
import SentryFeedbackButton from "@/components/SentryFeedbackButton/SentryFeedbackButton";
import { useRoomNamesWithUnreadNotifications } from "@chat/useUnreadNotificationCounts";
import UnreadChatMessagesIndicator from "@/components/IconWithIndicator/UnreadChatMessagesIndicator/UnreadChatMessagesIndicator";
import ChatContext from "@chat/ChatContext";
import { UnstyledLink } from "@components/Link/Link";
import NotificationCircle from "@components/NotificationCircle/NotificationCircle";
import ChatConversationIcon from "@components/icons/ChatConversation";
import { patientsWithUnhandledMeasurementsQueryOptions } from "@/api/Patients";
import { useQuery } from "@tanstack/react-query";
import UnhandledMeasurementsIndicator from "@/components/IconWithIndicator/UnhandledMeasurementsIndicator/UnhandledMeasurementsIndicator";
import { employeeName } from "@models/shifts";
import { getEmployeeUnitPrefix } from "@chat/roomAlias";
import { CHAT_ENVIRONMENT } from "@/Utils/EnvUtils";
import { useAuth } from "react-oidc-context";
import { knownFeatureFlagsSchema, useFeatureFlag } from "@/api/FeatureFlags";

const Navigation = () => {
  const { unit } = useUserProfile();
  const { data: patientsWithUnhandledMeasurements } = useQuery(
    patientsWithUnhandledMeasurementsQueryOptions,
  );

  const { data: shouldShowInvoicing } = useFeatureFlag(
    knownFeatureFlagsSchema.Values.PERMANENT_ShowInvoicingInUI,
  );

  const roomNamesWithUnreadNotifications =
    useRoomNamesWithUnreadNotifications();
  const roomNamesWithUnreadNotificationsExceptEmployees =
    roomNamesWithUnreadNotifications.filter(
      (roomName) =>
        !roomName.startsWith(
          getEmployeeUnitPrefix({ env: CHAT_ENVIRONMENT, unit }),
        ),
    );
  const patientRoomsHaveUnreadChatNotifications =
    roomNamesWithUnreadNotificationsExceptEmployees !== undefined &&
    roomNamesWithUnreadNotificationsExceptEmployees.length > 0;

  const patientsHaveUnhandledMeasurements =
    patientsWithUnhandledMeasurements !== undefined &&
    patientsWithUnhandledMeasurements.some(
      ({ unhandledMeasurementsCount }) => unhandledMeasurementsCount > 0,
    );

  const { data: shouldShowShiftManipulation } = useFeatureFlag(
    "PERMANENT_ShowShiftManipulationInUI",
  );

  return (
    <nav>
      <ul className={styles.navItems}>
        <li>
          <NavLink to="patients" className={styles.navItem}>
            <Trans>Patienter</Trans>
            <UnreadChatMessagesIndicator
              hasUnreadMessages={
                patientRoomsHaveUnreadChatNotifications ?? false
              }
            />
            <UnhandledMeasurementsIndicator
              hasUnhandledMeasurements={patientsHaveUnhandledMeasurements}
            />
          </NavLink>
        </li>
        <li>
          <NavLink to="activities" className={styles.navItem}>
            <Trans>Aktiviteter</Trans>
          </NavLink>
        </li>
        <li>
          <NavLink to="planning" className={styles.navItem}>
            <Trans>Planering</Trans>
          </NavLink>
        </li>
        {shouldShowShiftManipulation ? (
          <li>
            <NavLink to="shifts" className={styles.navItem}>
              <Trans context="plural">Medarbetare</Trans>
            </NavLink>
          </li>
        ) : null}
        {shouldShowInvoicing ? (
          <li>
            <NavLink to="reporting" className={styles.navItem}>
              <Trans>Rapportering</Trans>
            </NavLink>
          </li>
        ) : (
          <></>
        )}
      </ul>
    </nav>
  );
};

const ChatLink = () => {
  const { unit } = useUserProfile();
  const roomIdsWithUnreadNotifications = useRoomNamesWithUnreadNotifications();
  const employeeRoomsWithUnreadNotifications =
    roomIdsWithUnreadNotifications?.filter((id) =>
      id.startsWith(getEmployeeUnitPrefix({ env: CHAT_ENVIRONMENT, unit })),
    );

  const { matrixClient } = useContext(ChatContext);
  const hasCompletedInitialSync = !!matrixClient;

  const numberOfEmployeesWithUnreadNotifications =
    employeeRoomsWithUnreadNotifications?.length;

  return (
    <UnstyledLink to="chat">
      <div className={styles.iconLink}>
        <LoadingOverlay show={!hasCompletedInitialSync}>
          <NotificationCircle
            isVisible={!!numberOfEmployeesWithUnreadNotifications}
            placement="top-left"
            number={numberOfEmployeesWithUnreadNotifications}
          >
            <ChatConversationIcon />
          </NotificationCircle>
        </LoadingOverlay>
      </div>
    </UnstyledLink>
  );
};

const Header = () => {
  const { givenName } = useUserProfile();
  const { data: employee } = useCurrentEmployee();
  const { chatEnabled } = useContext(ChatContext);
  useEffect(() => {
    if (employee) {
      Sentry.setUser({
        username: employeeName(employee),
      });
    }
  }, [employee]);

  const { signoutRedirect } = useAuth();

  const CustomTrigger = () => (
    <Person name={givenName} photoUrl={employee?.imageURL ?? null} />
  );

  return (
    <header className={styles.header}>
      <Navigation />
      <div className={styles.logoContainer}>
        <Link aria-label={t`Startsida`} to="/" className={styles.logo}>
          <LogoText />
        </Link>
      </div>
      <div className={styles.rightContainer}>
        {chatEnabled ? <ChatLink /> : <></>}
        <DropdownMenu
          trigger={{
            custom: <CustomTrigger />,
            ariaLabel: t`Meny`,
          }}
        >
          {chatEnabled ? (
            <>
              <DropdownMenuGroup>
                <DropdownMenu.Label>
                  <Trans>SMS-notiser för chatt</Trans>
                </DropdownMenu.Label>
                <SmsPatientNotificationDropdownToggleItem />
                <SmsEmployeeNotificationDropdownToggleItem />
              </DropdownMenuGroup>
              <DropdownMenu.Separator />
            </>
          ) : (
            <></>
          )}
          <DropdownMenu.Item content={<SentryFeedbackButton />} />
          <DropdownMenu.Separator />
          <DropdownMenu.Item
            action={() =>
              signoutRedirect({
                post_logout_redirect_uri: window.location.origin,
              })
            }
            content={
              <>
                <LogoutIcon /> <Trans>Logga ut</Trans>
              </>
            }
          />
        </DropdownMenu>
      </div>
    </header>
  );
};

export default Header;
