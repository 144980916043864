import { DaySwitcher } from "../DaySwitcher/DaySwitcher";
import styles from "./PageToolbar.module.scss";

type IPageToolbar = {
  children?: React.ReactNode;
  showDaySwitcher: boolean;
};

const PageToolbar = ({ children, showDaySwitcher }: IPageToolbar) => {
  return (
    <section className={styles.toolbar}>
      {showDaySwitcher ? <DaySwitcher size="h2" /> : null}
      {children}
    </section>
  );
};

export { PageToolbar };
