/* eslint-disable lingui/no-unlocalized-strings */
// Don't translate user-generated content
const homeVisitSuggestions = [
  `Blodtransfusion`,
  `Byte av KAD`,
  `Byte av pump`,
  `Centrala infarter`,
  `Drän`,
  `Fysisk undersökning`,
  `Förflyttningsbedömning`,
  `Handläggning av neutropen infektion`,
  `Hjälpmedel`,
  `Hämtning av utlånad droppställning`,
  `Hämtning tekniklåda`,
  `Infusion dropp`,
  `Infusion in`,
  `Infusion ut`,
  `Injektion`,
  `Inskrivningssamtal`,
  `KAD - kateter`,
  `Kateter`,
  `Konferens med patient`,
  `Konferens om patient`,
  `Kontroll infart`,
  `Kontroll av accesser och dränage`,
  `Koppla bort pump`,
  `Koppla pump`,
  `Leverans läkemedel`,
  `Läkemedel`,
  `Läkemedel intravenöst`,
  `Lämna aktuell läkemedelslista`,
  `Lämna informationsmapp`,
  `Nefrostomi`,
  `Nyckelkvittens`,
  `Piccline`,
  `Provtagning`,
  `Riskbedömningar`,
  `Stomi`,
  `Symptomkontroll`,
  `Såromläggning`,
  `Utlämnande E-dos`,
  `Återlämning nyckel`,
] as const;

const videoCallSuggestions = [
  `Anhörigsamtal`,
  `Andningsbedömning`,
  `Digitalt besök`,
  `Läkemedelsgenomgång`,
  `Pumpkontroll digital`,
  `Rond`,
  `Uppföljande samtal`,
] as const;

const patientTaskSuggestions = [
  `Chatta vilka läkemedel du behöver`,
  `Ta medicin`,
  `Vätske- och urinmätning`,
] as const;

const adminTaskSuggestions = [
  `Arbetsmiljö riskbedömning`,
  `Beställ blod`,
  `Beställ e-dosrulle`,
  `Beställ läkemedel`,
  `Bevaka inläggning`,
  `Bevaka prover`,
  `Dela patientens dosett`,
  `Dokumentera riskbedömningar TC`,
  `Efterlevandesamtal`,
  `Förskrivning`,
  `Fördela morgondagens planerade besök`,
  `Följ upp patientens inrapporterade värden`,
  `Kontrollera remisskorg i TC`,
  `Kontrollera temp läkemedelskylskåp`,
  `Kontrollera läkemedels utgångsdatum`,
  `Planera nästa veckas blodprov `,
  `Kvittera Life Care`,
  `Provinlämning St Görans Sjukhus`,
  `Provinlämning VC Lidingö`,
  `Provinlämning NKS`,
  `Provinlämning Torsvik VC`,
  `Räkna narkotika, kontrollera datum`,
  `Samtal med anhörig`,
  `Samtal med patient`,
  `Städa läkemedelsrum`,
  `Ställ ut tvätt`,
  `Teamkonferens`,
  `Trygghetskvitto`,
  `Uppdatera ASIH-telefonnr i TC`,
  `Uppdatera Belport`,
  `Uppdatera nummer för patient/anhörig`,
  `Utskrivning`,
  `Vattna blommorna`,
] as const;

const patientMeasurementTaskSuggestions = [
  `Egen mätning`,
  `Egen mätning morgon`,
  `Egen mätning kväll`,
  `Egen mätning andningsfrekvens`,
  `Egen mätning blodsocker`,
  `Egen mätning puls`,
  `Egen mätning syremättnad`,
  `Egen mätning temperatur`,
  `Egen mätning vikt`,
] as const;

export const titleSuggestions = {
  homeVisitSuggestions,
  videoCallSuggestions,
  patientTaskSuggestions,
  adminTaskSuggestions,
  patientMeasurementTaskSuggestions,
};
