import { queryOptions, useQuery } from "@tanstack/react-query";
import { z } from "zod";
import { logisticsApi } from "./ApiClient";
import { generateQueryString } from "./Helpers";
import {
  medicalCompetenceSchema,
  scheduledShiftSchema,
  scheduledShiftsSchema,
} from "@models/shifts";
import { deduplicatePrimitiveArray } from "@/Utils/arrayUtils";
import { fetchPatients } from "./Patients";
import { visitSchema, visitSchemaWithPatientId } from "@models/visits";
import {
  activityOccurrenceSchema,
  activityOccurrenceWithPatientIdSchema,
} from "@models/activities";
import {
  optionallyNamedRouteSchema,
  optionallyNamedRouteWithPatientIdSchema,
} from "./Routes";
import { format } from "@models/date-and-time";
import { resolvePatient } from "./Patients";

async function fetchScheduledShifts(from?: string, to?: string) {
  const scheduledShiftsResponse = await logisticsApi.get(
    `/schedule${generateQueryString({ from, to })}`,
  );
  const parsedShifts = scheduledShiftsSchema
    .parse(scheduledShiftsResponse.data)
    .sort((shift, othershift) =>
      shift.startDateTime > othershift.startDateTime ? 1 : -1,
    );
  return parsedShifts;
}

async function fetchScheduledShiftsInCenter(from?: string, to?: string) {
  const scheduledShiftsResponse = await logisticsApi.get(
    `/schedule/center${generateQueryString({ from, to })}`,
  );
  const parsedShifts = scheduledShiftsSchema
    .parse(scheduledShiftsResponse.data)
    .sort((shift, othershift) =>
      shift.startDateTime > othershift.startDateTime ? 1 : -1,
    );
  return parsedShifts;
}

async function fetchShiftsWithContents(date: string) {
  const shiftsWithContentsWithPatientIdsResponse = await logisticsApi.get(
    `/schedule/planned${generateQueryString({ date })}`,
  );

  const parsedShiftsWithContentsWithPatientIdsResponse = z
    .array(shiftWithContentsWithPatientIds)
    .parse(shiftsWithContentsWithPatientIdsResponse.data);

  // AdminTask activities sometimes miss patientId
  const patientIds = deduplicatePrimitiveArray(
    parsedShiftsWithContentsWithPatientIdsResponse.flatMap(({ items }) =>
      items.flatMap((item) => {
        const isRoute = "visits" in item;
        if (isRoute) {
          return item.visits.map((visit) => visit.patientId);
        }
        const isActivityOccurrenceWithNoPatient = !("patientId" in item);
        if (isActivityOccurrenceWithNoPatient || !item.patientId) {
          return [];
        }
        return [item.patientId];
      }),
    ),
  );

  const patients = await fetchPatients({ patientIds });

  const enrichedShifts = parsedShiftsWithContentsWithPatientIdsResponse.map(
    (shiftWithContentsWithPatientIds) => ({
      ...shiftWithContentsWithPatientIds,
      items: shiftWithContentsWithPatientIds.items.map((item) => {
        const isNotRoute = "patientId" in item;
        const isRoute = "visits" in item;
        if (isNotRoute) {
          const patientId = item.patientId;
          const patient = resolvePatient({ patientId, patients });
          return {
            ...item,
            patient,
          };
        } else if (isRoute) {
          const visits = item.visits.map((visit) => {
            const patientId = visit.patientId;
            const patient = resolvePatient({ patientId, patients });
            return {
              ...visit,
              patient,
            };
          });
          return {
            ...item,
            visits,
          };
        }
        return item;
      }),
    }),
  );

  const parsedShiftsWithContents = z
    .array(shiftWithContents)
    .parse(enrichedShifts);

  return parsedShiftsWithContents;
}

export const shiftKeys = {
  all: ["shifts"] as const,
  lists: () => [...shiftKeys.all, "list"] as const,
  list: (filters: Record<string, unknown>) =>
    [...shiftKeys.lists(), filters] as const,
  listWithContents: (filters: Record<string, unknown>) =>
    [...shiftKeys.lists(), "contents", filters] as const,
  detail: (id: string) => [...shiftKeys.all, id, "details"] as const,
};

export const useScheduledShifts = (fromDate?: Date, toDate?: Date) => {
  const from = fromDate ? format(fromDate, "yyyy-MM-dd") : undefined;
  const to = toDate ? format(toDate, "yyyy-MM-dd") : undefined;
  return useQuery({
    queryKey: shiftKeys.list({ from, to }),
    queryFn: () => fetchScheduledShifts(from, to),
    staleTime: 30_000,
  });
};

export const useScheduledCenterShifts = (fromDate?: Date, toDate?: Date) => {
  const from = fromDate ? format(fromDate, "yyyy-MM-dd") : undefined;
  const to = toDate ? format(toDate, "yyyy-MM-dd") : undefined;
  return useQuery({
    queryKey: shiftKeys.list({
      from,
      to,
      center: true,
    }),
    queryFn: () => fetchScheduledShiftsInCenter(from, to),
  });
};

export const shiftsWithContentsQueryOptions = (date: string) =>
  queryOptions({
    queryKey: shiftKeys.listWithContents({ date }),
    queryFn: () => fetchShiftsWithContents(date),
  });

// visitSchema will be removed going forward
export const shiftWithContentsWithPatientIds = z.object({
  shift: scheduledShiftSchema,
  items: z.array(
    z.union([
      visitSchemaWithPatientId,
      optionallyNamedRouteWithPatientIdSchema,
      activityOccurrenceWithPatientIdSchema,
    ]),
  ),
});

export type IShiftWithContentsWithPatientIds = z.infer<
  typeof shiftWithContentsWithPatientIds
>;

// visitSchema will be removed going forward
export const shiftWithContents = z.object({
  shift: scheduledShiftSchema,
  items: z.array(
    z.union([
      visitSchema,
      optionallyNamedRouteSchema,
      activityOccurrenceSchema,
    ]),
  ),
});
export type IShiftWithContents = z.infer<typeof shiftWithContents>;

export async function assignGroupToShift(
  groupId: string,
  shiftId: number,
  routeId?: string,
) {
  await logisticsApi.post(`/shifts/${shiftId}/assign`, {
    occurrenceGroupId: groupId,
    routeId,
  });
}

async function fetchEnabledCompetences() {
  const enabledCompetencesResponse = await logisticsApi.get("/competences");

  const parsedEnabledCompetences = z
    .array(medicalCompetenceSchema)
    .parse(enabledCompetencesResponse.data);

  return parsedEnabledCompetences;
}

export const competenceKeys = {
  all: ["enabledCompetences"] as const,
};

export const useEnabledCompetences = () => {
  return useQuery({
    queryKey: competenceKeys.all,
    queryFn: fetchEnabledCompetences,
  });
};

export const assignEmployeeToShift = async ({
  employeeId,
  shiftId,
}: {
  employeeId: number;
  shiftId: number;
}) => {
  // (1) Assign to shift in underlying scheduling system
  await logisticsApi.post(`/shifts/${shiftId}/assign-employee/${employeeId}`);
  // (2) Assign to shift in scheduling DB to avoid waiting for sync
  // If 1 fails, return "fatal error"
  // If 2 fails, return "informative error, saying that within 10 minutes, we ok"
};

export const NO_EMPLOYEE = 0;
export const removeAssignedEmployeeFromShift = async ({
  shiftId,
}: {
  shiftId: number;
}) => {
  await assignEmployeeToShift({ employeeId: NO_EMPLOYEE, shiftId });
};

const fetchShift = async ({ shiftId }: { shiftId: number }) => {
  const shiftResponse = await logisticsApi.get(`/shifts/${shiftId}`);
  const parsedShift = scheduledShiftSchema.parse(shiftResponse.data);
  return parsedShift;
};

export const shiftQueryOptions = ({ shiftId }: { shiftId: number }) =>
  queryOptions({
    queryKey: ["shifts", shiftId],
    queryFn: () => fetchShift({ shiftId }),
  });
