import ClockIcon from "@components/icons/ClockIcon";
import styles from "./Time.module.scss";
import clsx from "clsx";

export const Time = ({
  children,
  showIcon = false,
  color = "faded",
}: {
  showIcon?: boolean;
  color?: "default" | "faded";
  children: string;
}) => (
  <div className={clsx([styles.time, styles[`color-${color}`]])}>
    {showIcon ? <ClockIcon /> : <></>}
    <time>{children}</time>
  </div>
);
