import { CompetenceChip } from "@/components/Chips/CompetenceChip";
import { PageToolbar } from "@/components/PageToolbar/PageToolbar";
import { SplitPane } from "@/components/SplitPane/SplitPane";
import styles from "./Shifts.module.scss";
import { useScheduledShifts } from "@/api/Shifts";
import { type IEmployee, type IMedicalCompetence } from "@models/shifts";
import { type ITimeOfDay } from "@models/activities";
import { Trans, useLingui } from "@lingui/react/macro";
import { useLingui as useLinguiNonMacro } from "@lingui/react";
import { useMemo } from "react";
import { useSelectedDate } from "@/Utils/useSelectedDate";
import { Loading } from "@components/Loading/Loading";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { msg } from "@lingui/core/macro";
import type { I18n } from "@lingui/core";
import { getDataFromShifts } from "./getDataFromShifts";
import * as Sentry from "@sentry/react";
import { deducedError } from "@/Utils/ErrorUtils";
import {
  Table,
  TableHeader,
  Column,
  TableBody,
  Row,
  Cell,
} from "react-aria-components";
import { Outlet, useNavigate, useParams } from "react-router";
import { Heading } from "@components/Heading/Heading";
import { ShiftDisplayName } from "./ShiftsDisplayName";
import { ShiftTime } from "@/components/Time/ShiftTime";
import clsx from "clsx";

type ShiftTableItem = {
  id: string;
  competence: IMedicalCompetence;
  timespan: [Date, Date];
  employee: IEmployee | null;
  shiftRowIsActive: boolean;
};

const COLUMN_DISPLAY_NAMES = {
  competence: msg`Kompetens`,
  timespan: msg`Tid`,
  employee: msg({ message: `Medarbetare`, context: "singular" }),
};

const columnHelper = createColumnHelper<ShiftTableItem>();

const columns = (translator: I18n["_"]) => [
  columnHelper.accessor("competence", {
    header: translator(COLUMN_DISPLAY_NAMES["competence"]),
    cell: ({ getValue, row }) => {
      return (
        <CompetenceChip
          competence={getValue()}
          state={row.original.employee ? "neutral" : "unassigned"}
          size="medium"
        />
      );
    },
  }),
  columnHelper.accessor("timespan", {
    header: translator(COLUMN_DISPLAY_NAMES["timespan"]),
    cell: ({ getValue }) => {
      const [start, end] = getValue();
      return <ShiftTime startDateTime={start} endDateTime={end} />;
    },
    sortingFn: (a, b) => {
      const aState = a.getValue<[Date, Date, ITimeOfDay]>("timespan");
      const bState = b.getValue<[Date, Date, ITimeOfDay]>("timespan");

      // If we don't have a timespan, we can't sort by it
      if (!aState || !bState) return 0;

      // Any time of day -> end of list
      if (aState[2] === "Any") {
        return 1;
      }
      // Any time of day -> end of list
      if (bState[2] === "Any") {
        return 1;
      }
      if (aState[0] < bState[0]) {
        return -1;
      } else if (aState[0] > bState[0]) {
        return 1;
      } else if (aState[1] < bState[1]) {
        return -1;
      } else if (aState[1] > bState[1]) {
        return 1;
      } else {
        return 0;
      }
    },
  }),
  columnHelper.accessor("employee", {
    header: translator(COLUMN_DISPLAY_NAMES["employee"]),
    cell: ({ getValue }) => {
      const employee = getValue();
      return employee ? (
        <ShiftDisplayName
          assigned={Boolean(employee)}
          employeeName={`${employee.firstName} ${employee.lastName}`}
        />
      ) : (
        <ShiftDisplayName assigned={false} />
      );
    },
  }),
];

const ShiftsTable = () => {
  const navigate = useNavigate();
  const { _ } = useLinguiNonMacro();
  const { t } = useLingui();
  const selectedDateString = useSelectedDate();
  const selectedDate = new Date(selectedDateString);
  const {
    data: shifts,
    isPending,
    isError,
    error,
  } = useScheduledShifts(selectedDate, selectedDate);

  const params = useParams();
  const shiftIdFromParams = params.shiftId;

  const data = useMemo(
    () => getDataFromShifts({ shifts: shifts || [], shiftIdFromParams }),
    [shifts, shiftIdFromParams],
  );

  const sorting = useMemo(() => [{ id: "timespan", desc: false }], []);
  const translatedColumns = columns(_);
  const table = useReactTable({
    data,
    columns: translatedColumns,
    getRowId: (row) => row.id,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
    },
  });

  if (isError) {
    Sentry.captureException(error);
    return (
      <ErrorMessage
        message={`${t`Gick inte att hämta in aktiviteterna.`} ${deducedError(error)}`}
        padding={24}
      />
    );
  }

  if (isPending) {
    return <Loading message={t`Hämtar arbetspass`} padding={24} />;
  }

  return (
    <Table
      aria-label={t({ message: `Medarbetare`, context: "plural" })}
      className={styles.table}
      key={selectedDate.toDateString()}
    >
      <TableHeader className={styles.tableHeader}>
        {table.getHeaderGroups().map((headerGroup) =>
          headerGroup.headers.map((header) => {
            return <Column key={header.id} isRowHeader />;
          }),
        )}
      </TableHeader>
      <TableBody items={table.getRowModel().rows}>
        {(row) => {
          return (
            <Row
              id={row.id}
              key={row.id}
              className={clsx([
                styles.tableRow,
                row.original.shiftRowIsActive && styles.activeRow,
              ])}
              onAction={() => {
                navigate(row.id);
              }}
            >
              {row.getVisibleCells().map((cell) => {
                return (
                  <Cell key={cell.id} className={styles.tableCell}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Cell>
                );
              })}
            </Row>
          );
        }}
      </TableBody>
    </Table>
  );
};

export const Shifts = () => {
  return (
    <>
      <PageToolbar showDaySwitcher />
      <div className={styles.shiftsContainer}>
        <SplitPane>
          {/* Elements spawn from ShiftsTable, messing with SplitPane child styles, requires div in between */}
          <div>
            <Heading level="h1" className={styles.pageHeader}>
              <Trans>Bemanning</Trans>
            </Heading>
            <ShiftsTable />
          </div>
          <div className={styles.detailsPanel}>
            <Outlet />
          </div>
        </SplitPane>
      </div>
    </>
  );
};
