import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import {
  activityOccurrenceAndGroupKeys,
  useHomeVisitGroup,
} from "@/api/Activities";
import { deducedError } from "@/Utils/ErrorUtils";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import { Loading } from "@components/Loading/Loading";
import { useNavigate, useParams } from "react-router";
import z from "zod";
import styles from "./GroupDetails.module.scss";
import { Heading } from "@components/Heading/Heading";
import { IconButton, PlainButton } from "@components/Button/Button";
import CrossIcon from "@components/icons/CrossIcon";
import { formattedTimeSpan } from "@/components/Time/timeUtils";
import { ActivityTitle } from "@/components/ActivityTitle/ActivityTitle";
import { Text } from "@components/Text/Text";
import { dateName } from "@/Utils/DateUtils";
import { ChangeGroup, CompetencesAndAssignees } from "@/forms/ChangeGroup";
import { useState } from "react";
import clsx from "clsx";
import type { IHomeVisitActivityOccurrence } from "@models/activities";
import {
  activityOccurrenceStatusSchema,
  categorySchema,
  timeOfDayDictionary,
  timeOfDaySchema,
  visitStatusSchema,
} from "@models/activities";
import { TimeSpan } from "@/components/Time/TimeSpan";
import { getUnfulfilledRequirements } from "@/pages/commandcenter/helpers";
import { useLingui } from "@lingui/react";
import { isUnfinishedOccurrence } from "@/forms/changeGroupUtils";
import ArrowLeftCurveIcon from "@components/icons/ArrowLeftCurveIcon";
import { StatusTag } from "@/components/StatusTag/StatusTag";
import { RemoveActivityOccurrence } from "../ActivityOccurrence/RemoveActivityOccurrence";
import { QuickActivityInDetails } from "@/pages/commandcenter/Planning/TimelineTile/QuickActivityInDetails";
import { endOfDay, isPast } from "date-fns";
import EditGroupTimeForm from "@/forms/EditGroupTimeForm";
import ClockIcon from "@components/icons/ClockIcon";
import { useQueryClient } from "@tanstack/react-query";
import { useFeatureFlag } from "@/api/FeatureFlags";
import { getPatientNameWithStatus } from "@/api/Patients";
import { ActivityGroupTag } from "@/pages/commandcenter/Planning/TimelineTile/ActivityGroupTag/ActivityGroupTag";
import { visitStatusDictionary } from "@models/visits";

const GroupDetailsContent = ({ groupId }: { groupId: string }) => {
  const queryClient = useQueryClient();
  const { _ } = useLingui();
  const { data: group, isPending, isError, error } = useHomeVisitGroup(groupId);
  const navigate = useNavigate();
  const [isEditingTime, setIsEditingTime] = useState(false);
  const { data: showRescheduleVisitInUI } = useFeatureFlag(
    "ShowRescheduleVisitInUI",
  );

  const [state, setState] = useState<
    | { mode: "view" }
    | { mode: "change-group"; occurrence: IHomeVisitActivityOccurrence }
  >({ mode: "view" });

  if (isPending) {
    return <Loading message={t`Hämtar besök`} padding={24} />;
  }

  if (isError) {
    return (
      <ErrorMessage
        message={`${t`Kunde inte hämta besöket.`} ${deducedError(error)}`}
        padding={24}
      />
    );
  }

  const { patient, occurrences, start, end } = group;

  const endDayOfGroupHasPassed = isPast(endOfDay(group.end));

  const canAddQuickActivityToVisit =
    group.visitStatus !== visitStatusSchema.Values.finished &&
    !endDayOfGroupHasPassed;

  const tryEditTime = () => {
    const { visitStatus } = group;

    if (
      visitStatus === visitStatusSchema.Values.finished ||
      visitStatus === visitStatusSchema.Values.ongoing ||
      visitStatus === visitStatusSchema.Values.travellingTo
    ) {
      const visitStatusText = _(
        visitStatusDictionary[visitStatus],
      ).toLocaleLowerCase();

      return window.alert(
        `${t`Kan inte ändra tid för ett besök som har status`} "${visitStatusText}".`,
      );
    }

    const hasOngoingOrFinishedOccurrences = occurrences.some(
      (occurrence) =>
        occurrence.status === activityOccurrenceStatusSchema.Values.finished ||
        occurrence.status === activityOccurrenceStatusSchema.Values.ongoing,
    );

    if (hasOngoingOrFinishedOccurrences) {
      return window.alert(
        t`Kan inte ändra tid för ett besök som innehåller pågående eller avslutade aktiviteter.`,
      );
    }

    setIsEditingTime((prev) => !prev);
  };

  return (
    <article className={styles.container}>
      <div className={styles.topRow}>
        <div className={styles.highlightedInfo}>
          <ActivityGroupTag
            category={categorySchema.Values.HomeVisit}
            title={getPatientNameWithStatus(patient)}
          />
          <Heading level="h2">
            <Trans>Hembesök</Trans>
          </Heading>
          <div className={styles.dateAndTime}>
            <Text element="div">
              {dateName(start)},{" "}
              {group.timeOfDay === timeOfDaySchema.Values.Any
                ? _(timeOfDayDictionary.Any.long)
                : formattedTimeSpan(start, end)}
            </Text>
            {showRescheduleVisitInUI ? (
              <PlainButton size="small" weight="light" onClick={tryEditTime}>
                {isEditingTime ? <CrossIcon /> : <ClockIcon />}
                {isEditingTime ? (
                  <Trans>Avbryt</Trans>
                ) : (
                  <Trans>Ändra tid</Trans>
                )}
              </PlainButton>
            ) : null}
          </div>
        </div>
        <IconButton
          aria-label={t`Stäng detaljvyn`}
          onClick={() => navigate("..")}
        >
          <CrossIcon />
        </IconButton>
      </div>

      {isEditingTime ? (
        <div className={styles.editContainer}>
          <Heading level="h3" margin="bottom">
            <Trans>Ändra tid</Trans>
          </Heading>
          <EditGroupTimeForm
            occurrences={occurrences}
            currentTimeCategory={group.timeOfDay}
          />
        </div>
      ) : null}

      <ul className={styles.activities}>
        {occurrences.map((occurrence) => {
          const { start, end, timeOfDay, id, activityId } = occurrence;

          const occurrenceUnderChange =
            state.mode === "change-group" &&
            state.occurrence.id === id &&
            state.occurrence.activityId === activityId;

          return (
            <li
              key={`${activityId}${id}`}
              className={clsx(
                styles.activityOccurrence,
                occurrenceUnderChange && styles.occurrenceUnderChange,
              )}
            >
              <StatusTag status={occurrence.status} />
              <TimeSpan
                timespan={
                  timeOfDay === timeOfDaySchema.Values.Any
                    ? timeOfDay
                    : { start, end }
                }
              />
              <div>
                <ActivityTitle
                  activityOccurrence={occurrence}
                  linkTo={`../${occurrence.activityId}/occurrences/${occurrence.id}`}
                  weight="regular"
                  size="small"
                  alwaysShowDescription
                />
              </div>
              <CompetencesAndAssignees
                assignees={occurrence.assignees}
                unfulfilledRequirements={getUnfulfilledRequirements({
                  assignees: occurrence.assignees,
                  occurrences: [occurrence],
                })}
              />
              {isUnfinishedOccurrence(occurrence) ? (
                <>
                  <div className={styles.buttonWrapper}>
                    <RemoveActivityOccurrence
                      text={t`Ta bort tillfälle`}
                      occurrence={occurrence}
                      size={"medium"}
                      onSuccess={async () => {
                        if (!group) return;
                        // If the group still has occurrences, it stays in view, so we invalidate it to get the latest data.
                        if (group.occurrences.length >= 2) {
                          await queryClient.invalidateQueries({
                            queryKey:
                              activityOccurrenceAndGroupKeys.detail(groupId),
                          });
                          queryClient.invalidateQueries({
                            queryKey: activityOccurrenceAndGroupKeys.lists(),
                          });
                        } else {
                          // The group is now empty
                          // This component is always rendered in the context of a list.
                          // Once removal is done, we want to refresh the list, and then navigate to it.
                          // Notably, we don't invalidate the group detail, since that causes NotFound errors.
                          await queryClient.invalidateQueries({
                            queryKey: activityOccurrenceAndGroupKeys.lists(),
                          });
                          // Important to navigate back to the list URL since we can't display the detail view any more.
                          // See `index.tsx` for route structure.
                          await navigate("..");
                        }
                      }}
                    />
                  </div>
                  {occurrenceUnderChange ? (
                    <>
                      <div className={styles.buttonWrapper}>
                        <PlainButton
                          size="medium"
                          weight="light"
                          onClick={() => setState({ mode: "view" })}
                        >
                          <Trans>Avbryt</Trans>
                        </PlainButton>
                      </div>

                      <ChangeGroup
                        activityOccurrence={occurrence}
                        onSubmitSuccess={() => {
                          return occurrences.length === 1
                            ? navigate("..")
                            : setState({ mode: "view" });
                        }}
                      />
                    </>
                  ) : (
                    <div className={styles.buttonWrapper}>
                      <PlainButton
                        size="medium"
                        weight="light"
                        onClick={() =>
                          setState({ mode: "change-group", occurrence })
                        }
                      >
                        <ArrowLeftCurveIcon />
                        <Trans>Flytta</Trans>
                      </PlainButton>
                    </div>
                  )}
                </>
              ) : null}
            </li>
          );
        })}
      </ul>

      {canAddQuickActivityToVisit ? (
        <QuickActivityInDetails
          groupId={groupId}
          visitStatus={visitStatusSchema.Values.planned}
        />
      ) : null}
    </article>
  );
};

// This wrapper is used to ensure that the component is re-mounted when the groupId changes.
// One use case is that we want the `isEditingTime` state to reset when navigating to another group.
export const GroupDetails = () => {
  const { groupId } = z.object({ groupId: z.string() }).parse(useParams());

  return <GroupDetailsContent groupId={groupId} key={groupId} />;
};
