import styles from "./ActivitiesCalendar.module.scss";
import { endOfDay, isPast, isToday } from "date-fns";
import {
  isGroup,
  type IActivityOccurrenceOrGroupWithPatient,
} from "@models/activities";
import { format } from "@models/date-and-time";
import clsx from "clsx";
import {
  ActivityCalendarItem,
  ActivityCalendarItemPlaceholder,
} from "./ActivityCalendarItem";
import { useLingui } from "@lingui/react";
import { t } from "@lingui/core/macro";
import NoResults from "@/components/NoResults/NoResults";
import { dateAndMonth } from "@/Utils/DateUtils";
import { weekdayDictionary } from "@/forms/AddActivityForm/Scheduling/schedulingModels";

type IWeek = [Date, Date, Date, Date, Date, Date, Date];
type IWeeks = IWeek[];

const TableHeader = () => {
  const { _ } = useLingui();

  return (
    <thead>
      <tr>
        {Object.values(weekdayDictionary).map(({ regular: weekdayName }) => (
          <th key={_(weekdayName)}>{_(weekdayName)}</th>
        ))}
      </tr>
    </thead>
  );
};

export type IActivitiesCalendar = {
  activityOccurrencesAndGroups: IActivityOccurrenceOrGroupWithPatient[];
  displayedDays: Date[];
  isPlaceholderData: boolean;
};

const ActivitiesCalendar = ({
  activityOccurrencesAndGroups,
  displayedDays,
  isPlaceholderData,
}: IActivitiesCalendar) => {
  // Split the days into weeks
  const weeks: IWeeks = [];
  for (let i = 0; i < displayedDays.length; i += 7) {
    weeks.push(displayedDays.slice(i, i + 7) as IWeek);
  }

  return (
    <article className={styles.calendar}>
      <table aria-label={t`Kalender med aktiviteter`}>
        <TableHeader />
        <tbody>
          {weeks.map((week: IWeek, weekIndex) => {
            return (
              <tr key={`first-day-of-week-${format(week[0], "yyyy-MM-dd")}`}>
                {week.map((day) => {
                  const dateMonth = dateAndMonth(day);
                  const activityOccurrencesOrGroupsOnDay =
                    activityOccurrencesAndGroups.filter(
                      (activityOccurrencesAndGroups) =>
                        format(
                          activityOccurrencesAndGroups.start,
                          "yyyy-MM-dd",
                        ) === format(day, "yyyy-MM-dd"),
                    );
                  const pastDayHasNoActivities =
                    activityOccurrencesOrGroupsOnDay.length === 0 &&
                    isPast(endOfDay(day));

                  return (
                    <td key={format(day, "yyyy-MM-dd")} aria-label={dateMonth}>
                      <div className={styles.dayCell}>
                        <div
                          className={clsx([
                            styles.dayAndMonth,
                            isToday(day) && styles.today,
                            isPast(endOfDay(day)) && styles.past,
                          ])}
                        >
                          {dateMonth}
                        </div>
                        <div className={styles.activities}>
                          {/* While fetching the previous week, we don't want to show the "no activities" message until we know. */}
                          {/* Instead show some placeholder components */}
                          {(isPlaceholderData && weekIndex === 0) ||
                          (isPlaceholderData &&
                            weekIndex === weeks.length - 1) ? (
                            <>
                              <ActivityCalendarItemPlaceholder />
                              <ActivityCalendarItemPlaceholder />
                              <ActivityCalendarItemPlaceholder />
                            </>
                          ) : pastDayHasNoActivities ? (
                            <NoResults
                              message={t`Inga aktiviteter`}
                              size="smallest"
                            />
                          ) : (
                            activityOccurrencesOrGroupsOnDay.map(
                              (activityOccurrenceOrGroup) => {
                                const key = isGroup(activityOccurrenceOrGroup)
                                  ? activityOccurrenceOrGroup.id
                                  : activityOccurrenceOrGroup.id +
                                    activityOccurrenceOrGroup.activityId;

                                return (
                                  <ActivityCalendarItem
                                    key={key}
                                    activityOccurrenceOrGroup={
                                      activityOccurrenceOrGroup
                                    }
                                  />
                                );
                              },
                            )
                          )}
                        </div>
                      </div>
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </article>
  );
};

export { ActivitiesCalendar };
