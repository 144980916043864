import { usePatient } from "@/api/Patients";
import { type IPatientType } from "@models/patients";
import { v5 as uuidv5, type UUIDTypes } from "uuid";
import type { ITemplate, ITemplateKey, ITemplateUnit } from "./templateModels";
import {
  recurringDictionary,
  recurringOptionSchema,
  weekdayDictionary,
} from "@/forms/AddActivityForm/Scheduling/schedulingModels";
import { t, selectOrdinal } from "@lingui/core/macro";

export const useMatchPatientTypes = (patientId: string) => {
  const { data: patient } = usePatient(patientId);

  const isPatientTypesMatching = (patientTypes: IPatientType[]) => {
    if (!patient) return false;

    return patientTypes.includes(patient.patientType);
  };

  return { isPatientTypesMatching, patient };
};

/**
 * Generates a deterministic GUID from two input strings using SubtleCrypto and UUID v5.
 *
 * @param unit The unit of the template.
 * @param templateTitle The title of the template.
 * @returns A Promise resolving into a deterministic GUID (UUID version 5) as a string.
 */
export const generateTemplateId = async ({
  unit,
  templateTitle,
}: {
  unit: ITemplateUnit;
  templateTitle: ITemplateKey;
}) => {
  const combinedString = `${unit}|${templateTitle}`;
  const encoder = new TextEncoder();
  const data = encoder.encode(combinedString);
  const hashBuffer = await crypto.subtle.digest("SHA-256", data);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");

  // Use "templates" as namespace
  const namespace: UUIDTypes = uuidv5(
    "templates",
    "6ba7b810-9dad-11d1-80b4-00c04fd430c8",
  );

  return uuidv5(hashHex.substring(0, 32), namespace);
};

export const getRecurrenceLabel = ({
  recurrence,
  customRecurrenceInterval,
  customRecurrenceIntervalUnit,
  weekdays,
}: Pick<
  ITemplate,
  | "recurrence"
  | "customRecurrenceInterval"
  | "customRecurrenceIntervalUnit"
  | "weekdays"
>) => {
  if (!recurrence) return undefined;

  if (recurrence === recurringOptionSchema.Values.custom) {
    if (!customRecurrenceInterval || !customRecurrenceIntervalUnit) {
      return undefined;
    }

    const intervalAsNumber = Number(customRecurrenceInterval);
    if (isNaN(intervalAsNumber) || intervalAsNumber < 1) {
      return undefined;
    }

    if (customRecurrenceIntervalUnit === "weeks") {
      return selectOrdinal(customRecurrenceInterval, {
        1: "Varje vecka",
        2: "Varannan vecka",
        one: "Var #:a vecka",
        two: "Var #:a vecka",
        few: "Var #:e vecka",
        other: "Var #:e vecka",
      });
    }
    if (customRecurrenceIntervalUnit === "days") {
      return selectOrdinal(customRecurrenceInterval, {
        1: "Varje dag",
        2: "Varannan dag",
        one: "Var #:a dag",
        two: "Var #:a dag",
        few: "Var #:e dag",
        other: "Var #:e dag",
      });
    }
  }

  if (recurrence === recurringOptionSchema.Values.onSelectedWeekdays) {
    if (weekdays.length === 0) {
      return t`Välj dagar`;
    }
    return weekdays
      .map((day) => t(weekdayDictionary[day].regular).toLocaleLowerCase())
      .join(", ");
  }

  return t(recurringDictionary[recurrence]);
};
