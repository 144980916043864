import { useFormContext } from "react-hook-form";
import type { IWeekdaysPickerFields } from "./WeekdaysPicker";
import { WeekdaysPicker } from "./WeekdaysPicker";
import type { IRecurrencePickerFields } from "./RecurrencePicker";
import { RecurrencePicker } from "./RecurrencePicker";
import {
  activityFrequencySchema,
  recurringOptionSchema,
} from "./schedulingModels";
import type { ITimeSlotsPickerFields } from "./TimeSlotsPicker";
import { TimeSlotsPicker } from "./TimeSlotsPicker";
import type { IDateInputFields } from "./DateInput";
import { DateInput } from "./DateInput";
import { useEffect } from "react";
import {
  FrequencyPicker,
  type IFrequencyPickerFields,
} from "./FrequencyPicker";
import {
  CustomRecurringInterval,
  type ICustomRecurringIntervalFields,
} from "./CustomRecurringInput";

export type ISchedulingFields = IFrequencyPickerFields &
  IRecurrencePickerFields &
  IWeekdaysPickerFields &
  ICustomRecurringIntervalFields &
  IDateInputFields &
  ITimeSlotsPickerFields;

export const Scheduling = () => {
  const { unregister, watch } = useFormContext<ISchedulingFields>();

  const isRecurringOnWeekdays =
    watch("recurrence") === recurringOptionSchema.Values.onSelectedWeekdays;

  const isCustomRecurring =
    watch("recurrence") === recurringOptionSchema.Values.custom;

  const selectedRecurring = watch("frequency");
  const isRecurring =
    selectedRecurring === activityFrequencySchema.Values.recurring;

  // Unregister the weekdays field if the activity is not recurring on weekdays
  useEffect(() => {
    if (!isRecurringOnWeekdays) {
      unregister("weekdays");
    }
  }, [isRecurringOnWeekdays, unregister]);

  // Unregister the recurrence field if the activity is one-time
  useEffect(() => {
    if (selectedRecurring === activityFrequencySchema.Values.oneTime) {
      unregister("recurrence");
    }
  }, [selectedRecurring, unregister]);

  // Unregister the custom recurring interval field if the activity is not custom recurring
  useEffect(() => {
    if (!isCustomRecurring) {
      unregister("customRecurrenceInterval");
      unregister("customRecurrenceIntervalUnit");
    }
  }, [isCustomRecurring, unregister]);

  return (
    <>
      <FrequencyPicker />
      {isRecurring ? (
        <>
          <RecurrencePicker />
          {isRecurringOnWeekdays ? <WeekdaysPicker /> : <></>}
          {isCustomRecurring ? <CustomRecurringInterval /> : <></>}
        </>
      ) : (
        <></>
      )}
      <DateInput hasEndDate={isRecurring} />
      <TimeSlotsPicker isRecurring={isRecurring} />
    </>
  );
};
