import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import { Heading } from "@components/Heading/Heading";
import { EditDoubleStaffing } from "./EditDoubleStaffing";
import { EditButton } from "./EditButton";
import { CompetenceChip } from "@/components/Chips/CompetenceChip";
import { useCurrentlyEditing } from "./useCurrentlyEditing";
import { EditTitle } from "./EditTitle";
import { Text, TextWithLineBreaks } from "@components/Text/Text";
import { EditDescription } from "./EditDescription";
import { EditMeasurements } from "./EditMeasurements";
import MeasurementChip from "@/components/Chips/MeasurementChip";
import { EditTime } from "./EditTime";
import { EditHidden } from "./EditHidden";
import { EditRequiredCompetences } from "./EditRequiredCompetences";
import {
  type IActivity,
  useActivity,
  useActivityOccurrence,
} from "@/api/Activities";
import { ActivitySchedule } from "./ActivitySchedule";
import {
  activityOccurrenceStatusSchema,
  categorySchema,
  timeOfDayDictionary,
  timeOfDaySchema,
} from "@models/activities";
import { dateName } from "@/Utils/DateUtils";
import { formattedTimeSpan } from "@/components/Time/timeUtils";
import styles from "./ActivityInformation.module.scss";
import { EditContainer } from "./EditContainer";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import { Loading } from "@components/Loading/Loading";
import { deducedError } from "@/Utils/ErrorUtils";
import * as Sentry from "@sentry/react";
import { useLingui } from "@lingui/react";
import { EditInvoicingCodes } from "@/pages/commandcenter/Patients/Patient/Activities/ActivityOccurrence/EditInvoicingCodes";
import { useActivityInvoicingCodes } from "@/api/Invoicing";
import { knownFeatureFlagsSchema, useFeatureFlag } from "@/api/FeatureFlags";

const Invoicing = ({
  currentlyEditing,
  setCurrentlyEditing,
  activity,
}: {
  currentlyEditing:
    | "time"
    | "title"
    | "description"
    | "schedule"
    | "requiredCompetences"
    | "doubleStaffing"
    | "hidden"
    | "measurements"
    | "move"
    | "invoicingCodes"
    | null;
  setCurrentlyEditing: (
    value:
      | "time"
      | "title"
      | "description"
      | "schedule"
      | "requiredCompetences"
      | "doubleStaffing"
      | "hidden"
      | "measurements"
      | "move"
      | "invoicingCodes"
      | null,
  ) => void;
  activity: IActivity;
}) => {
  const { _ } = useLingui();

  const { data: activityInvoicingCodes } = useActivityInvoicingCodes(
    activity.id,
  );

  return (
    <section className={styles.informationSection}>
      <div className={styles.editableSection}>
        <Heading level="h3">
          <Trans>Faktureringskoder</Trans>
        </Heading>
        <EditButton
          attribute="edit-invoicingCodes"
          isEditing={currentlyEditing === "invoicingCodes"}
          toggleIsEditing={() =>
            setCurrentlyEditing(
              currentlyEditing === "invoicingCodes" ? null : "invoicingCodes",
            )
          }
        />
      </div>
      {currentlyEditing === "invoicingCodes" && activity.patientId ? (
        <EditContainer>
          <EditInvoicingCodes
            currentProcedureCode={
              activityInvoicingCodes && activityInvoicingCodes.procedureCodes[0]
                ? activityInvoicingCodes.procedureCodes[0].id
                : ""
            }
            currentProductCode={
              activityInvoicingCodes && activityInvoicingCodes.productCodes[0]
                ? activityInvoicingCodes.productCodes[0].id
                : ""
            }
            onSuccess={() => setCurrentlyEditing(null)}
            activityId={activity.id}
            patientId={activity.patientId}
          />
        </EditContainer>
      ) : activityInvoicingCodes &&
        (activityInvoicingCodes.procedureCodes.length > 0 ||
          activityInvoicingCodes.productCodes.length > 0) ? (
        <>
          <Text element="div">
            {activityInvoicingCodes.procedureCodes.length > 0 && (
              <>
                <Trans>Åtgärd (KVÅ)</Trans>:
                {activityInvoicingCodes.procedureCodes
                  .map(
                    (procedureCode) =>
                      ` ${procedureCode.code} - ${_(procedureCode.name)}`,
                  )
                  .join(", ")}
              </>
            )}
          </Text>
          <Text element="div">
            {activityInvoicingCodes.productCodes.length > 0 && (
              <>
                <Trans>Produkt:</Trans>
                {activityInvoicingCodes.productCodes
                  .map(
                    (productCode) =>
                      ` ${productCode.code} - ${_(productCode.name)}`,
                  )
                  .join(", ")}
              </>
            )}
          </Text>
        </>
      ) : (
        <Trans>Ingen</Trans>
      )}
    </section>
  );
};

export const ActivityInformation = ({
  isRecurringTab,
  activityId,
  occurrenceId,
}: {
  isRecurringTab: boolean;
  activityId: string;
  occurrenceId: string;
}) => {
  const { _ } = useLingui();
  const { currentlyEditing, setCurrentlyEditing } = useCurrentlyEditing();

  const { data: activity, isPending, isError, error } = useActivity(activityId);

  const {
    data: occurrence,
    isPending: occurrenceIsPending,
    isError: occurrenceIsError,
    error: occurrenceError,
  } = useActivityOccurrence(activityId, occurrenceId);

  const {
    data: invoicingActivated,
    isPending: invoicingActivatedIsPending,
    isError: invoicingActivatedIsError,
    error: invoicingActivatedError,
  } = useFeatureFlag(
    knownFeatureFlagsSchema.Values.PERMANENT_ShowInvoicingInUI,
  );

  if (isPending || occurrenceIsPending || invoicingActivatedIsPending) {
    return <Loading message={t`Hämtar aktivitet`} padding={24} />;
  }

  if (isError) {
    Sentry.captureException(error);
    return <ErrorMessage message={deducedError(error)} padding={24} />;
  }

  if (occurrenceIsError) {
    Sentry.captureException(occurrenceError);
    return (
      <ErrorMessage message={deducedError(occurrenceError)} padding={24} />
    );
  }

  if (invoicingActivatedIsError) {
    Sentry.captureException(invoicingActivatedError);
    return (
      <ErrorMessage
        message={deducedError(invoicingActivatedError)}
        padding={24}
      />
    );
  }

  const isRecurringActivity = activity.recurring;
  const isSingleActivityNoRecurrency = !isRecurringActivity && !isRecurringTab;
  const isSingleActivityWithRecurrency = isRecurringActivity && !isRecurringTab;

  const canBeInvoiced =
    (activity.category === categorySchema.Values.AdminTask ||
      activity.category === categorySchema.Values.HomeVisit) &&
    invoicingActivated;

  return (
    <div className={styles.activityInfo}>
      {isRecurringTab || isSingleActivityNoRecurrency ? (
        <>
          <section className={styles.informationSection}>
            <div className={styles.editableSection}>
              <Heading level="h3">
                <Trans>Namn</Trans>
              </Heading>
              <EditButton
                attribute="edit-title"
                isEditing={currentlyEditing === "title"}
                toggleIsEditing={() =>
                  setCurrentlyEditing(
                    currentlyEditing === "title" ? null : "title",
                  )
                }
              />
            </div>
            {currentlyEditing === "title" ? (
              <EditContainer>
                <EditTitle
                  currentTitle={activity.title}
                  onSuccess={() => setCurrentlyEditing(null)}
                  activityId={activity.id}
                />
              </EditContainer>
            ) : (
              <Text element="p">{activity.title}</Text>
            )}
          </section>
          <section className={styles.informationSection}>
            <div className={styles.editableSection}>
              <Heading level="h3">
                <Trans>Beskrivning</Trans>
              </Heading>
              <EditButton
                attribute="edit-description"
                isEditing={currentlyEditing === "description"}
                toggleIsEditing={() =>
                  setCurrentlyEditing(
                    currentlyEditing === "description" ? null : "description",
                  )
                }
              />
            </div>
            {currentlyEditing === "description" ? (
              <EditContainer>
                <EditDescription
                  currentDescription={activity.description}
                  onSuccess={() => setCurrentlyEditing(null)}
                  activityId={activity.id}
                />
              </EditContainer>
            ) : (
              <TextWithLineBreaks>{activity.description}</TextWithLineBreaks>
            )}
          </section>
          {activity.category === "PatientMeasurementTask" ? (
            <section className={styles.informationSection}>
              <div className={styles.editableSection}>
                <Heading level="h3">
                  <Trans>Mätvärden</Trans>
                </Heading>
                <EditButton
                  attribute="edit-measurements"
                  isEditing={currentlyEditing === "measurements"}
                  toggleIsEditing={() =>
                    setCurrentlyEditing(
                      currentlyEditing === "measurements"
                        ? null
                        : "measurements",
                    )
                  }
                />
              </div>
              {currentlyEditing === "measurements" ? (
                <EditContainer>
                  <EditMeasurements
                    currentMeasurements={activity.measurements}
                    onSuccess={() => setCurrentlyEditing(null)}
                    activityId={activity.id}
                  />
                </EditContainer>
              ) : (
                <ul className={styles.chips}>
                  {activity.measurements.map((measurement) => (
                    <li key={measurement}>
                      <MeasurementChip measurement={measurement} />
                    </li>
                  ))}
                </ul>
              )}
            </section>
          ) : null}
        </>
      ) : null}

      {isRecurringTab ? (
        <ActivitySchedule activityId={activityId} />
      ) : occurrence.status !==
          activityOccurrenceStatusSchema.Values.finished &&
        occurrence.status !== activityOccurrenceStatusSchema.Values.ongoing ? (
        <section className={styles.informationSection}>
          <div className={styles.editableSection}>
            <Heading level="h3">
              <Trans>Tid</Trans>
            </Heading>
            <EditButton
              attribute="edit-time"
              label={t`Ändra`}
              isEditing={currentlyEditing === "time"}
              toggleIsEditing={() =>
                setCurrentlyEditing(currentlyEditing === "time" ? null : "time")
              }
            />
          </div>
          {currentlyEditing === "time" ? (
            <EditContainer>
              <EditTime
                currentDateTime={occurrence.start}
                currentTimeOfDay={occurrence.timeOfDay}
                onSuccess={() => {
                  setCurrentlyEditing(null);
                }}
                activityId={activityId}
                occurrenceId={occurrenceId}
              />
            </EditContainer>
          ) : (
            <Text element="p">
              {dateName(occurrence.start)}
              <br />
              {occurrence.timeOfDay === timeOfDaySchema.Values.Any
                ? _(timeOfDayDictionary.Any.long)
                : formattedTimeSpan(occurrence.start, occurrence.end)}
            </Text>
          )}
        </section>
      ) : null}

      {!isSingleActivityWithRecurrency ? (
        <>
          {activity.category !== "AdminTask" ? (
            <section className={styles.informationSection}>
              <div className={styles.editableSection}>
                <Heading level="h3">
                  <Trans>Dold för patienten</Trans>
                </Heading>
                <EditButton
                  attribute="edit-hidden"
                  isEditing={currentlyEditing === "hidden"}
                  toggleIsEditing={() =>
                    setCurrentlyEditing(
                      currentlyEditing === "hidden" ? null : "hidden",
                    )
                  }
                />
              </div>
              {currentlyEditing === "hidden" ? (
                <EditContainer>
                  <EditHidden
                    currentHidden={activity.hidden}
                    onSuccess={() => setCurrentlyEditing(null)}
                    activityId={activity.id}
                  />
                </EditContainer>
              ) : (
                <Text element="p">{activity.hidden ? t`Ja` : t`Nej`}</Text>
              )}
            </section>
          ) : null}
          {activity.category === "VideoCall" ||
          activity.category === "HomeVisit" ||
          activity.category === "AdminTask" ? (
            <section className={styles.informationSection}>
              <div className={styles.editableSection}>
                <Heading level="h3">
                  <Trans>Kompetensbehov</Trans>
                </Heading>
                <EditButton
                  attribute="edit-requiredCompetences"
                  isEditing={currentlyEditing === "requiredCompetences"}
                  toggleIsEditing={() =>
                    setCurrentlyEditing(
                      currentlyEditing === "requiredCompetences"
                        ? null
                        : "requiredCompetences",
                    )
                  }
                />
              </div>
              {currentlyEditing === "requiredCompetences" ? (
                <EditContainer>
                  <EditRequiredCompetences
                    currentRequiredCompetences={activity.requiredCompetences}
                    onSuccess={() => setCurrentlyEditing(null)}
                    activityId={activity.id}
                  />
                </EditContainer>
              ) : (
                <ul className={styles.chips}>
                  {activity.requiredCompetences.map((competence) => (
                    <li key={competence}>
                      <CompetenceChip competence={competence} state="neutral" />
                    </li>
                  ))}
                </ul>
              )}
            </section>
          ) : null}
          {activity.category === "HomeVisit" ? (
            <section className={styles.informationSection}>
              <div className={styles.editableSection}>
                <Heading level="h3">
                  <Trans>Dubbelbemanning</Trans>
                </Heading>
                <EditButton
                  attribute="edit-doubleStaffing"
                  isEditing={currentlyEditing === "doubleStaffing"}
                  toggleIsEditing={() =>
                    setCurrentlyEditing(
                      currentlyEditing === "doubleStaffing"
                        ? null
                        : "doubleStaffing",
                    )
                  }
                />
              </div>
              {currentlyEditing === "doubleStaffing" ? (
                <EditContainer>
                  <EditDoubleStaffing
                    currentDoubleStaffing={activity.doubleStaffing}
                    onSuccess={() => setCurrentlyEditing(null)}
                    activityId={activity.id}
                  />
                </EditContainer>
              ) : (
                <Text element="p">
                  {activity.doubleStaffing ? t`Ja` : t`Nej`}
                </Text>
              )}
            </section>
          ) : null}

          {canBeInvoiced ? (
            <Invoicing
              currentlyEditing={currentlyEditing}
              setCurrentlyEditing={setCurrentlyEditing}
              activity={activity}
            />
          ) : null}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};
