import { shiftTimeDisplayValues, type IScheduledShift } from "@models/shifts";
import { Text, type IText } from "@components/Text/Text";
import { useLingui } from "@lingui/react/macro";
import styles from "./ShiftTime.module.scss";
import { useSelectedDate } from "@/Utils/useSelectedDate";
import clsx from "clsx";
import { TimeSpan } from "./TimeSpan";

type IShiftTime = Pick<IScheduledShift, "startDateTime" | "endDateTime"> &
  Pick<IText, "size" | "color"> & {
    layout?: "horizontal" | "vertical";
  };

const ShiftTime = ({
  startDateTime,
  endDateTime,
  size = "base",
  color,
  layout = "vertical",
}: IShiftTime) => {
  const { t } = useLingui();
  const selectedDate = useSelectedDate();
  const selectedDateAsDate = new Date(selectedDate);

  const {
    startedDayBefore,
    hasEndTimeAfterToday,
    formattedDayBefore,
    formattedDayToday,
  } = shiftTimeDisplayValues({
    selectedDate: selectedDateAsDate,
    startDateTime,
    endDateTime,
  });

  const additionalContext = `${startedDayBefore ? t`Start ${formattedDayBefore}` : hasEndTimeAfterToday ? t`Start ${formattedDayToday}` : ""}`;
  const hasAdditionalContext = additionalContext !== "";

  return (
    <>
      <div className={clsx([styles.shiftTime, styles[`layout-${layout}`]])}>
        <TimeSpan
          timespan={{ start: startDateTime, end: endDateTime }}
          size={size}
          color={color}
        />
        {hasAdditionalContext ? (
          <Text
            element="div"
            color="faded"
            size={layout === "vertical" ? "small" : size}
          >
            {additionalContext}
          </Text>
        ) : null}
      </div>
    </>
  );
};

export { ShiftTime };
