import type { IScheduledShift } from "@models/shifts";

export const getDataFromShifts = ({
  shifts,
  shiftIdFromParams,
}: {
  shifts: IScheduledShift[];
  shiftIdFromParams: string | undefined;
}) => {
  return shifts.map((shift) => {
    return {
      id: shift.id.toString(),
      competence: shift.competence,
      timespan: [shift.startDateTime, shift.endDateTime] as [Date, Date],
      employee: shift.employee ?? null,
      shiftRowIsActive: shift.id.toString() === shiftIdFromParams,
    };
  });
};
