import Chip from "./Chip";
import { Trans } from "@lingui/react/macro";

const NotUsingAppChip = () => {
  return (
    <Chip
      border="solid"
      background="solid"
      color="blue"
      shape="sharp"
      size="small"
    >
      <Trans>Använder inte Medomas patientapp</Trans>
    </Chip>
  );
};

export default NotUsingAppChip;
