import { forwardRef, useId } from "react";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import styles from "./Select.module.scss";
import CaretDownIcon from "@components/icons/CaretDownIcon";
import clsx from "clsx";

interface ISelect {
  children: React.ReactNode;
  errorMessage?: string;
  icon?: React.ReactNode;
  name: string;
  label: string;
  hideLabel?: boolean;
  description?: string;
}
const Select = forwardRef<HTMLSelectElement | null, ISelect>(
  (
    {
      children,
      errorMessage,
      icon,
      name,
      label,
      hideLabel,
      description,
      ...rest
    },
    ref,
  ) => {
    const id = useId();
    const descriptionId = useId();
    return (
      <div className={styles.wrapper}>
        {hideLabel ? null : icon ? (
          <div className={styles.labelWithIcon}>
            {icon}
            <label className={styles.label} htmlFor={id}>
              {label}
            </label>
          </div>
        ) : (
          <label className={styles.label} htmlFor={id}>
            {label}
          </label>
        )}
        {description ? (
          <p id={descriptionId} className={styles.description}>
            {description}
          </p>
        ) : undefined}
        {errorMessage ? (
          <div className={styles.errorMessage}>
            <ErrorMessage message={errorMessage} weight="bold" />
          </div>
        ) : undefined}
        <div
          className={clsx([
            styles.selectWrapper,
            hideLabel && styles.hiddenLabel,
          ])}
        >
          <select
            aria-invalid={Boolean(errorMessage) || undefined}
            aria-describedby={
              description !== undefined ? descriptionId : undefined
            }
            aria-label={hideLabel ? label : undefined}
            id={id}
            name={name}
            {...rest}
            ref={ref}
            className={styles[`select-bordered`]}
          >
            {children}
          </select>
          <div className={styles.arrow}>
            <CaretDownIcon />
          </div>
        </div>
      </div>
    );
  },
);
Select.displayName = "Select";

const ControlledSelect = ({
  value,
  children,
  onChange,
  label,
  size,
  frame = "bordered",
}: {
  value: string;
  children: React.ReactNode;
  onChange: (value: string) => void;
  label: string;
  size?: "compact";
  frame?: "bordered" | "borderless";
}) => {
  const id = useId();
  return (
    <div
      className={clsx(
        styles.controlledSelectWrapper,
        size !== undefined && styles[`size-${size}`],
      )}
    >
      <label className={styles.label} htmlFor={id}>
        {label}
      </label>
      <select
        aria-label={label}
        id={id}
        className={styles[`select-${frame}`]}
        value={value}
        onChange={(e) => onChange(e.currentTarget.value)}
      >
        {children}
      </select>
      <div className={styles[`arrow-${frame}`]}>
        <CaretDownIcon />
      </div>
    </div>
  );
};

export { ControlledSelect, Select as default };
