import { unitAlerisAhcGroupTemplateKeysSchema } from "../templatesAlerisAhc";
import { newActivityFromTemplateAlerisAhc } from "../newActivityFromTemplateAlerisAhc";
import { templates } from "../templatesAlerisAhc";
import type { ITimeSlotsPickerFields } from "@/forms/AddActivityForm/Scheduling/TimeSlotsPicker";
import { TimeSlotsPicker } from "@/forms/AddActivityForm/Scheduling/TimeSlotsPicker";
import { useParams } from "react-router";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import z from "zod";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { addActivitiesFromGroupTemplate } from "@/api/Activities";
import { ExpansionPanel } from "@components/ExpansionPanel/ExpansionPanel";
import styles from "../ActivityTemplatesAlerisAhc.module.scss";
import Form from "@/components/Form/Form";
import { FilledButton } from "@components/Button/Button";
import Checkboxes from "@/components/Checkbox/Checkboxes";
import { TemplateHeadingAlerisAhc } from "../TemplateHeadingAlerisAhc";
import Checkbox from "@/components/Checkbox/Checkbox";
import { deducedError } from "@/Utils/ErrorUtils";
import HiddenIcon from "@components/icons/HiddenIcon";
import { timeOfDaySchema } from "@models/activities";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import { GroupTemplateHeadingAleris } from "../TemplateHeadingAlerisAhc";
import type { IDateInputFields } from "@/forms/AddActivityForm/Scheduling/DateInput";
import { DateInput } from "@/forms/AddActivityForm/Scheduling/DateInput";
import { medicalCompetenceSchema } from "@models/shifts";
import { nextWednesday, isWednesday } from "date-fns";
import { format } from "@models/date-and-time";
import {
  saveMultipleActivitiesInvoicingCodes,
  type INewActivityWithOptionalInvoicingCodes,
} from "@/api/Invoicing";
import InvoicingBillIcon from "@components/icons/InvoicingBillIcon";
import {
  templateUnitsSchema,
  type ITemplateId,
  type ITemplateRevision,
} from "../../templateModels";
import { generateTemplateId } from "../../templateUtils";
import { Trans } from "@lingui/react/macro";

/**
 * Admission Template Id and revision number
 * NEVER change the Id.
 * Increment revision number when you make changes to the template.
 */
const TEMPLATE_ID: ITemplateId = await generateTemplateId({
  unit: templateUnitsSchema.Values.alerisahc,
  templateTitle: unitAlerisAhcGroupTemplateKeysSchema.Values.admission,
});
const TEMPLATE_REVISION: ITemplateRevision = 1;

const admissionActivities = {
  handoverMedicationList: templates.handoverMedicationList,
  keyQuittance: templates.keyQuittance,
  checkCatheter: templates.checkCatheter,
  riskAssessment: templates.riskAssessment,
  admissionInterview: templates.admissionInterview,
  workEnvironmentRiskAssessment: templates.workEnvironmentRiskAssessment,
  teamRound: templates.teamRound,
  leaveInformationMap: templates.leaveInformationMap,
  updatePatientInformation: templates.updatePatientInformation,
  medicationReviewSimple: templates.medicationReviewSimple,
  conferenceWithPatient: templates.conferenceWithPatient,
  leaveMedication: templates.leaveMedication,
  bloodSample: templates.bloodSample,
  screening: templates.screening,
  bringEmergencyKit: templates.bringEmergencyKit,
  documentRiskAssessmentTc: templates.documentRiskAssessmentTc,
};

export const AdmissionTemplateAlerisAhc = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { patientId } = z.object({ patientId: z.string() }).parse(useParams());
  const queryClient = useQueryClient();

  const methods = useForm<
    ITimeSlotsPickerFields &
      Pick<IDateInputFields, "startDate"> & {
        handoverMedicationList: boolean;
        riskAssessment: boolean;
        keyQuittance: boolean;
        checkCatheter: boolean;
        admissionInterview: boolean;
        workEnvironmentRiskAssessment: boolean;
        teamRound: boolean;
        leaveInformationMap: boolean;
        updatePatientInformation: boolean;
        medicationReviewSimple: boolean;
        conferenceWithPatient: boolean;
        leaveMedication: boolean;
        bloodSample: boolean;
        screening: boolean;
        bringEmergencyKit: boolean;
        documentRiskAssessmentTc: boolean;
      }
  >({
    defaultValues: {
      ...templates.sskAdmissionVisit,
      handoverMedicationList: true,
      riskAssessment: true,
      admissionInterview: true,
      keyQuittance: false,
      checkCatheter: false,
      workEnvironmentRiskAssessment: true,
      teamRound: true,
      leaveInformationMap: true,
      updatePatientInformation: true,
      medicationReviewSimple: true,
      conferenceWithPatient: true,
      leaveMedication: false,
      bloodSample: false,
      screening: false,
      bringEmergencyKit: false,
      documentRiskAssessmentTc: true,
    },
  });

  const {
    formState: { errors, isDirty },
    getValues,
    handleSubmit,
    register,
    reset,
    setError,
  } = methods;

  const { mutate, isPending } = useMutation({
    mutationFn: async (
      activities: INewActivityWithOptionalInvoicingCodes[],
    ) => {
      await addActivitiesFromGroupTemplate({
        templateId: TEMPLATE_ID,
        templateRevision: TEMPLATE_REVISION,
        newActivities: activities,
      });
      const activitiesWithInvoicingCodes = activities
        .filter(
          (activity) =>
            activity.patientId &&
            ((activity.procedureCodes && activity.procedureCodes.length > 0) ||
              (activity.productCodes && activity.productCodes.length > 0)),
        )
        .map((activity) => ({
          activityId: activity.activityId,
          patientId: activity.patientId as string, // Handled by the filter above
          procedureCodes: activity.procedureCodes || [],
          productCodes: activity.productCodes || [],
        }));
      if (activitiesWithInvoicingCodes.length > 0) {
        return saveMultipleActivitiesInvoicingCodes(
          activitiesWithInvoicingCodes,
        );
      }
    },
    onError: (error) => {
      setError("root.server", {
        message: deducedError(error),
      });
      // Reset `isDirty` to support only showing server error when the form is not changed.
      reset(getValues(), {
        keepErrors: true,
        keepIsSubmitted: true,
        keepTouched: true,
        keepIsValid: true,
        keepSubmitCount: true,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries();
      setIsExpanded(false);
    },
  });

  const validateAndSubmit = handleSubmit((formData) => {
    const {
      startDate,
      recurrencesPerDay,
      timeCategory,
      timeslots,
      timeSensitivity,
      handoverMedicationList,
      riskAssessment,
      keyQuittance,
      checkCatheter,
      admissionInterview,
      leaveInformationMap,
      medicationReviewSimple,
      conferenceWithPatient,
      leaveMedication,
      bloodSample,
      screening,
      bringEmergencyKit,
    } = formData;

    const timeConfig = {
      recurrencesPerDay,
      timeCategory,
      timeslots,
      timeSensitivity,
    };

    const activities: INewActivityWithOptionalInvoicingCodes[] = [];

    if (handoverMedicationList) {
      activities.push(
        newActivityFromTemplateAlerisAhc({
          template: {
            ...admissionActivities.handoverMedicationList,
            ...timeConfig,
          },
          startDate,
          patientId,
        }),
      );
    }
    if (riskAssessment) {
      activities.push(
        newActivityFromTemplateAlerisAhc({
          template: {
            ...admissionActivities.riskAssessment,
            ...timeConfig,
          },
          startDate,
          patientId,
        }),
      );
    }
    if (admissionInterview) {
      activities.push(
        newActivityFromTemplateAlerisAhc({
          template: {
            ...admissionActivities.admissionInterview,
            ...timeConfig,
          },
          startDate,
          patientId,
        }),
      );
    }
    if (leaveInformationMap) {
      activities.push(
        newActivityFromTemplateAlerisAhc({
          template: {
            ...admissionActivities.leaveInformationMap,
            ...timeConfig,
          },
          startDate,
          patientId,
        }),
      );
    }
    if (medicationReviewSimple) {
      activities.push(
        newActivityFromTemplateAlerisAhc({
          template: {
            ...admissionActivities.medicationReviewSimple,
            ...timeConfig,
          },
          startDate,
          patientId,
        }),
      );
    }
    if (keyQuittance) {
      activities.push(
        newActivityFromTemplateAlerisAhc({
          template: {
            ...admissionActivities.keyQuittance,
            ...timeConfig,
          },
          startDate,
          patientId,
        }),
      );
    }
    if (leaveMedication) {
      activities.push(
        newActivityFromTemplateAlerisAhc({
          template: {
            ...admissionActivities.leaveMedication,
            ...timeConfig,
          },
          startDate,
          patientId,
        }),
      );
    }
    if (bloodSample) {
      activities.push(
        newActivityFromTemplateAlerisAhc({
          template: {
            ...admissionActivities.bloodSample,
            ...timeConfig,
          },
          startDate,
          patientId,
        }),
      );
    }
    if (screening) {
      activities.push(
        newActivityFromTemplateAlerisAhc({
          template: {
            ...admissionActivities.screening,
            ...timeConfig,
          },
          startDate,
          patientId,
        }),
      );
    }
    if (checkCatheter) {
      activities.push(
        newActivityFromTemplateAlerisAhc({
          template: {
            ...admissionActivities.checkCatheter,
            ...timeConfig,
          },
          startDate,
          patientId,
        }),
      );
    }
    if (conferenceWithPatient) {
      activities.push(
        newActivityFromTemplateAlerisAhc({
          template: {
            ...admissionActivities.conferenceWithPatient,
            ...timeConfig,
          },
          startDate,
          patientId,
        }),
      );
    }
    if (bringEmergencyKit) {
      activities.push(
        newActivityFromTemplateAlerisAhc({
          template: {
            ...admissionActivities.bringEmergencyKit,
            ...timeConfig,
          },
          startDate,
          patientId,
        }),
      );
    }

    activities.push(
      newActivityFromTemplateAlerisAhc({
        template: {
          ...admissionActivities.workEnvironmentRiskAssessment,
          ...timeConfig,
          timeCategory: timeOfDaySchema.Values.Any,
        },
        startDate,
        patientId,
      }),
    );

    activities.push(
      newActivityFromTemplateAlerisAhc({
        template: {
          ...admissionActivities.updatePatientInformation,
          ...timeConfig,
          timeCategory: timeOfDaySchema.Values.Any,
        },
        startDate,
        patientId,
      }),
    );

    activities.push(
      newActivityFromTemplateAlerisAhc({
        template: {
          ...admissionActivities.documentRiskAssessmentTc,
          ...timeConfig,
          timeCategory: timeOfDaySchema.Values.Any,
        },
        startDate,
        patientId,
      }),
    );

    activities.push(
      newActivityFromTemplateAlerisAhc({
        template: admissionActivities.teamRound,
        startDate: isWednesday(startDate)
          ? startDate
          : format(nextWednesday(startDate) as Date, "yyyy-MM-dd"),
        patientId,
      }),
    );

    mutate(activities);
  });

  return (
    <ExpansionPanel
      // Don't translate user-generated content
      // eslint-disable-next-line lingui/no-unlocalized-strings
      trigger={<GroupTemplateHeadingAleris title={`Inskrivning`} />}
      // Don't translate user-generated content
      // eslint-disable-next-line lingui/no-unlocalized-strings
      triggerAriaLabel={`Inskrivning`}
      isExpanded={isExpanded}
      setIsExpanded={setIsExpanded}
    >
      <div className={styles.content}>
        <FormProvider {...methods}>
          <Form onSubmit={validateAndSubmit}>
            <div>
              <TemplateHeadingAlerisAhc
                template={{
                  ...templates.sskAdmissionVisit,
                  // TODO: Build a dynamic way to get required competences from the included activities.
                  // This will be needed for future batches of activities.
                  requiredCompetences: [
                    medicalCompetenceSchema.Values.NursePractitioner,
                  ],
                }}
              />
              <div className={styles.dayAndTime}>
                <DateInput hasEndDate={false} />
                <TimeSlotsPicker isRecurring={false} />
              </div>
              <div className={styles.checkboxes}>
                <Checkboxes>
                  {/* Use fieldset to get boolean default values from checkboxes.
                  See https://react-hook-form.com/docs/useform/register */}
                  <fieldset disabled className={styles.fieldsetWithCheckboxes}>
                    <Checkbox
                      errorMessage={errors.admissionInterview?.message}
                      label={{
                        text: admissionActivities.admissionInterview.title,
                      }}
                      {...register("admissionInterview")}
                    />
                    <Checkbox
                      errorMessage={errors.handoverMedicationList?.message}
                      label={{
                        text: admissionActivities.handoverMedicationList.title,
                      }}
                      {...register("handoverMedicationList")}
                    />
                    <div className={styles.checkboxRow}>
                      <Checkbox
                        errorMessage={errors.leaveInformationMap?.message}
                        label={{
                          text: admissionActivities.leaveInformationMap.title,
                        }}
                        {...register("leaveInformationMap")}
                      />
                      <HiddenIcon />
                    </div>
                    <div className={styles.checkboxRow}>
                      <Checkbox
                        errorMessage={errors.riskAssessment?.message}
                        label={{
                          text: admissionActivities.riskAssessment.title,
                        }}
                        {...register("riskAssessment")}
                      />
                      <HiddenIcon />
                    </div>
                  </fieldset>
                  <Checkbox
                    errorMessage={errors.leaveMedication?.message}
                    label={{
                      text: admissionActivities.leaveMedication.title,
                    }}
                    {...register("leaveMedication")}
                  />
                  <Checkbox
                    errorMessage={errors.bloodSample?.message}
                    label={{ text: admissionActivities.bloodSample.title }}
                    {...register("bloodSample")}
                  />
                  <div className={styles.checkboxRow}>
                    <Checkbox
                      errorMessage={errors.keyQuittance?.message}
                      label={{
                        text: admissionActivities.keyQuittance.title,
                      }}
                      {...register("keyQuittance")}
                    />
                    <HiddenIcon />
                  </div>
                  <Checkbox
                    errorMessage={errors.bringEmergencyKit?.message}
                    label={{
                      text: admissionActivities.bringEmergencyKit.title,
                    }}
                    {...register("bringEmergencyKit")}
                  />
                  <div className={styles.checkboxRow}>
                    <Checkbox
                      errorMessage={errors.screening?.message}
                      label={{
                        text: admissionActivities.screening.title,
                      }}
                      {...register("screening")}
                    />
                    <HiddenIcon />
                  </div>
                  <Checkbox
                    errorMessage={errors.checkCatheter?.message}
                    label={{ text: admissionActivities.checkCatheter.title }}
                    {...register("checkCatheter")}
                  />
                </Checkboxes>
              </div>
            </div>
            <div>
              <TemplateHeadingAlerisAhc
                template={{
                  ...templates.medicalDoctorAdmissionVisit,
                  // TODO: Build a dynamic way to get required competences from the included activities.
                  // This will be needed for future batches of activities.
                  requiredCompetences: [
                    medicalCompetenceSchema.Values.MedicalDoctor,
                  ],
                }}
              />
              <div className={styles.checkboxes}>
                <Checkboxes>
                  <div className={styles.checkboxRow}>
                    <Checkbox
                      errorMessage={errors.conferenceWithPatient?.message}
                      label={{
                        text: admissionActivities.conferenceWithPatient.title,
                      }}
                      {...register("conferenceWithPatient")}
                    />
                    <InvoicingBillIcon />
                  </div>
                  <div className={styles.checkboxRow}>
                    <Checkbox
                      errorMessage={errors.medicationReviewSimple?.message}
                      label={{
                        text: admissionActivities.medicationReviewSimple.title,
                      }}
                      {...register("medicationReviewSimple")}
                    />
                    <InvoicingBillIcon />
                  </div>
                </Checkboxes>
              </div>
            </div>
            <TemplateHeadingAlerisAhc
              template={admissionActivities.updatePatientInformation}
            />
            <TemplateHeadingAlerisAhc
              template={admissionActivities.documentRiskAssessmentTc}
            />
            <TemplateHeadingAlerisAhc
              template={admissionActivities.workEnvironmentRiskAssessment}
            />
            <TemplateHeadingAlerisAhc
              template={admissionActivities.teamRound}
            />
            {errors.root?.server?.message && !isDirty ? (
              <ErrorMessage
                message={errors.root.server.message}
                weight="bold"
              />
            ) : null}
            <FilledButton type="submit" disabled={isPending}>
              <Trans>Lägg till aktiviteter</Trans>
            </FilledButton>
          </Form>
        </FormProvider>
      </div>
    </ExpansionPanel>
  );
};
