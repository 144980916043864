import { Trans } from "@lingui/react/macro";
import { msg, t } from "@lingui/core/macro";
import styles from "./index.module.scss";
import type { GroupingState, SortingState, Row } from "@tanstack/react-table";
import {
  useReactTable,
  createColumnHelper,
  flexRender,
  getSortedRowModel,
  getCoreRowModel,
  getFilteredRowModel,
} from "@tanstack/react-table";
import { useSelectedDate } from "@/Utils/useSelectedDate";
import {
  activityCategoryDictionary,
  useActivityOccurrencesAndGroups,
} from "@/api/Activities";
import { Loading } from "@components/Loading/Loading";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import { deducedError } from "@/Utils/ErrorUtils";
import { memo, useMemo, useState } from "react";
import type {
  IActivityCategory,
  IActivityOccurrenceOrGroup,
  IActivityOccurrenceStatus,
  ITimeOfDay,
} from "@models/activities";
import {
  activityOccurrenceStatusSchema,
  categorySchema,
  isGroup,
  orderedStatuses,
  timeOfDaySchema,
} from "@models/activities";
import { ActorsCell } from "./ActorsCell";
import { StatusTag } from "@/components/StatusTag/StatusTag";
import { StatusTagWithDropdown } from "@/components/StatusTagWithDropdown/StatusTagWithDropdown";
import { getPatientNameWithStatus } from "@/api/Patients";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { Link } from "@components/Link/Link";
import { CategoryIcon } from "@/components/CategoryIcon/CategoryIcon";
import {
  patientStatusDictionary,
  patientStatusSchema,
  type IDeletedPatient,
  type IExistingPatient,
  type IPatient,
} from "@models/patients";
import RadioButtons from "@/components/RadioButton/RadioButtons";
import RadioButton from "@/components/RadioButton/RadioButton";
import * as Sentry from "@sentry/react";
import type { IScheduledShift } from "@models/shifts";
import { shiftName, shiftTimeDisplayValues } from "@models/shifts";
import { customGetGroupedRowModel } from "./customGetGroupedRowModel";
import { TimeSpan } from "@/components/Time/TimeSpan";
import { z } from "zod";
import { statusDictionary } from "@/components/StatusTag/statusTagUtils";
import Form from "@/components/Form/Form";
import InputField from "@/components/InputField/InputField";
import { useForm } from "react-hook-form";
import NoResults from "@/components/NoResults/NoResults";
import { InlineAlert } from "@components/InlineAlert/InlineAlert";
import { useLingui } from "@lingui/react";
import { type I18n } from "@lingui/core";
import { SplitPane } from "@/components/SplitPane/SplitPane";
import { Outlet, useParams } from "react-router";
import { Heading } from "@components/Heading/Heading";
import { ActivityTitle } from "@/components/ActivityTitle/ActivityTitle";
import clsx from "clsx";

type IActivityTableItem = {
  activityOccurrenceOrGroup: IActivityOccurrenceOrGroup;
  category: IActivityCategory;
  timespan: [Date, Date, ITimeOfDay];
  status: IActivityOccurrenceStatus[];
  title: IActivityOccurrenceOrGroup;
  patient?:
    | IDeletedPatient
    | Pick<IExistingPatient, "id" | "name" | "status">
    | null;
  actors: IActivityOccurrenceOrGroup;
  occurrenceIsActive: boolean;
  activityIsActive: boolean;
  groupIsActive: boolean;
  activeOccurrencesInGroup: { activityId: string; occurrenceId: string }[];
  activeActivitiesInGroup: { activityId: string }[];
};

const GROUPABLE_COLUMNS = ["category", "status", "patient", "actors"] as const;
const SORTABLE_COLUMNS = ["timespan", "status", "patient"] as const;
const COLUMN_DISPLAY_NAMES = {
  category: msg`Aktivitetstyp`,
  patient: msg`Patient`,
  status: msg`Status`,
  timespan: msg`Tid`,
  actors: msg`Utförare`,
};

const NOT_ASSIGNABLE = "NOT_ASSIGNABLE";
const NO_ONE_ASSIGNED = "NO_ONE_ASSIGNED";
const MULTIPLE_ASSIGNED = "MULTIPLE_ASSIGNED";

const columnHelper = createColumnHelper<IActivityTableItem>();

const columns = (translator: I18n["_"]) => [
  columnHelper.accessor("category", {
    header: translator(COLUMN_DISPLAY_NAMES["category"]),
    cell: ({ getValue }) => {
      return <CategoryIcon category={getValue()} size="small" />;
    },
  }),
  columnHelper.accessor("timespan", {
    header: translator(COLUMN_DISPLAY_NAMES["timespan"]),
    cell: ({ getValue }) => {
      const [start, end, type] = getValue();
      return (
        <TimeSpan
          timespan={type === timeOfDaySchema.Values.Any ? type : { start, end }}
        />
      );
    },
    sortingFn: (a, b) => {
      const aState = a.getValue<[Date, Date, ITimeOfDay]>("timespan");
      const bState = b.getValue<[Date, Date, ITimeOfDay]>("timespan");

      // Grouped rows are unlikely to have a timespan
      // If we don't have a timespan, we can't sort by it
      if (!aState || !bState) return 0;

      // Any time of day -> end of list
      if (aState[2] === "Any") {
        return 1;
      }
      // Any time of day -> end of list
      if (bState[2] === "Any") {
        return 1;
      }
      if (aState[0] < bState[0]) {
        return -1;
      } else if (aState[0] > bState[0]) {
        return 1;
      } else if (aState[1] < bState[1]) {
        return -1;
      } else if (aState[1] > bState[1]) {
        return 1;
      } else {
        return 0;
      }
    },
  }),
  columnHelper.accessor("status", {
    header: translator(COLUMN_DISPLAY_NAMES["status"]),
    cell: ({ getValue, row: { original } }) => {
      const { activityOccurrenceOrGroup } = original;
      if (!isGroup(activityOccurrenceOrGroup)) {
        const { category, activityId, id, status } = activityOccurrenceOrGroup;
        return (
          <StatusTagWithDropdown
            status={status}
            category={category}
            activityId={activityId}
            occurrenceId={id}
          />
        );
      }

      const statuses = getValue();
      const { occurrences } = activityOccurrenceOrGroup;
      return (
        <ul>
          {statuses.map((status, index) => {
            // Accessing occurrences here is temporary. When a group has its own status, it should be rendered here instead.
            // @ts-expect-error - Shouldn't direct access without checking, but here we know it's safe
            const { id, activityId } = occurrences[index];

            return (
              <li
                key={`${activityId}${id}`}
                className={styles.multipleStatusesListItem}
              >
                <StatusTag status={status} size="tiny" />
              </li>
            );
          })}
        </ul>
      );
    },
    getGroupingValue: ({ status }) => {
      return status.join(",");
    },
    sortingFn: (a, b) => {
      const aState = a.getValue<IActivityOccurrenceStatus[]>("status");
      const bState = b.getValue<IActivityOccurrenceStatus[]>("status");

      if (!aState[0] || !bState[0]) return 0;

      // For grouped rows, we take the first occurrence's status.
      // For non-grouped rows, we take the status directly.
      if (
        orderedStatuses.indexOf(aState[0]) < orderedStatuses.indexOf(bState[0])
      ) {
        return -1;
      } else if (
        orderedStatuses.indexOf(aState[0]) > orderedStatuses.indexOf(bState[0])
      ) {
        return 1;
      } else {
        return 0;
      }
    },
  }),
  columnHelper.accessor("title", {
    header: translator(msg`Aktivitet`),
    cell: ({ getValue, row }) => {
      const activityOccurrenceOrGroup = getValue();
      if (!isGroup(activityOccurrenceOrGroup)) {
        return (
          <ActivityTitle
            activityOccurrence={activityOccurrenceOrGroup}
            linkTo={`${activityOccurrenceOrGroup.activityId}/occurrences/${activityOccurrenceOrGroup.id}`}
            weight="medium"
            size="small"
          />
        );
      }

      return (
        <>
          <Link to={`occurrence-groups/${activityOccurrenceOrGroup.id}`}>
            <Trans>Hembesök</Trans>
          </Link>
          <ul>
            {activityOccurrenceOrGroup.occurrences.map((activityOccurrence) => {
              const { id, activityId } = activityOccurrence;

              return (
                <li
                  key={`${activityId}-${id}`}
                  className={clsx(
                    styles.activityTitleInGroup,
                    row.original.activeOccurrencesInGroup.find(
                      (item) =>
                        activityId === item.activityId &&
                        id === item.occurrenceId,
                    ) && styles.occurrenceIsActive,
                    row.original.activeActivitiesInGroup.find(
                      (item) => activityId === item.activityId,
                    ) && styles.activityIsActive,
                  )}
                >
                  <ActivityTitle
                    activityOccurrence={activityOccurrence}
                    linkTo={`${activityOccurrence.activityId}/occurrences/${activityOccurrence.id}`}
                    weight="regular"
                    size="small"
                  />
                </li>
              );
            })}
          </ul>
        </>
      );
    },
  }),
  columnHelper.accessor("patient", {
    header: translator(COLUMN_DISPLAY_NAMES["patient"]),
    cell: ({ getValue }) => {
      const patient = getValue();
      if (!patient) {
        return null;
      }
      if (patient.status === patientStatusSchema.Values.deleted) {
        return getPatientNameWithStatus(patient);
      }
      return (
        <Link to={`../patients/${patient.id}`} weight="regular">
          {getPatientNameWithStatus(patient)}
        </Link>
      );
    },
    getGroupingValue: ({ patient }) => {
      return patient ? patient.id : undefined;
    },
    sortingFn: (a, b) => {
      const aState = a.getValue<IPatient | undefined>("patient");
      const bState = b.getValue<IPatient | undefined>("patient");

      // Sort patient-less activities to the top
      if (!aState) {
        return -1;
      } else if (!bState) {
        return 1;
      }

      // Sort activities with deleted patients to the bottom
      if (aState.status === patientStatusSchema.Values.deleted) {
        return 1;
      } else if (bState.status === patientStatusSchema.Values.deleted) {
        return -1;
      }

      if (aState.name < bState.name) {
        return -1;
      } else if (aState.name > bState.name) {
        return 1;
      } else if (aState.id < bState.id) {
        return -1;
      } else if (aState.id > bState.id) {
        return 1;
      }
      return 0;
    },
    filterFn: (row, _columnId, filterValue) => {
      if (
        row.original.patient?.status !== patientStatusSchema.Values.deleted &&
        row.original.patient?.name
          ?.toLocaleLowerCase()
          .includes(filterValue.toLocaleLowerCase())
      )
        return true;
      return false;
    },
  }),
  columnHelper.accessor("actors", {
    header: translator(COLUMN_DISPLAY_NAMES["actors"]),
    cell: ({ getValue }) => {
      const activityOccurrenceOrGroup = getValue();
      return (
        <ActorsCell activityOccurrenceOrGroup={activityOccurrenceOrGroup} />
      );
    },
    getGroupingValue: ({ actors: activityOccurrence }) => {
      if (!("assignees" in activityOccurrence)) return NOT_ASSIGNABLE;
      if (activityOccurrence.assignees.length === 0) return NO_ONE_ASSIGNED;
      if (activityOccurrence.assignees.length > 1) return MULTIPLE_ASSIGNED;
      return activityOccurrence.assignees
        .map((assignee) => assignee.id)
        .join(",");
    },
    sortingFn: (a, b) => {
      // Only do assignees sorting on group level
      // On sub-row level, sort by next column, typically time
      if (a.depth > 0 && b.depth > 0) {
        return 0;
      }

      // Put unassignable activities at the bottom
      if (a.groupingValue === NOT_ASSIGNABLE) return 1;
      if (b.groupingValue === NOT_ASSIGNABLE) return -1;

      // Equivalent to checking `groupingValue` above.
      // TypeScript does not understand this relation, so we need this as typeguard.
      if (
        !("assignees" in a.original.actors) ||
        !("assignees" in b.original.actors)
      ) {
        return 0;
      }

      // Then put activities with no assignees at the bottom
      if (a.groupingValue === NO_ONE_ASSIGNED) return 1;
      if (b.groupingValue === NO_ONE_ASSIGNED) return -1;

      const getSortingValues = (
        assignees: IScheduledShift[],
        groupingValue: unknown,
      ) => {
        const assignee = assignees.find(
          (assignee) => assignee.id.toString() === groupingValue,
        );

        // Should never happen, since `groupingValue` is derived from assignees.
        // If it happens, just bail with default values.
        if (!assignee) {
          Sentry.captureException(new Error("Could not find assignee"));
          return { start: new Date(), end: new Date(), name: "" };
        }
        return {
          start: assignee.startDateTime,
          end: assignee.endDateTime,
          name: shiftName({ ...assignee, options: { length: "long" } }),
        };
      };

      const {
        start: aStart,
        end: aEnd,
        name: aName,
      } = getSortingValues(a.original.actors.assignees, a.groupingValue);
      const {
        start: bStart,
        end: bEnd,
        name: bName,
      } = getSortingValues(b.original.actors.assignees, b.groupingValue);

      return aStart < bStart
        ? -1
        : aStart > bStart
          ? 1
          : aEnd < bEnd
            ? -1
            : aEnd > bEnd
              ? 1
              : aName < bName
                ? -1
                : aName > bName
                  ? 1
                  : 0;
    },
  }),
];

const groupingSchema = z.union([
  z.object({ id: z.literal("actors"), value: z.string() }),
  z.object({ id: z.literal("category"), value: categorySchema }),
  z.object({ id: z.literal("patient"), value: z.string() }),
  z.object({ id: z.literal("status"), value: activityOccurrenceStatusSchema }),
]);
type IGrouping = z.infer<typeof groupingSchema>;

const GroupHeaderRow = ({
  columnCount,
  grouping,
}: {
  columnCount: number;
  grouping: IGrouping;
}) => {
  const { _ } = useLingui();
  const groupHeaderDisplayValue = (grouping: IGrouping) => {
    if (grouping.id === "category") {
      return _(activityCategoryDictionary[grouping.value]);
    }
    if (grouping.id === "status") {
      return _(statusDictionary[grouping.value]);
    }

    // For actors we group on list of ids, but we display the list of names
    if (grouping.id === "actors") {
      if (grouping.value === NOT_ASSIGNABLE)
        return <Trans>Inte tilldelbar</Trans>;
      if (grouping.value === NO_ONE_ASSIGNED)
        return <Trans>Ingen tilldelad</Trans>;
      if (grouping.value === MULTIPLE_ASSIGNED)
        return <Trans>Flera tilldelade</Trans>;
      return grouping.value;
    }

    // TODO: Check if this makes sense, `value` is just a string?
    return _(grouping.value);
  };
  return (
    <tr>
      <th colSpan={columnCount} className={styles.groupHeaderCell}>
        <Heading level="h2">{groupHeaderDisplayValue(grouping)}</Heading>
      </th>
    </tr>
  );
};

const groupValue = (
  row: Row<IActivityTableItem>,
  selectedDate: Date,
  translator: I18n["_"],
) => {
  // For patients we group on id, but we display the name (which in rare cases may be duplicated)
  if (row.groupingColumnId === "patient") {
    return row.original.patient
      ? row.original.patient.status === patientStatusSchema.Values.deleted
        ? translator(patientStatusDictionary.deleted.singular)
        : row.original.patient.name
      : // If there is no patient, fallback to a hyphen
        "-";
  }

  // For actors we group on list of ids, but we display the list of names
  if (row.groupingColumnId === "actors") {
    if (row.groupingValue === NOT_ASSIGNABLE) return row.groupingValue;
    if (row.groupingValue === NO_ONE_ASSIGNED) return row.groupingValue;
    if (row.groupingValue === MULTIPLE_ASSIGNED) return row.groupingValue;

    // Equivalent to checking `groupingValue` above.
    // TypeScript does not understand this relation, so we need this as typeguard.
    if (!("assignees" in row.original.actors)) {
      return NOT_ASSIGNABLE;
    }

    const assignedShift = row.original.actors.assignees.find((assignee) => {
      return row.groupingValue === assignee.id.toString();
    });

    // Should never happen, since `groupingValue` is derived from assignees.
    if (!assignedShift) {
      Sentry.captureException(new Error("Could not find any assigned shifts"));
      return NO_ONE_ASSIGNED;
    }

    const {
      startedDayBefore,
      hasEndTimeAfterToday,
      formattedDayBefore,
      formattedDayToday,
    } = shiftTimeDisplayValues({
      selectedDate,
      startDateTime: assignedShift.startDateTime,
      endDateTime: assignedShift.endDateTime,
    });

    const suffix = startedDayBefore
      ? t`Start ${formattedDayBefore}`
      : hasEndTimeAfterToday
        ? t`Start ${formattedDayToday}`
        : "";
    const spaceForSuffix = suffix ? " " : "";

    return `${shiftName({ ...assignedShift, options: { length: "long" } })} (${assignedShift.startDateTime.getHours()}-${assignedShift.endDateTime.getHours()})${spaceForSuffix}${suffix}`;
  }

  return row.groupingValue;
};

// When all Groups re-render, it can take several 100ms.
// Reduce the frequency of this happening by memoizing and only updating Groups when the selected date is changed.
// Cleaner logic welcome (the date string manipulation is easy to break), but the plan is to remove this view soon, so not prioritized.
// Also possible to drill down further and only re-render when the "actors" grouping is selected, since that is the only one requiring the selectedDate.
const Group = memo(
  ({
    groupRow,
    selectedDateString,
    translator,
  }: {
    groupRow: Row<IActivityTableItem>;
    selectedDateString: string;
    translator: I18n["_"];
  }) => {
    const selectedDate = new Date(selectedDateString);
    const [parent] = useAutoAnimate();

    const typeSafeGrouping = groupingSchema.parse({
      id: groupRow.groupingColumnId,
      value: groupValue(groupRow, selectedDate, translator),
    });

    return (
      <tbody ref={parent}>
        <GroupHeaderRow
          columnCount={groupRow.getVisibleCells().length}
          grouping={typeSafeGrouping}
        />
        {groupRow.subRows.map((row) => (
          <tr
            key={row.id}
            className={clsx(
              row.original.occurrenceIsActive && styles.occurrenceIsActive,
              row.original.activityIsActive && styles.activityIsActive,
              row.original.groupIsActive && styles.groupIsActive,
            )}
          >
            {row.getVisibleCells().map((cell) => (
              <td key={cell.id}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    );
  },
);
Group.displayName = "Group";

export const ActivitiesTable = () => {
  const { _ } = useLingui();
  const selectedDateString = useSelectedDate();
  const selectedDate = new Date(selectedDateString);

  const { handleSubmit, register, reset } = useForm<{ name: string }>();

  const {
    data: activityOccurrencesAndGroups,
    isPending,
    isError,
    error,
  } = useActivityOccurrencesAndGroups(
    selectedDate.toDateString(),
    selectedDate.toDateString(),
  );

  const params = useParams();

  const data = useMemo(
    () =>
      activityOccurrencesAndGroups?.map((activityOccurrenceOrGroup) => {
        if (isGroup(activityOccurrenceOrGroup)) {
          const group = activityOccurrenceOrGroup;
          return {
            activityOccurrenceOrGroup: group,
            category: categorySchema.Values.HomeVisit,
            timespan: [
              group.start,
              group.end,
              group.occurrences.every(
                ({ timeOfDay }) => timeOfDay === timeOfDaySchema.Values.Any,
              )
                ? timeOfDaySchema.Values.Any
                : timeOfDaySchema.Values.Specific,
            ] as [Date, Date, ITimeOfDay],
            status: group.occurrences.map(({ status }) => status),
            title: group,
            actors: group,
            patient: group.occurrences[0]?.patient,
            block: group,
            groupIsActive: params.groupId === group.id,
            occurrenceIsActive: false,
            activityIsActive: false,
            activeOccurrencesInGroup: group.occurrences
              .filter(
                (occurrence) =>
                  params.activityId === occurrence.activityId &&
                  params.occurrenceId === occurrence.id,
              )
              .map((occurrence) => ({
                activityId: occurrence.activityId,
                occurrenceId: occurrence.id,
              })),
            activeActivitiesInGroup: group.occurrences
              .filter(
                (occurrence) => params.activityId === occurrence.activityId,
              )
              .map((occurrence) => ({
                activityId: occurrence.activityId,
              })),
          };
        } else {
          const activityOccurrence = activityOccurrenceOrGroup;
          return {
            activityOccurrenceOrGroup: activityOccurrence,
            category: activityOccurrence.category,
            timespan: [
              activityOccurrence.start,
              activityOccurrence.end,
              activityOccurrence.timeOfDay,
            ] as [Date, Date, ITimeOfDay],
            status: [activityOccurrence.status],
            title: activityOccurrence,
            patient: activityOccurrence.patient,
            actors: activityOccurrence,
            block: activityOccurrence,
            occurrenceIsActive:
              params.occurrenceId === activityOccurrenceOrGroup.id &&
              params.activityId === activityOccurrenceOrGroup.activityId,
            activityIsActive:
              params.activityId === activityOccurrenceOrGroup.activityId,
            groupIsActive: false,
            activeOccurrencesInGroup: [],
            activeActivitiesInGroup: [],
          };
        }
      }) ?? [],
    [
      activityOccurrencesAndGroups,
      params.activityId,
      params.occurrenceId,
      params.groupId,
    ],
  );

  const [grouping, setGrouping] = useState<GroupingState>(["category"]);

  const [inGroupSorting, setInGroupSorting] = useState<SortingState>([
    { id: "timespan", desc: false },
  ]);
  const sorting = useMemo(
    () => [
      ...grouping.map((group) => ({ id: group, desc: false })),
      ...inGroupSorting.map((sort) => ({
        id: sort.id,
        desc: sort.desc,
      })),
      { id: "timespan", desc: false },
    ],
    [grouping, inGroupSorting],
  );
  const translatedColumns = columns(_);
  const table = useReactTable({
    data,
    columns: translatedColumns,
    getCoreRowModel: getCoreRowModel(),
    getGroupedRowModel: customGetGroupedRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    groupedColumnMode: false, // Don't move grouped columns to the left
    state: {
      grouping,
      sorting,
    },
  });

  const [parent] = useAutoAnimate();

  if (isError) {
    Sentry.captureException(error);
    return (
      <ErrorMessage
        message={`${t`Gick inte att hämta in aktiviteterna.`} ${deducedError(error)}`}
        padding={24}
      />
    );
  }

  const validateAndSubmit = handleSubmit(({ name }) => {
    table.getColumn("patient")?.setFilterValue(name);
  });

  const patientSearchValue = table
    .getColumn("patient")
    ?.getFilterValue() as string;

  return (
    <>
      <article className={styles.tableToolbar}>
        <RadioButtons orientation="horizontal" legend={t`Gruppering`}>
          {GROUPABLE_COLUMNS.map((column) => (
            <RadioButton
              label={{ text: _(COLUMN_DISPLAY_NAMES[column]) }}
              name="group-by"
              key={column}
              value={column}
              checked={grouping[0] === column}
              visualStyle="framed"
              onChange={(e) => setGrouping([e.target.value])}
            />
          ))}
        </RadioButtons>
        <RadioButtons orientation="horizontal" legend={t`Ordning`}>
          {SORTABLE_COLUMNS.map((column) => (
            <RadioButton
              label={{ text: _(COLUMN_DISPLAY_NAMES[column]) }}
              name="order-by"
              key={column}
              value={column}
              checked={inGroupSorting[0]?.id === column}
              visualStyle="framed"
              onChange={(e) =>
                setInGroupSorting([{ id: e.target.value, desc: false }])
              }
            />
          ))}
        </RadioButtons>
        <search>
          <Form onSubmit={validateAndSubmit}>
            <InputField
              type="search"
              label={t`Sök patient`}
              showOptionalLabel={false}
              size="compact"
              {...register("name")}
            />
          </Form>
        </search>
      </article>
      <div className={styles.absoluteContainer}>
        <SplitPane>
          {isPending ? (
            <Loading message={t`Hämtar aktiviteter`} padding={24} />
          ) : (
            <div className={styles.tableContainer}>
              {table.getColumn("patient")?.getIsFiltered() ? (
                <div className={styles.infoWrapper}>
                  <InlineAlert
                    type="info"
                    title={t`Sökt på patientnamn "${patientSearchValue}"`}
                    placement="fill"
                    actions={[
                      {
                        label: t`Rensa sökning`,
                        onClick: () => {
                          reset({ name: "" });
                          table.getColumn("patient")?.setFilterValue("");
                        },
                      },
                    ]}
                  />
                </div>
              ) : null}
              {table.getColumn("patient")?.getIsFiltered() &&
              table.getRowModel().rows.length === 0 ? (
                <NoResults
                  message={t`Inga aktiviteter med nuvarande sökfilter hittades`}
                  padding={24}
                />
              ) : null}
              <table
                className={styles.activitiesTable}
                key={selectedDate.toDateString()}
                ref={parent}
              >
                <thead className={styles.thead}>
                  {table.getHeaderGroups().map((headerGroup) => {
                    return (
                      <tr key={headerGroup.id}>
                        {headerGroup.headers.map((header) => (
                          <th
                            className={styles.tableHeaderCell}
                            key={header.id}
                          >
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                  header.column.columnDef.header,
                                  header.getContext(),
                                )}
                          </th>
                        ))}
                      </tr>
                    );
                  })}
                </thead>
                {/* Grouping logic/markup inspired by https://github.com/TanStack/table/discussions/3320#discussioncomment-1453996 */}
                {table.getRowModel().rows.map((groupedRow) => {
                  return (
                    <Group
                      key={groupedRow.id}
                      groupRow={groupedRow}
                      selectedDateString={selectedDateString}
                      translator={_}
                    />
                  );
                })}
              </table>
            </div>
          )}
          <Outlet />
        </SplitPane>
      </div>
    </>
  );
};
