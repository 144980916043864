import { Trans } from "@lingui/react/macro";
import { unitAlerisAhcGroupTemplateKeysSchema } from "../templatesAlerisAhc";
import { templates } from "../templatesAlerisAhc";
import type { ITimeSlotsPickerFields } from "@/forms/AddActivityForm/Scheduling/TimeSlotsPicker";
import { TimeSlotsPicker } from "@/forms/AddActivityForm/Scheduling/TimeSlotsPicker";
import { useParams } from "react-router";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import z from "zod";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import type { INewActivity } from "@/api/Activities";
import { addActivitiesFromGroupTemplate } from "@/api/Activities";
import { newActivityFromTemplateAlerisAhc } from "../newActivityFromTemplateAlerisAhc";
import { ExpansionPanel } from "@components/ExpansionPanel/ExpansionPanel";
import styles from "../ActivityTemplatesAlerisAhc.module.scss";
import Form from "@/components/Form/Form";
import { FilledButton } from "@components/Button/Button";
import Checkboxes from "@/components/Checkbox/Checkboxes";
import {
  TemplateHeadingAlerisAhc,
  GroupTemplateHeadingAleris,
} from "../TemplateHeadingAlerisAhc";
import Checkbox from "@/components/Checkbox/Checkbox";
import { deducedError } from "@/Utils/ErrorUtils";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import type { IDateInputFields } from "@/forms/AddActivityForm/Scheduling/DateInput";
import { DateInput } from "@/forms/AddActivityForm/Scheduling/DateInput";
import { timeOfDaySchema } from "@models/activities";
import { medicalCompetenceSchema } from "@models/shifts";
import {
  saveMultipleActivitiesInvoicingCodes,
  type INewActivityWithOptionalInvoicingCodes,
} from "@/api/Invoicing";
import InvoicingBillIcon from "@components/icons/InvoicingBillIcon";
import {
  templateUnitsSchema,
  type ITemplateId,
  type ITemplateRevision,
} from "../../templateModels";
import { generateTemplateId } from "../../templateUtils";

/**
 * Discharge Template Id and revision number
 * NEVER change the Id.
 * Increment revision number when you make changes to the template.
 */
const TEMPLATE_ID: ITemplateId = await generateTemplateId({
  unit: templateUnitsSchema.Values.alerisahc,
  templateTitle: unitAlerisAhcGroupTemplateKeysSchema.Values.discharge,
});
const TEMPLATE_REVISION: ITemplateRevision = 1;

const dischargeActivities = {
  handoverMedicationList: templates.handoverMedicationList,
  returnKey: templates.returnKey,
  securityReceipt: templates.securityReceipt,
  retrievalLoanedIVStand: templates.retrievalLoanedIVStand,
  medication: templates.medication,
  evaluateAndEndActivityPlans: templates.evaluateAndEndActivityPlans,
};

export const DischargeTemplateAleris = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { patientId } = z.object({ patientId: z.string() }).parse(useParams());
  const queryClient = useQueryClient();

  const methods = useForm<
    ITimeSlotsPickerFields &
      Pick<IDateInputFields, "startDate"> & {
        retrievalLoanedIVStand: boolean;
        handoverMedicationList: boolean;
        returnKey: boolean;
        securityReceipt: boolean;
        medication: boolean;
        evaluationAndEndActivityPlans: boolean;
      }
  >({
    defaultValues: {
      ...templates.dischargeVisit,
      timeCategory: timeOfDaySchema.Values.Any,
      handoverMedicationList: false,
      returnKey: false,
      medication: false,
      securityReceipt: false,
      retrievalLoanedIVStand: false,
      evaluationAndEndActivityPlans: false,
    },
  });

  const {
    register,
    handleSubmit,
    setError,
    reset,
    getValues,
    formState: { errors, isDirty },
  } = methods;

  const { mutate, isPending } = useMutation({
    mutationFn: async (
      activities: INewActivityWithOptionalInvoicingCodes[],
    ) => {
      await addActivitiesFromGroupTemplate({
        templateId: TEMPLATE_ID,
        templateRevision: TEMPLATE_REVISION,
        newActivities: activities,
      });
      const activitiesWithInvoicingCodes = activities
        .filter(
          (activity) =>
            activity.patientId &&
            ((activity.procedureCodes && activity.procedureCodes.length > 0) ||
              (activity.productCodes && activity.productCodes.length > 0)),
        )
        .map((activity) => ({
          activityId: activity.activityId,
          patientId: activity.patientId as string, // Handled by the filter above
          procedureCodes: activity.procedureCodes || [],
          productCodes: activity.productCodes || [],
        }));
      if (activitiesWithInvoicingCodes.length > 0) {
        return saveMultipleActivitiesInvoicingCodes(
          activitiesWithInvoicingCodes,
        );
      }
    },
    onError: (error) => {
      setError("root.server", {
        message: deducedError(error),
      });
      // Reset `isDirty` to support only showing server error when the form is not changed.
      reset(getValues(), {
        keepErrors: true,
        keepIsSubmitted: true,
        keepTouched: true,
        keepIsValid: true,
        keepSubmitCount: true,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries();
      setIsExpanded(false);
    },
  });

  const validateAndSubmit = handleSubmit((formData) => {
    const {
      startDate,
      recurrencesPerDay,
      timeCategory,
      timeslots,
      timeSensitivity,
      handoverMedicationList,
      returnKey,
      securityReceipt,
      retrievalLoanedIVStand,
      medication,
    } = formData;

    const timeConfig = {
      recurrencesPerDay,
      timeCategory,
      timeslots,
      timeSensitivity,
    };

    const activities: INewActivity[] = [];

    if (handoverMedicationList) {
      activities.push(
        newActivityFromTemplateAlerisAhc({
          template: {
            ...dischargeActivities.handoverMedicationList,
            ...timeConfig,
          },
          startDate,
          patientId,
        }),
      );
    }
    if (returnKey) {
      activities.push(
        newActivityFromTemplateAlerisAhc({
          template: {
            ...dischargeActivities.returnKey,
            ...timeConfig,
          },
          startDate,
          patientId,
        }),
      );
    }
    if (securityReceipt) {
      activities.push(
        newActivityFromTemplateAlerisAhc({
          template: {
            ...dischargeActivities.securityReceipt,
            ...timeConfig,
            timeCategory: timeOfDaySchema.Values.Any,
          },
          startDate,
          patientId,
        }),
      );
    }
    if (retrievalLoanedIVStand) {
      activities.push(
        newActivityFromTemplateAlerisAhc({
          template: {
            ...dischargeActivities.retrievalLoanedIVStand,
            ...timeConfig,
          },
          startDate,
          patientId,
        }),
      );
    }
    if (medication) {
      activities.push(
        newActivityFromTemplateAlerisAhc({
          template: {
            ...dischargeActivities.medication,
            ...timeConfig,
          },
          startDate,
          patientId,
        }),
      );
    }

    activities.push(
      newActivityFromTemplateAlerisAhc({
        template: dischargeActivities.evaluateAndEndActivityPlans,
        startDate,
        patientId,
      }),
    );

    mutate(activities);
  });

  return (
    <ExpansionPanel
      // Don't translate user-generated content
      // eslint-disable-next-line lingui/no-unlocalized-strings
      trigger={<GroupTemplateHeadingAleris title={`Utskrivning`} />}
      // Don't translate user-generated content
      // eslint-disable-next-line lingui/no-unlocalized-strings
      triggerAriaLabel={`Utskrivning`}
      isExpanded={isExpanded}
      setIsExpanded={setIsExpanded}
    >
      <div className={styles.content}>
        <FormProvider {...methods}>
          <Form onSubmit={validateAndSubmit}>
            <div>
              <TemplateHeadingAlerisAhc
                template={{
                  ...templates.dischargeVisit,
                  requiredCompetences: [
                    medicalCompetenceSchema.Values.NursePractitioner,
                  ],
                }}
              />
              <div className={styles.dayAndTime}>
                <DateInput hasEndDate={false} />
                <TimeSlotsPicker isRecurring={false} />
              </div>
              <div className={styles.checkboxes}>
                <Checkboxes>
                  <Checkbox
                    errorMessage={errors.handoverMedicationList?.message}
                    label={{
                      text: dischargeActivities.handoverMedicationList.title,
                    }}
                    {...register("handoverMedicationList")}
                  />
                  <Checkbox
                    errorMessage={errors.medication?.message}
                    label={{ text: dischargeActivities.medication.title }}
                    {...register("medication")}
                  />
                  <Checkbox
                    errorMessage={errors.returnKey?.message}
                    label={{ text: dischargeActivities.returnKey.title }}
                    {...register("returnKey")}
                  />
                  <div className={styles.checkboxRow}>
                    <Checkbox
                      errorMessage={errors.securityReceipt?.message}
                      label={{
                        text: dischargeActivities.securityReceipt.title,
                      }}
                      {...register("securityReceipt")}
                    />
                    <InvoicingBillIcon />
                  </div>
                  <Checkbox
                    errorMessage={errors.retrievalLoanedIVStand?.message}
                    label={{
                      text: dischargeActivities.retrievalLoanedIVStand.title,
                    }}
                    {...register("retrievalLoanedIVStand")}
                  />
                </Checkboxes>
              </div>
            </div>
            <TemplateHeadingAlerisAhc
              template={dischargeActivities.evaluateAndEndActivityPlans}
            />
            {errors.root?.server?.message && !isDirty ? (
              <ErrorMessage
                message={errors.root.server.message}
                weight="bold"
              />
            ) : null}
            <FilledButton type="submit" disabled={isPending}>
              <Trans>Lägg till aktiviteter</Trans>
            </FilledButton>
          </Form>
        </FormProvider>
      </div>
    </ExpansionPanel>
  );
};
