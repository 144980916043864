import { timeOfDayDictionary, timeOfDaySchema } from "@models/activities";
import type { IText } from "@components/Text/Text";
import { Text } from "@components/Text/Text";
import styles from "./TimeSpan.module.scss";
import { formattedTime } from "./timeUtils";
import { useLingui } from "@lingui/react";

type TextProps = Pick<IText, "color" | "size" | "weight">;

type ITimeSpan = {
  timespan: { start: Date; end: Date } | typeof timeOfDaySchema.Values.Any;
};

type TimeSpanProps = ITimeSpan & TextProps;

export const TimeSpan = ({
  timespan,
  color,
  size = "small",
  weight,
}: TimeSpanProps) => {
  const { _ } = useLingui();

  if (timespan === timeOfDaySchema.Values.Any) {
    return (
      <time className={styles.timespan}>
        <Text element="span" size={size} weight={weight} color={color}>
          {_(timeOfDayDictionary.Any.short)}
        </Text>
      </time>
    );
  }

  const { start, end } = timespan;

  return (
    <time className={styles.timespan}>
      <Text element="span" size={size} weight={weight} color={color}>
        {formattedTime(start)}
      </Text>
      <Text element="span" size={size} weight={weight} color={color}>
        -
      </Text>
      <Text element="span" size={size} weight={weight} color={color}>
        {formattedTime(end)}
      </Text>
    </time>
  );
};
