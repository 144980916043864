import { t } from "@lingui/core/macro";
import styles from "./Planning.module.scss";
import { useEffect, useState, type ChangeEvent } from "react";
import { usePlanningViewToolbar } from "./usePlanningViewToolbar";
import StandaloneCheckbox from "@/components/Checkbox/StandaloneCheckbox";
import { PlanningSideBar } from "./SideBar/PlanningSideBar";
import { ShiftsSection } from "./ShiftsSection";
import { PlanningContextProvider } from "./PlanningContextProvider";
import { MapProvider } from "react-map-gl/mapbox";
import clsx from "clsx";
import { useUnplannedOccurrencesAndGroups } from "@/api/Activities";
import { useSelectedDate } from "@/Utils/useSelectedDate";
import { PlanningMap } from "./PlanningMap";
import { useMap } from "react-map-gl/mapbox";
import useResizablePanels from "./useResizablePanels";
import { PageToolbar } from "@/components/PageToolbar/PageToolbar";
import RadioButton from "@/components/RadioButton/RadioButton";

export type IAllocatableItemType = "group" | "activityOccurrence" | undefined;

export const Planning = () => {
  return (
    <PlanningContextProvider>
      <MapProvider>
        <InnerPlanning />
      </MapProvider>
    </PlanningContextProvider>
  );
};

const InnerPlanning = () => {
  const [
    isExpanded,
    setIsExpanded,
    isMapShown,
    setIsMapShown,
    isToPlanShown,
    setIsToPlanShown,
    mapPosition,
    setMapPosition,
  ] = usePlanningViewToolbar();
  const [isPendingAddToShift, setIsPendingAddToShift] = useState(false);

  const { planningMap } = useMap();

  const { containerRef, handleRef, leadingPanelRef } = useResizablePanels({
    layout: mapPosition === "bottom" ? "vertical" : "horizontal",
    initialLeadingPanelSize: mapPosition === "bottom" ? "33%" : "50%",
    minPanelSize: 100,
  });

  useEffect(() => {
    if (!planningMap) {
      // planningMap is probably closed, or just not loaded, so this is a no-op
      return;
    } else {
      planningMap.resize();
    }
  }, [isToPlanShown, planningMap]);

  const selectedDate = new Date(useSelectedDate());

  // Resize the map when the left panel is resized with debounce
  useEffect(() => {
    if (!leadingPanelRef.current || !planningMap) {
      return;
    }

    let resizeTimeoutId: NodeJS.Timeout | null = null;

    const resizeHandler = () => {
      if (resizeTimeoutId) {
        clearTimeout(resizeTimeoutId);
      }

      resizeTimeoutId = setTimeout(() => {
        planningMap.resize();
        resizeTimeoutId = null; // Clear timeout ID after execution
      }, 200);
    };

    const observer = new ResizeObserver(resizeHandler);
    observer.observe(leadingPanelRef.current);

    return () => {
      if (resizeTimeoutId) {
        clearTimeout(resizeTimeoutId);
      }
      observer.disconnect();
    };
  }, [leadingPanelRef, planningMap]);

  const {
    data: unplannedOccurrencesAndGroups,
    isPending,
    isError,
  } = useUnplannedOccurrencesAndGroups(
    selectedDate.toDateString(),
    selectedDate.toDateString(),
  );

  const handleMapPositionChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === "bottom") {
      setMapPosition("bottom");
    }
    if (e.target.value === "right") {
      setMapPosition("right");
    }
  };

  const numberOfUnplannedOccurrences =
    unplannedOccurrencesAndGroups?.length ?? 0;

  return (
    <section className={styles.container}>
      <PageToolbar showDaySwitcher>
        <div className={styles.pageOptions}>
          <StandaloneCheckbox
            label={
              isError || isPending
                ? t`Att planera`
                : t`Att planera (${numberOfUnplannedOccurrences})`
            }
            name="to-plan-view"
            checked={isToPlanShown}
            onChange={(e) => setIsToPlanShown(e.target.checked)}
          />
          <StandaloneCheckbox
            label={t`Expanderat läge`}
            name="expanded-view"
            checked={isExpanded}
            onChange={(e) => setIsExpanded(e.target.checked)}
          />
          <fieldset className={styles.mapOptions}>
            <StandaloneCheckbox
              label={t`Visa karta`}
              name="show-map"
              checked={isMapShown}
              onChange={(e) => setIsMapShown(e.target.checked)}
            />
            {isMapShown ? (
              <div className={styles.mapPositionOptions}>
                <RadioButton
                  name="map-placement"
                  value="right"
                  label={{
                    text: t`till höger`,
                  }}
                  checked={mapPosition === "right"}
                  onChange={handleMapPositionChange}
                  disabled={!isMapShown}
                />
                <RadioButton
                  name="map-placement"
                  value="bottom"
                  label={{
                    text: t`nedtill`,
                  }}
                  checked={mapPosition === "bottom"}
                  onChange={handleMapPositionChange}
                  disabled={!isMapShown}
                />
              </div>
            ) : null}
          </fieldset>
        </div>
      </PageToolbar>

      <div className={styles.contentWrapper}>
        {isToPlanShown ? (
          <section className={styles.toPlan}>
            <PlanningSideBar isPendingAddToShift={isPendingAddToShift} />
          </section>
        ) : null}
        <div
          ref={containerRef}
          className={clsx(
            styles.shiftsAndMap,
            isToPlanShown ? "" : styles.sideBarHidden,
            isMapShown ? "" : styles.mapHidden,
            mapPosition === "bottom" ? styles.verticalLayout : "",
          )}
        >
          <div ref={leadingPanelRef} className={styles.shifts}>
            <ShiftsSection
              isExpanded={isExpanded}
              onPendingAddToShift={setIsPendingAddToShift}
            />
          </div>
          <div
            ref={handleRef}
            role="separator"
            aria-orientation="vertical"
            className={styles.handle}
            title={t`Dra för att justera bredd`}
          ></div>
          {isMapShown ? (
            <div className={styles.map}>
              <PlanningMap />
            </div>
          ) : null}
        </div>
      </div>
    </section>
  );
};
