import clsx from "clsx";
import styles from "./Chip.module.scss";

export interface IChip {
  children: React.ReactNode;
  onClick?: () => void;
  ariaLabel?: string;
  iconStart?: React.ReactNode;
  iconEnd?: React.ReactNode;
  color?:
    | "primary"
    | "blue"
    | "gray"
    | "green"
    | "orange"
    | "red"
    | "yellow"
    | "purple"
    | "warning";
  border?: "none" | "solid" | "dashed";
  background?: "none" | "solid";
  size?: "tiny" | "small" | "medium";
  shape?: "sharp" | "round";
  disabled?: boolean;
  weight?: "regular" | "medium" | "bold";
}
const Chip = ({
  children,
  onClick,
  ariaLabel = "",
  color = "gray",
  border = "none",
  background = "solid",
  size = "small",
  shape = "round",
  weight = "regular",
  iconStart,
  iconEnd,
  disabled = false,
}: IChip) => {
  const isButton = Boolean(onClick);

  const classes = clsx(
    styles.chip,
    styles[`border-${border}`],
    styles[`background-${background}`],
    styles[`color-${color}`],
    styles[`size-${size}`],
    styles[`weight-${weight}`],
    styles[`shape-${shape}`],
    styles[`disabled-${disabled}`],
  );

  return isButton ? (
    <button
      aria-label={ariaLabel}
      className={classes}
      onClick={onClick}
      disabled={disabled}
    >
      {iconStart ? iconStart : null}
      <span>{children}</span>
      {iconEnd ? iconEnd : null}
    </button>
  ) : (
    <div className={classes}>
      {iconStart ? iconStart : null}
      <span>{children}</span>
      {iconEnd ? iconEnd : null}
    </div>
  );
};

export default Chip;
