import { msg } from "@lingui/core/macro";
import { z } from "zod";
import { addressWithCoordinates } from "./addresses";
import { localizePhoneNumber } from "./phoneNumbers";
import { dateSchema } from "./date-and-time";
import type { MessageDescriptor } from "@lingui/core";

const relativeSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  phoneNumber: z.string().transform(localizePhoneNumber),
  relation: z.string(),
  isLivingTogether: z.boolean(),
});

export type IRelative = z.infer<typeof relativeSchema>;
export const patientStatusSchema = z.enum([
  "prospect",
  "admitted",
  "discharged",
  "declined",
  "deleted",
]);
export const patientStatusDictionary: Record<
  IPatientStatus,
  { singular: MessageDescriptor; plural: MessageDescriptor }
> = {
  prospect: { singular: msg`Under bedömning`, plural: msg`Under bedömning` },
  admitted: { singular: msg`Inskriven`, plural: msg`Inskrivna` },
  discharged: { singular: msg`Utskriven`, plural: msg`Utskrivna` },
  declined: { singular: msg`Fått avslag`, plural: msg`Fått avslag` },
  deleted: { singular: msg`Borttagen`, plural: msg`Borttagna` },
};
export type IPatientStatus = z.infer<typeof patientStatusSchema>;

export const activeStatuses = [
  patientStatusSchema.Values.prospect,
  patientStatusSchema.Values.admitted,
];

export const canDischargePatient = ({ status }: Pick<IPatient, "status">) =>
  status === patientStatusSchema.Values.admitted;

export const ward = z.object({ displayName: z.string(), id: z.number() });

export const wardsSchema = z.array(ward);

export type IWard = z.infer<typeof ward>;

export const area = z.object({ displayName: z.string(), id: z.number() });

export const areasSchema = z.array(area);

export type IArea = z.infer<typeof area>;

const careHubSchema = z.object({
  id: z.string(),
  latitude: z.number(),
  longitude: z.number(),
});

export const careHubsSchema = z.array(careHubSchema);

export type ICareHub = z.infer<typeof careHubSchema>;

export const patientTypesForInvoicing = [
  "AdvancedHomeCare",
  "External",
] as const;

export const patientTypesForInvoicingSchema = z.enum(patientTypesForInvoicing);
export const patientTypes = [
  "HospitalAtHome",
  ...patientTypesForInvoicing,
] as const;

export const allPatientTypes = [...patientTypes];
export const patientTypeSchema = z.enum(patientTypes);
export const patientTypesSchema = z.array(patientTypeSchema);
export type IPatientType = z.infer<typeof patientTypeSchema>;

export const patientTypeDictionary: Record<
  "HospitalAtHome" | "AdvancedHomeCare" | "External",
  MessageDescriptor
> = {
  HospitalAtHome: msg`Sjukhus hemma`,
  AdvancedHomeCare: msg`ASIH`,
  External: msg`Särskilt uppdrag`,
};
export const deletedPatientSchema = z.object({
  id: z.string().uuid(),
  status: z.literal(patientStatusSchema.Values.deleted),
});
export type IDeletedPatient = z.infer<typeof deletedPatientSchema>;

export const personalIdentityNumberTypeSchema = z.enum([
  "SwedishPersonalIdentityNumber",
  "SwedishTemporaryIdentityNumber",
]);

export type IPersonalIdentityNumberType = z.infer<
  typeof personalIdentityNumberTypeSchema
>;

export const prospectPatientSchema = deletedPatientSchema.extend({
  address: addressWithCoordinates,
  healthcareJourneyNumber: z.string().length(8).nullish(),
  information: z.string().nullish(),
  name: z.string(),
  personalIdentityNumber: z.string(),
  personalIdentityNumberType: personalIdentityNumberTypeSchema,
  phoneNumber: z.string().transform(localizePhoneNumber).nullish(),
  status: z.literal(patientStatusSchema.Values.prospect),
  relatives: z.array(relativeSchema),
  safetyAlarmText: z.string().nullish(),
  registeredAt: dateSchema,
  ward: ward,
  area: area.nullish(),
  patientType: patientTypeSchema,
  pet: z.string().nullish(),
  homeCare: z.boolean().nullish(),
  lifeCare: z.boolean().nullish(),
  diagnosisCode: z.string().nullish(),
});
export type IRegisteredPatient = z.infer<typeof prospectPatientSchema>;

export const admittedPatientSchema = prospectPatientSchema.extend({
  admittedAt: dateSchema,
  status: z.literal(patientStatusSchema.Values.admitted),
});
export type IAdmittedPatient = z.infer<typeof admittedPatientSchema>;

const declinedPatientSchema = prospectPatientSchema.extend({
  declinedAt: dateSchema,
  status: z.literal(patientStatusSchema.Values.declined),
});
export type IDeclinedPatient = z.infer<typeof declinedPatientSchema>;

const dischargedPatientSchema = admittedPatientSchema.extend({
  dischargedAt: dateSchema,
  status: z.literal(patientStatusSchema.Values.discharged),
});
export type IDischargedPatient = z.infer<typeof dischargedPatientSchema>;

// Standardized omitters
const listPatient = {
  healthcareJourneyNumber: true,
  relatives: true,
  safetyAlarmText: true,
  pet: true,
  homeCare: true,
  lifeCare: true,
} as const;

export const patientSchema = z.union([
  prospectPatientSchema,
  admittedPatientSchema,
  declinedPatientSchema,
  dischargedPatientSchema,
  deletedPatientSchema,
]);
export type IPatient = z.infer<typeof patientSchema>;

export const existingPatientSchema = z.union([
  prospectPatientSchema,
  admittedPatientSchema,
  declinedPatientSchema,
  dischargedPatientSchema,
]);
export type IExistingPatient = z.infer<typeof existingPatientSchema>;

export const listProspectPatientSchema =
  prospectPatientSchema.omit(listPatient);
export const listAdmittedPatientSchema =
  admittedPatientSchema.omit(listPatient);
export const listDischargedPatientSchema =
  dischargedPatientSchema.omit(listPatient);

export const listPatientSchema = z.union([
  prospectPatientSchema.omit(listPatient),
  admittedPatientSchema.omit(listPatient),
  declinedPatientSchema.omit(listPatient),
  dischargedPatientSchema.omit(listPatient),
  deletedPatientSchema,
]);

export const listExistingPatientSchema = z.union([
  prospectPatientSchema.omit(listPatient),
  admittedPatientSchema.omit(listPatient),
  declinedPatientSchema.omit(listPatient),
  dischargedPatientSchema.omit(listPatient),
]);

const prospectPatientWithHomeVisitSchema = prospectPatientSchema
  .omit(listPatient)
  .extend({ hasHomeVisit: z.boolean() });

const admittedPatientWithHomeVisitSchema = admittedPatientSchema
  .omit(listPatient)
  .extend({ hasHomeVisit: z.boolean() });

const declinedPatientWithHomeVisitSchema = declinedPatientSchema
  .omit(listPatient)
  .extend({ hasHomeVisit: z.boolean() });

const dischargedPatientWithHomeVisitSchema = dischargedPatientSchema
  .omit(listPatient)
  .extend({ hasHomeVisit: z.boolean() });

export const listExistingPatientWithHomeVisitFlagSchema = z.union([
  prospectPatientWithHomeVisitSchema,
  admittedPatientWithHomeVisitSchema,
  declinedPatientWithHomeVisitSchema,
  dischargedPatientWithHomeVisitSchema,
]);
