import clsx from "clsx";
import type { HTMLInputTypeAttribute } from "react";
import { forwardRef, useId } from "react";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import styles from "./InputField.module.scss";
import { t } from "@lingui/core/macro";

interface IInputField {
  autoComplete?: string;
  errorMessage?: string;
  formatHint?: string;
  icon?: React.ReactNode;
  name: string;
  label: string;
  hideLabel?: boolean; // Only to be used when part of a `<fieldset>` with a `<legend>` that acts as label
  showOptionalLabel: boolean;
  description?: React.ReactNode;
  suggestions?: string[];
  type?: HTMLInputTypeAttribute;
  pattern?: string;
  outerWidth?: "full" | "fit";
  inputWidth?: string;
  size?: "default" | "compact";
  prefix?: string;
  suffix?: string;
}

const InputField = forwardRef<HTMLInputElement | null, IInputField>(
  (
    {
      autoComplete,
      errorMessage,
      formatHint,
      name,
      label,
      hideLabel,
      showOptionalLabel,
      description,
      icon,
      suggestions,
      type,
      pattern,
      outerWidth = ["date", "number", "time"].includes(type ?? "")
        ? "fit"
        : "full",
      inputWidth,
      size = "default",
      prefix,
      suffix,
      ...rest
    },
    ref,
  ) => {
    const id = useId();
    const datalistId = useId();
    const descriptionId = useId();

    const displayLabel =
      `${label} ${showOptionalLabel ? t`(valfri)` : ""}`.trim();

    return (
      <div
        className={clsx(
          styles.inputFieldWrapper,
          styles[`outerWidth-${outerWidth}`],
        )}
      >
        {hideLabel ? null : icon ? (
          <div className={styles.labelWithIcon}>
            {icon}
            <label className={styles.label} htmlFor={id}>
              {displayLabel}
            </label>
          </div>
        ) : (
          <label className={styles.label} htmlFor={id}>
            {displayLabel}
          </label>
        )}
        {description ? (
          <p id={descriptionId} className={styles.description}>
            {description}
          </p>
        ) : undefined}
        {errorMessage ? (
          <div className={styles.errorMessage}>
            <ErrorMessage message={errorMessage} weight="bold" />
          </div>
        ) : undefined}
        <div
          className={clsx(
            styles.inputWrapper,
            styles[`size-${size}`],
            hideLabel && styles.hiddenLabel,
          )}
        >
          {prefix ? <div className={styles.prefix}>{prefix}</div> : undefined}
          <input
            aria-invalid={Boolean(errorMessage) || undefined}
            aria-describedby={description ? descriptionId : undefined}
            aria-label={hideLabel ? label : undefined}
            autoComplete={autoComplete || Math.random().toString(36)}
            className={styles.input}
            style={inputWidth ? { width: inputWidth } : undefined}
            formNoValidate={true}
            id={id}
            list={suggestions ? datalistId : undefined}
            name={name}
            placeholder={formatHint}
            ref={ref}
            type={type}
            pattern={pattern}
            {...rest}
          />
          {suffix ? <div className={styles.suffix}>{suffix}</div> : undefined}
        </div>
        {suggestions ? (
          <datalist id={datalistId}>
            {suggestions.map((suggestion) => (
              <option key={suggestion} value={suggestion} />
            ))}
          </datalist>
        ) : undefined}
      </div>
    );
  },
);
InputField.displayName = "InputField";

export default InputField;
