import { useLocalStorage } from "@/Utils/useLocalStorage";

export const usePlanningViewToolbar = () => {
  const [storedExpandedValue, setStoredExpandedValue] = useLocalStorage(
    "planningViewExpanded",
    "false",
  );
  const [storedMapShownValue, setStoredMapShownValue] = useLocalStorage(
    "planningViewMapShown",
    "false",
  );
  const [storedToPlanShownValue, setStoredToPlanShownValue] = useLocalStorage(
    "planningViewToPlanShown",
    "true",
  );
  const [storedMapPositionValue, setStoredMapPositionValue] = useLocalStorage(
    "planningViewMapPosition",
    "right",
  );

  const isExpanded = storedExpandedValue === "true";

  const setIsExpanded = (value: boolean) => {
    setStoredExpandedValue(value ? "true" : "false");
  };

  const isMapShown = storedMapShownValue === "true";

  const setMapShown = (value: boolean) => {
    setStoredMapShownValue(value ? "true" : "false");
  };

  const isToPlanShown = storedToPlanShownValue === "true";

  const setToPlanShown = (value: boolean) => {
    setStoredToPlanShownValue(value ? "true" : "false");
  };

  const mapPosition = storedMapPositionValue;

  const setMapPosition = (value: "right" | "bottom") => {
    setStoredMapPositionValue(value);
  };

  return [
    isExpanded,
    setIsExpanded,
    isMapShown,
    setMapShown,
    isToPlanShown,
    setToPlanShown,
    mapPosition,
    setMapPosition,
  ] as const;
};
