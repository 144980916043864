import { PlainButton, type IPlainButton } from "@components/Button/Button";
import BinIcon from "@components/icons/BinIcon";
import {
  activityOccurrenceStatusSchema,
  type IActivityOccurrence,
} from "@models/activities";
import { useLingui } from "@lingui/react/macro";
import { useMutation } from "@tanstack/react-query";
import { removeActivityOccurrence } from "@/api/Activities";
import { displayErrorMessageAlert, deducedError } from "@/Utils/ErrorUtils";

export const RemoveActivityOccurrence = ({
  text,
  occurrence: { status, activityId, id },
  size = "small",
  onSuccess,
}: {
  text?: string;
  occurrence: Pick<IActivityOccurrence, "status" | "id" | "activityId">;
  size?: IPlainButton["size"];
  onSuccess: () => Promise<void>;
}) => {
  const { t } = useLingui();

  const { mutate: removeActivityOccurrenceMutation, isPending } = useMutation({
    mutationFn: ({
      activityId,
      occurrenceId,
    }: {
      activityId: string;
      occurrenceId: string;
    }) => {
      return removeActivityOccurrence(activityId, occurrenceId);
    },
    onError: (error) => {
      displayErrorMessageAlert(
        `${t`Gick inte att ta bort aktivitetstillfället`} ${deducedError(error)}`,
      );
    },
    onSuccess: async () => {
      await onSuccess();
    },
  });

  const STANDARD_REMOVE_TEXT = t`Ta bort`;
  const buttonText = text || STANDARD_REMOVE_TEXT;

  const activityOccurrenceIsRemovable = ({
    occurrenceStatus,
  }: {
    occurrenceStatus: string;
  }) => {
    return (
      occurrenceStatus !== activityOccurrenceStatusSchema.Values.ongoing ||
      window.confirm(
        t`Är du säker på att du vill ta bort en aktivitet i ett pågående besök?`,
      )
    );
  };

  return (
    <PlainButton
      size={size}
      weight="light"
      onClick={() =>
        activityOccurrenceIsRemovable({
          occurrenceStatus: status,
        })
          ? removeActivityOccurrenceMutation({
              activityId,
              occurrenceId: id,
            })
          : null
      }
      disabled={isPending}
    >
      <BinIcon />
      {buttonText}
    </PlainButton>
  );
};
