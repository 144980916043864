import { msg } from "@lingui/core/macro";
import type { MessageDescriptor } from "@lingui/core";
import z from "zod";
import type { IWeekday } from "@/api/Activities";

const frequencyOptions = ["oneTime", "recurring"] as const;
export const activityFrequencySchema = z.enum(frequencyOptions);
export type IActivityFrequency = z.infer<typeof activityFrequencySchema>;

export const activityFrequencyDictionary: Record<
  IActivityFrequency,
  MessageDescriptor
> = {
  oneTime: msg`Ett tillfälle`,
  recurring: msg`Återkommande`,
};

export const recurringOptions = [
  "everyDay",
  "every2ndDay",
  "onSelectedWeekdays",
  "custom",
] as const;
export const recurringOptionSchema = z.enum(recurringOptions);
export type IRecurringOption = z.infer<typeof recurringOptionSchema>;

export const recurringDictionary: Record<IRecurringOption, MessageDescriptor> =
  {
    everyDay: msg`Varje dag`,
    every2ndDay: msg`Varannan dag`,
    onSelectedWeekdays: msg`På utvalda veckodagar`,
    custom: msg`Anpassad`,
  };

export const recurringNumberDictionary: Record<number, IRecurringOption> = {
  1: recurringOptionSchema.Values.everyDay,
  2: recurringOptionSchema.Values.every2ndDay,
};

export const recurrenceToNumberDictionary: Record<IRecurringOption, 0 | 1 | 2> =
  {
    [recurringOptionSchema.Values.everyDay]: 1,
    [recurringOptionSchema.Values.every2ndDay]: 2,
    // Edge cases, don't really make sense.
    [recurringOptionSchema.Values.onSelectedWeekdays]: 0,
    [recurringOptionSchema.Values.custom]: 0,
  };

export const weekdayDictionary: Record<
  IWeekday,
  { short: MessageDescriptor; regular: MessageDescriptor }
> = {
  Monday: { short: msg`Mån`, regular: msg`Måndag` },
  Tuesday: { short: msg`Tis`, regular: msg`Tisdag` },
  Wednesday: { short: msg`Ons`, regular: msg`Onsdag` },
  Thursday: { short: msg`Tors`, regular: msg`Torsdag` },
  Friday: { short: msg`Fre`, regular: msg`Fredag` },
  Saturday: { short: msg`Lör`, regular: msg`Lördag` },
  Sunday: { short: msg`Sön`, regular: msg`Söndag` },
};

export const customRecurrenceIntervalSchema = z.string();
export type ICustomRecurrenceInterval = z.infer<
  typeof customRecurrenceIntervalSchema
>;

export const customRecurrenceIntervalUnitOptions = ["weeks", "days"] as const;
export const customRecurrenceIntervalUnitSchema = z.enum(
  customRecurrenceIntervalUnitOptions,
);
export type ICustomRecurrenceIntervalUnit = z.infer<
  typeof customRecurrenceIntervalUnitSchema
>;

export const timespanSchema = z.string().regex(/^\d{2}:\d{2}:\d{2}$/);
export type ITimespan = z.infer<typeof timespanSchema>;
