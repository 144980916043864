import WarningTriangleIcon from "@components/icons/WarningTriangleIcon";
import Chip, { type IChip } from "./Chip";

type IWarningChip = Pick<IChip, "size" | "children">;

const WarningChip = ({ children, size = "medium" }: IWarningChip) => {
  return (
    <Chip
      iconStart={<WarningTriangleIcon />}
      border="solid"
      background="solid"
      color="warning"
      shape="sharp"
      size={size}
    >
      {children}
    </Chip>
  );
};

export default WarningChip;
