import { Text, type IText } from "@components/Text/Text";

/**
 * Use **padding** sparingly, prefer to use padding of parent (layout) component
 */
const NoResults = ({
  message,
  padding = 0,
  size,
}: {
  message: string;
  padding?: number;
} & Pick<IText, "size">) => {
  return (
    <Text
      element="p"
      aria-live="polite"
      style={{ padding: padding }}
      size={size}
      color="faded"
    >
      {message}
    </Text>
  );
};

export default NoResults;
