import { createContext, useContext } from "react";
import type { ISelectedItem } from "./ShiftsRow";
import type { IActivityOccurrenceOrGroup } from "@models/activities";

export const PlanningContext = createContext<{
  draggedItem: IActivityOccurrenceOrGroup | undefined;
  setDraggedItem: (
    _draggedItem: IActivityOccurrenceOrGroup | undefined,
  ) => void;
  selectedItem: ISelectedItem | undefined;
  setSelectedItem: (_selectedItem: ISelectedItem | undefined) => void;
  boundsShowingAllPatients:
    | readonly [{ lat: number; lng: number }, { lat: number; lng: number }]
    | undefined;
  setBoundsShowingAllPatients: (
    _boundsShowingAllPatients:
      | readonly [{ lat: number; lng: number }, { lat: number; lng: number }]
      | undefined,
  ) => void;
}>({
  draggedItem: undefined,
  setDraggedItem: () => {},
  selectedItem: undefined,
  setSelectedItem: () => {},
  boundsShowingAllPatients: undefined,
  setBoundsShowingAllPatients: () => {},
});

export const usePlanningContext = () => useContext(PlanningContext);
