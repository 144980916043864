import { z } from "zod";
import {
  listExistingPatientSchema,
  patientTypesForInvoicingSchema,
} from "./patients";
import { medicalCompetenceSchema } from "./shifts";
import { timestampSchema } from "./date-and-time";

const invoicingCodeTypeOptions = ["procedureCode", "productCode"] as const;
export const invoicingCodeTypeOptionsSchema = z.enum(invoicingCodeTypeOptions);
export type IInvoicingCodeType = z.infer<typeof invoicingCodeTypeOptionsSchema>;

export const invoicingCodeSchema = z.object({
  id: z.string(),
  name: z.string(),
  code: z.string(),
  type: invoicingCodeTypeOptionsSchema,
  patientType: patientTypesForInvoicingSchema,
});
export type IInvoicingCode = z.infer<typeof invoicingCodeSchema>;

export const invoiceStatusSchema = z.enum([
  "awaitingApproval",
  "pending",
  "error",
  "completed",
  "notExecuted",
]);
export type IInvoiceStatus = z.infer<typeof invoiceStatusSchema>;

export type IOptionalInvoicingCodes = {
  procedureCodes?: string[];
  productCodes?: string[];
};

export const activityInvoicingCodesSchema = z
  .object({
    procedureCodes: z.array(invoicingCodeSchema),
    productCodes: z.array(invoicingCodeSchema),
  })
  .nullish();

export type IActivityInvoicingCodes = z.infer<
  typeof activityInvoicingCodesSchema
>;

export const occurrenceInvoicingCodesSchema = z
  .object({
    procedureCodes: z.array(invoicingCodeSchema),
    productCodes: z.array(invoicingCodeSchema),
  })
  .nullish();

export type IOccurrenceInvoicingCodes = z.infer<
  typeof occurrenceInvoicingCodesSchema
>;

export const invoicingActivityOccurrenceSchema = z.object({
  patientId: z.string().uuid(),
  title: z.string(),
  activityId: z.string().uuid(),
  occurrenceId: z.string(),
  shiftIds: z.array(z.number()),
  requiredCompetences: z.array(medicalCompetenceSchema),
  finishedAt: timestampSchema,
  procedureCodes: z.array(invoicingCodeSchema),
  productCodes: z.array(invoicingCodeSchema),
  duration: z.number(),
  status: invoiceStatusSchema,
  error: z.string().nullish(),
});
export type IInvoicingActivityOccurrence = z.infer<
  typeof invoicingActivityOccurrenceSchema
>;

export const invoicingGroupSchema = z.object({
  patientId: z.string().uuid(),
  groupId: z.string(),
  occurrences: z.array(invoicingActivityOccurrenceSchema),
});
export type IInvoicingGroup = z.infer<typeof invoicingGroupSchema>;

export const invoicingActivityOccurrenceWithPatientSchema =
  invoicingActivityOccurrenceSchema.extend({
    patient: listExistingPatientSchema,
  });
export type IInvoicingActivityOccurrenceWithPatient = z.infer<
  typeof invoicingActivityOccurrenceWithPatientSchema
>;

export const invoicingGroupWithPatientSchema = invoicingGroupSchema.extend({
  patient: listExistingPatientSchema,
});
export type IInvoicingGroupWithPatient = z.infer<
  typeof invoicingGroupWithPatientSchema
>;

export const invoicingActivityOccurrenceOrGroupSchema = z.union([
  invoicingActivityOccurrenceSchema,
  invoicingGroupSchema,
]);
export type IInvoicingActivityOccurrenceOrGroup = z.infer<
  typeof invoicingActivityOccurrenceOrGroupSchema
>;

export const invoicingActivityOccurrenceOrGroupWithPatientSchema = z.union([
  invoicingActivityOccurrenceWithPatientSchema,
  invoicingGroupWithPatientSchema,
]);
export type IInvoicingActivityOccurrenceOrGroupWithPatient = z.infer<
  typeof invoicingActivityOccurrenceOrGroupWithPatientSchema
>;
