import { t } from "@lingui/core/macro";
import { useActivityOccurrence } from "@/api/Activities";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import { Loading } from "@components/Loading/Loading";
import { deducedError } from "@/Utils/ErrorUtils";
import { Outlet, useNavigate, useParams } from "react-router";
import { z } from "zod";
import styles from "./ActivityOccurrence.module.scss";
import { Heading } from "@components/Heading/Heading";
import * as Sentry from "@sentry/react";
import { ActivityGroupTag } from "@/pages/commandcenter/Planning/TimelineTile/ActivityGroupTag/ActivityGroupTag";
import { ActivityTabs } from "./ActivityTabs";
import { IconButton } from "@components/Button/Button";
import CrossIcon from "@components/icons/CrossIcon";
import { patientStatusSchema } from "@models/patients";

const ActivityOccurrence = ({
  showCloseButton = true,
}: {
  showCloseButton?: boolean;
}) => {
  const { activityId, occurrenceId } = z
    .object({ activityId: z.string(), occurrenceId: z.string() })
    .parse(useParams());

  const navigate = useNavigate();

  const {
    data: activityOccurrence,
    isPending,
    isError,
    error,
  } = useActivityOccurrence(activityId, occurrenceId);

  if (isPending) {
    return <Loading message={t`Hämtar aktivitet`} padding={24} />;
  }

  if (isError) {
    Sentry.captureException(error);
    return <ErrorMessage message={deducedError(error)} padding={24} />;
  }

  return (
    <>
      <div className={styles.highlightedInfo}>
        {"patient" in activityOccurrence &&
        activityOccurrence.patient.status !==
          patientStatusSchema.Values.deleted ? (
          <ActivityGroupTag
            category={activityOccurrence.category}
            title={activityOccurrence.patient.name}
            patientId={activityOccurrence.patient.id}
          />
        ) : (
          <ActivityGroupTag category={activityOccurrence.category} />
        )}

        <Heading level="h2">{activityOccurrence.title}</Heading>
        {activityOccurrence.recurring ? <ActivityTabs /> : <Outlet />}
      </div>
      {showCloseButton ? (
        <div className={styles.closeButton}>
          <IconButton
            aria-label={t`Stäng formuläret`}
            onClick={() => navigate("..")}
          >
            <CrossIcon />
          </IconButton>
        </div>
      ) : null}
    </>
  );
};

export default ActivityOccurrence;
