import {
  type IActivityOccurrenceStatus,
  activityOccurrenceStatusSchema,
} from "@models/activities";

export const isUnfinishedOccurrence = <
  T extends { status: IActivityOccurrenceStatus },
>(
  occurrence: T,
): occurrence is T & {
  status: Exclude<IActivityOccurrenceStatus, "finished">;
} => occurrence.status !== activityOccurrenceStatusSchema.Values.finished;
