import type { IAddActivityFormData } from "@/forms/AddActivityForm/AddActivityForm";
import type { IPatientType } from "@models/patients";
import { unitSchema } from "@models/units";
import z from "zod";
import type { IUnitDefaultTemplateKey } from "./UnitDefault/templatesDefault";
import type { IUnitAlerisAhcTemplateKey } from "./UnitAlerisAhc/templatesAlerisAhc";
import type { IUnitSivNsvtTemplateKey } from "./UnitSivNsvt/templatesSivNsvt";

export const templateUnitsSchema = unitSchema;
export type ITemplateUnit = z.infer<typeof templateUnitsSchema>;

export type ITemplateKey =
  | IUnitDefaultTemplateKey
  | IUnitAlerisAhcTemplateKey
  | IUnitSivNsvtTemplateKey;

const _templateIdSchema = z.string().uuid();
const _templateRevisionSchema = z.number().int().positive();
export type ITemplateId = z.infer<typeof _templateIdSchema>;
export type ITemplateRevision = z.infer<typeof _templateRevisionSchema>;

export type IBaseTemplate = Pick<
  IAddActivityFormData,
  | "category"
  | "doubleStaffing"
  | "duration"
  | "recurrence"
  | "frequency"
  | "customRecurrenceInterval"
  | "customRecurrenceIntervalUnit"
  | "recurrencesPerDay"
  | "requiredCompetences"
  | "timeCategory"
  | "timeSensitivity"
  | "timeslots"
  | "hidden"
  | "weekdays"
  | "measurements"
> & {
  templateOptions: {
    showInList: boolean;
    patientTypes: IPatientType[];
    showNumberOfOccurrencesInHeader: boolean;
  };
  templateId: ITemplateId;
  templateRevision: ITemplateRevision;
  title: string;
  description: string | null;
};

export type IAnyTimeOfDayTemplate =
  // Omit is needed, because otherwise IAnyTimeOfDayTemplate contains a contradiction and becomes `never`
  Omit<IBaseTemplate, "timeslots" | "timeSensitivity"> & {
    // These properties should _never_ be set for AnyTimeOfDayTemplates
    timeslots?: never;
    timeSensitivity?: never;
    recurrencesPerDay: "1";
  };

// Must be a subset of IAddActivityFormData
export type ITemplate = IBaseTemplate | IAnyTimeOfDayTemplate;
