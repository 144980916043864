import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import styles from "./Patient.module.scss";
import { useContext, useEffect } from "react";
import { Outlet, useNavigate, useParams } from "react-router";
import { Loading } from "@components/Loading/Loading";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import {
  declinePatient,
  getPatientNameWithStatus,
  patientsWithUnhandledMeasurementsQueryOptions,
  usePatient,
} from "@/api/Patients";
import { Tab, TabList } from "@components/Tabs";
import { deducedError, displayErrorMessageAlert } from "@/Utils/ErrorUtils";
import { z } from "zod";
import { Heading } from "@components/Heading/Heading";
import CommunicationTab from "./CommunicationTab";
import { getLogisticsApiUrl, getNotificationApiUrl } from "@/Utils/EnvUtils";
import { useMutation, useQuery } from "@tanstack/react-query";
import { FilledButton, OutlinedButton } from "@components/Button/Button";
import { type IPatient } from "@models/patients";
import { Text } from "@components/Text/Text";
import { PersonalIdentityNumber } from "@components/PersonalIdentityNumber/PersonalIdentityNumber";
import * as Sentry from "@sentry/react";
import NotificationCircle from "@components/NotificationCircle/NotificationCircle";
import { knownFeatureFlagsSchema, useFeatureFlag } from "@/api/FeatureFlags";
import ChatContext from "@chat/ChatContext";
import { useUserProfile } from "@/api/Users";
import { isPatientSignedInQueryOptions } from "@/api/Auth";
import NotUsingAppChip from "@/components/Chips/NotUsingAppChip";

type PatientEvent = MessageEvent<{ type: string; patientId: string }>;

const AdmitOrCancel = ({ patient }: { patient: IPatient }) => {
  const patientName = getPatientNameWithStatus(patient);
  const navigate = useNavigate();
  const { mutate: declinePatientMutation } = useMutation({
    mutationFn: ({ patientId }: { patientId: string }) =>
      declinePatient(patientId),
    onError: (error) => {
      displayErrorMessageAlert(
        `${t`Gick inte att avslå ${patientName}.`} ${deducedError(error)}`,
      );
    },
    onSuccess: () => {
      alert(t`${patientName} avslogs`);
      navigate(`..`);
    },
  });

  return (
    <div className={styles.buttonGroup}>
      <OutlinedButton
        onClick={() => {
          // TODO: Remove when Lingui 5 drops: https://github.com/lingui/js-lingui/issues/370#issuecomment-2172480857
          const TWO_LINE_BREAKS = "\n\n";
          if (
            window.confirm(
              t`Är du säker på att du vill ge ${patientName} avslag?${TWO_LINE_BREAKS}OBS!${TWO_LINE_BREAKS}Säkerställ först att det inte finns några olästa chattnotiser från patienten.`,
            )
          ) {
            declinePatientMutation({ patientId: patient.id });
          }
        }}
      >
        <Trans>Avslå</Trans>
      </OutlinedButton>
      <FilledButton onClick={() => navigate("admit")}>
        <Trans>Fullfölj inskrivning</Trans>
      </FilledButton>
    </div>
  );
};

const Patient = () => {
  const { unit } = useUserProfile();
  const { chatEnabled } = useContext(ChatContext);
  const { patientId } = z.object({ patientId: z.string() }).parse(useParams());
  const { data: patient, isError, isPending, error } = usePatient(patientId);
  const { data: patientsWithUnhandledMeasurements } = useQuery(
    patientsWithUnhandledMeasurementsQueryOptions,
  );
  const { data: isPatientSignedIn, isPending: isPendingPatientSignedIn } =
    useQuery(isPatientSignedInQueryOptions(patientId));
  const hasUnhandledMeasurements = patientsWithUnhandledMeasurements?.find(
    ({ id, unhandledMeasurementsCount }) =>
      id === patientId && unhandledMeasurementsCount > 0,
  )
    ? true
    : false;
  const { data: patientNoteIsEnabled } = useFeatureFlag(
    knownFeatureFlagsSchema.Values.CarePatientNote,
  );
  useEffect(() => {
    const handleShowNotification = (event: PatientEvent) => {
      if (event.origin !== getLogisticsApiUrl(unit)) {
        return;
      }
      if (event.data.type !== "patientTabletAckMissing") {
        return;
      }
      if (event.data.patientId !== patient?.id) {
        return;
      }
      const patientName = patient?.name;
      new Notification(t`Misslyckades att notifiera patient ${patientName}`, {
        body: t`Det ringer inte hos ${patientName}. Pröva att ringa igen, eller kontakta patienten på annat sätt.`,
        requireInteraction: true,
      });
    };

    window.addEventListener("message", handleShowNotification);

    return () => {
      window.removeEventListener("message", handleShowNotification);
    };
  }, [patient?.name, patient?.id, unit]);

  if (isPending) {
    return <Loading message={t`Laddar patientdetaljer`} padding={24} />;
  }

  if (isError) {
    Sentry.captureException(error);
    return (
      <ErrorMessage
        message={`${t`Kunde inte hämta patientdetaljer.`} ${deducedError(error)}`}
        padding={24}
      />
    );
  }

  return (
    <>
      {/* This iframe is used to setup an SSE subscription to notifications from the Backend. 
          We can then act on these events, for example, showing an error message when the patient-tablet 
          has not received a notification for 'video-call-started'. */}
      <iframe
        style={{ display: "none" }}
        title="Patient notifications"
        src={`${getNotificationApiUrl(unit)}/htmx/patient/${patientId}/sse`}
      />

      <header className={styles.header}>
        <div className={styles.topRow}>
          <Heading level="h1">{getPatientNameWithStatus(patient)}</Heading>
          <Text element="div" size="large">
            <PersonalIdentityNumber
              personalIdentityNumber={patient.personalIdentityNumber}
              personalIdentityNumberType={patient.personalIdentityNumberType}
            />
          </Text>
          {isPendingPatientSignedIn || isPatientSignedIn ? null : (
            <NotUsingAppChip />
          )}
          {patient.status === "prospect" ? (
            <AdmitOrCancel patient={patient} />
          ) : (
            <></>
          )}
        </div>
        <TabList>
          <Tab to="activities">
            <Trans>Aktiviteter</Trans>
          </Tab>
          <Tab to="measurements">
            <NotificationCircle
              isVisible={hasUnhandledMeasurements}
              placement="right"
            >
              <Trans>Mätvärden</Trans>
            </NotificationCircle>
          </Tab>
          {chatEnabled ? <CommunicationTab patientId={patient.id} /> : <></>}
          <Tab to="information">
            <Trans>Information</Trans>
          </Tab>
          <Tab to="authentication">
            <Trans>Inloggning</Trans>
          </Tab>
          {patientNoteIsEnabled ? (
            <Tab to="patient-note">
              <Trans>Patientanteckning</Trans>
            </Tab>
          ) : (
            <></>
          )}
          <Tab to="discharge">
            <Trans>Utskrivning</Trans>
          </Tab>
        </TabList>
      </header>

      <div className={styles.content}>
        <Outlet context={patient} />
      </div>
    </>
  );
};

export default Patient;
