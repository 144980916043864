import { t } from "@lingui/core/macro";
import { addDays, subDays } from "date-fns";
import { IconButton, PlainButton } from "@components/Button/Button";
import CaretLeftIcon from "@components/icons/CaretLeftIcon";
import type { IHeading } from "@components/Heading/Heading";
import { Heading } from "@components/Heading/Heading";
import CaretRightIcon from "@components/icons/CaretRightIcon";
import styles from "./DaySwitcher.module.scss";
import { dateName } from "@/Utils/DateUtils";
import { SelectedDateContext, useSelectedDate } from "@/Utils/useSelectedDate";
import { format } from "@models/date-and-time";
import { useContext } from "react";
import { Trans } from "@lingui/react/macro";

export const DaySwitcher = ({ size = "h1" }: Pick<IHeading, "size">) => {
  const selectedDate = new Date(useSelectedDate());
  const setContext = useContext(SelectedDateContext)?.setSelectedDate;
  const setSelectedDate = (dateTime: Date) => {
    setContext?.(format(dateTime, "yyyy-MM-dd"));
  };
  const goToPreviousDay = () => setSelectedDate(subDays(selectedDate, 1));
  const goToNextDay = () => setSelectedDate(addDays(selectedDate, 1));
  const goToToday = () => setSelectedDate(new Date());

  return (
    <div className={styles.dayToggle}>
      <PlainButton onClick={goToToday}>
        <Trans>Idag</Trans>
      </PlainButton>
      <IconButton
        aria-label={t`Visa aktiviteter för föregående dag`}
        onClick={goToPreviousDay}
      >
        <CaretLeftIcon />
      </IconButton>
      <IconButton
        aria-label={t`Visa aktiviteter för nästa dag`}
        onClick={goToNextDay}
      >
        <CaretRightIcon />
      </IconButton>
      <Heading level="h2" size={size}>
        {dateName(selectedDate)}
      </Heading>
    </div>
  );
};
