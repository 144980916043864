import {
  type IInvoiceStatus,
  type IInvoicingActivityOccurrenceOrGroupWithPatient,
  type IInvoicingCode,
} from "@models/invoicing";
import type { IMedicalCompetence } from "@models/shifts";

const getDataFromInvoicingOccurrencesAndGroups = (
  invoicingOccurrencesAndGroups: IInvoicingActivityOccurrenceOrGroupWithPatient[],
) => {
  return invoicingOccurrencesAndGroups.map((occurrenceOrGroup) => {
    if ("occurrences" in occurrenceOrGroup) {
      const group = occurrenceOrGroup;
      const uniqueCompetencesMap = new Map<string, IMedicalCompetence>();
      const uniqueProductCodesMap = new Map<string, IInvoicingCode>();
      const uniqueProcedureCodesMap = new Map<string, IInvoicingCode>();
      const occurrenceTitles: {
        occurrenceId: string;
        activityId: string;
        title: string;
        status: IInvoiceStatus;
        procedureCodes: IInvoicingCode[];
        productCodes: IInvoicingCode[];
      }[] = [];

      for (const occurrence of group.occurrences) {
        for (const competence of occurrence.requiredCompetences) {
          if (!uniqueCompetencesMap.has(competence)) {
            uniqueCompetencesMap.set(competence, competence);
          }
        }
        for (const productCode of occurrence.productCodes) {
          if (!uniqueProductCodesMap.has(productCode.code)) {
            uniqueProductCodesMap.set(productCode.code, productCode);
          }
        }
        for (const procedureCode of occurrence.procedureCodes) {
          if (!uniqueProcedureCodesMap.has(procedureCode.code)) {
            uniqueProcedureCodesMap.set(procedureCode.code, procedureCode);
          }
        }
        occurrenceTitles.push({
          occurrenceId: occurrence.occurrenceId,
          activityId: occurrence.activityId,
          title: occurrence.title,
          status: occurrence.status,
          procedureCodes: occurrence.procedureCodes,
          productCodes: occurrence.productCodes,
        });
      }

      const uniqueCompetences = Array.from(uniqueCompetencesMap.values());
      const uniqueProductCodes = Array.from(uniqueProductCodesMap.values());
      const uniqueProcedureCodes = Array.from(uniqueProcedureCodesMap.values());

      return {
        patient: { id: group.patient.id, name: group.patient.name },
        personalIdentityNumber: group.patient.personalIdentityNumber,
        patientType: group.patient.patientType,
        competences: uniqueCompetences,
        finishedAt: group.occurrences[0]?.finishedAt,
        title: occurrenceTitles,
        diagnosisCode: group.patient.diagnosisCode,
        procedureCodes: uniqueProcedureCodes,
        productCodes: uniqueProductCodes,
        // If there is an error, show the first
        comment: group.occurrences.find(({ error }) => error)?.error,
      };
    } else {
      const occurrence = occurrenceOrGroup;
      return {
        patient: { id: occurrence.patient.id, name: occurrence.patient.name },
        personalIdentityNumber: occurrence.patient.personalIdentityNumber,
        patientType: occurrence.patient.patientType,
        competences: occurrence.requiredCompetences,
        finishedAt: occurrence.finishedAt,
        title: [
          {
            occurrenceId: occurrence.occurrenceId,
            activityId: occurrence.activityId,
            title: occurrence.title,
            status: occurrence.status,
            procedureCodes: occurrence.procedureCodes,
            productCodes: occurrence.productCodes,
          },
        ],
        diagnosisCode: occurrence.patient.diagnosisCode,
        procedureCodes: occurrence.procedureCodes,
        productCodes: occurrence.productCodes,
        comment: occurrence.error,
      };
    }
  });
};

export { getDataFromInvoicingOccurrencesAndGroups };
