/* eslint-disable lingui/no-unlocalized-strings */
// Don't translate user-generated content
const homeVisitSuggestions = [
  `Apodos/uppstart`,
  `Bensvullnad`,
  `Bladderscan`,
  `Hämtning tekniklåda`,
  `Kost och vätskegenomgång`,
  `Leverans läkemedel`,
  `Leverans materiallåda`,
  `Lungor`,
  `Lämna läkemedelslista`,
  `Läkemedelshantering`,
  `Läkemedelsgenomgång`,
  `Nyckelkvittens`,
  `Provtagning`,
  `Social uppföljning`,
  `Urvätskning`,
  `Vitalparametrar`,
] as const;

const videoCallSuggestions = [
  `Digitalt besök`,
  `Rond`,
  `Uppföljande samtal`,
] as const;

const patientTaskSuggestions = [`Ta medicin`, `Vätskelista`] as const;

const adminTaskSuggestions = [
  `Bevaka provsvar`,
  `Bilvård`,
  `Dosettdelning`,
  `Information till patient`,
  `Personalmöte`,
  `Remiss`,
  `Slutanteckning`,
  `Telefonkontakt NSVT`,
  `Telefonkontakt patient`,
  `Uppdatera läkemedelslista/apodos`,
  `Uppföljande samtal`,
  `Veckoavstämning`,
] as const;

const patientMeasurementTaskSuggestions = [
  `Egen mätning`,
  `Egen mätning morgon`,
  `Egen mätning kväll`,
  `Egen mätning andningsfrekvens`,
  `Egen mätning blodsocker`,
  `Egen mätning puls`,
  `Egen mätning syremättnad`,
  `Egen mätning temperatur`,
  `Egen mätning vikt`,
] as const;

export const titleSuggestions = {
  homeVisitSuggestions,
  videoCallSuggestions,
  patientTaskSuggestions,
  adminTaskSuggestions,
  patientMeasurementTaskSuggestions,
};
