import type { MapRef, ViewState } from "react-map-gl/mapbox";
import MapboxMap, { FullscreenControl } from "react-map-gl/mapbox";
import { useMemo, useRef } from "react";
import { AnnotationPanel } from "./AnnotationPanel";
import { MAPBOX_PUBLIC_ACCESS_TOKEN } from "@/Utils/EnvUtils";

export type ICoordinatePair = { latitude: number; longitude: number };
export type IMapState = Omit<ViewState, "longitude" | "latitude"> & {
  coordinates: ICoordinatePair;
};
interface IMap {
  annotation?: React.ReactNode;
  disabled?: boolean;
  id: string;
  initialAreaToShow:
    | {
        bounds: readonly [
          { lat: number; lng: number },
          { lat: number; lng: number },
        ];
      }
    | { center: { lat: number; lng: number }; zoom: number };
  children?: React.ReactNode;
  style: Omit<React.CSSProperties, "minHeight" | "minWidth" | "opacity"> & {
    height: string;
  };
}

export const MAP_PADDING = 100;

const Map = ({
  annotation,
  disabled,
  id,
  initialAreaToShow,
  children,
  style,
}: IMap) => {
  // Disable map handlers if map is disabled. Solution in the spirit of https://stackoverflow.com/a/38774303/4102048
  const adjustedHandlers = disabled
    ? {
        boxZoom: false,
        doubleClickZoom: false,
        dragRotate: false,
        dragPan: false,
        keyboard: false,
        scrollZoom: false,
        touchPitch: false,
        touchZoomRotate: false,
      }
    : {};

  const mapRef = useRef<MapRef>(null);

  const initialViewState = useMemo(() => {
    if ("bounds" in initialAreaToShow) {
      const { bounds } = initialAreaToShow;
      const lngDiff = Math.abs(bounds[0].lng - bounds[1].lng);
      const latDiff = Math.abs(bounds[0].lat - bounds[1].lat);
      const boundsWithPadding = [
        bounds[0].lng - lngDiff * 0.2,
        bounds[0].lat - latDiff * 0.2,
        bounds[1].lng + lngDiff * 0.2,
        bounds[1].lat + latDiff * 0.2,
      ] as [number, number, number, number];
      return {
        bounds: boundsWithPadding,
      };
    } else {
      const { center, zoom } = initialAreaToShow;
      return {
        longitude: center.lng,
        latitude: center.lat,
        zoom,
        bearing: 0,
        pitch: 0,
        padding: {
          top: MAP_PADDING,
          left: MAP_PADDING,
          right: MAP_PADDING,
          bottom: MAP_PADDING,
        },
      };
    }
  }, [initialAreaToShow]);

  return (
    <MapboxMap
      reuseMaps
      onError={(error) => console.error("Map error", error)}
      id={id}
      mapboxAccessToken={MAPBOX_PUBLIC_ACCESS_TOKEN}
      initialViewState={initialViewState}
      mapStyle="mapbox://styles/mapbox/streets-v11"
      style={{
        ...style,
        opacity: disabled ? 0.5 : 1,
        minHeight: `${MAP_PADDING * 2 + 50}px`,
        minWidth: `${MAP_PADDING * 2 + 50}px`,
      }}
      minZoom={7}
      {...adjustedHandlers}
      ref={mapRef}
    >
      {children}
      {annotation ? <AnnotationPanel>{annotation}</AnnotationPanel> : null}
      {!disabled ? <FullscreenControl /> : null}
    </MapboxMap>
  );
};

export default Map;
