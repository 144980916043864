import { Trans } from "@lingui/react/macro";
import type { ITimeSlotsPickerFields } from "@/forms/AddActivityForm/Scheduling/TimeSlotsPicker";
import { TimeSlotsPicker } from "@/forms/AddActivityForm/Scheduling/TimeSlotsPicker";
import { useParams } from "react-router";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import z from "zod";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  addActivitiesFromGroupTemplate,
  type INewActivity,
} from "@/api/Activities";
import { ExpansionPanel } from "@components/ExpansionPanel/ExpansionPanel";
import styles from "../ActivityTemplatesSivNsvt.module.scss";
import Form from "@/components/Form/Form";
import { FilledButton } from "@components/Button/Button";
import Checkboxes from "@/components/Checkbox/Checkboxes";
import Checkbox from "@/components/Checkbox/Checkbox";
import { deducedError } from "@/Utils/ErrorUtils";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import type { IDateInputFields } from "@/forms/AddActivityForm/Scheduling/DateInput";
import { DateInput } from "@/forms/AddActivityForm/Scheduling/DateInput";
import { medicalCompetenceSchema } from "@models/shifts";
import {
  templates,
  unitSivNsvtGroupTemplateKeysSchema,
} from "@/pages/commandcenter/Patients/Patient/Activities/AddActivity/Templates/UnitSivNsvt/templatesSivNsvt";
import { newActivityFromTemplateSivNsvt } from "@/pages/commandcenter/Patients/Patient/Activities/AddActivity/Templates/UnitSivNsvt/newActivityFromTemplateSivNsvt";
import { GroupTemplateHeadingDefault } from "@/pages/commandcenter/Patients/Patient/Activities/AddActivity/Templates/UnitDefault/TemplateHeadingDefault";
import { TemplateHeadingSivNsvt } from "@/pages/commandcenter/Patients/Patient/Activities/AddActivity/Templates/UnitSivNsvt/TemplateHeadingSivNsvt";
import {
  templateUnitsSchema,
  type ITemplateId,
  type ITemplateRevision,
} from "../../templateModels";
import { generateTemplateId } from "../../templateUtils";

/**
 * Admission Template Id and revision number
 * NEVER change the Id.
 * Increment revision number when you make changes to the template.
 */
const TEMPLATE_ID: ITemplateId = await generateTemplateId({
  unit: templateUnitsSchema.Values.sivnsvt,
  templateTitle: unitSivNsvtGroupTemplateKeysSchema.Values.otherVisit,
});
const TEMPLATE_REVISION: ITemplateRevision = 1;

const otherActivities = {
  medicationReview: templates.medicationReview,
  socialFollowUp: templates.socialFollowUp,
  vitalParameters: templates.vitalParameters,
  bloodSampleOneTime: templates.bloodSampleOneTime,
  handoverMedicationList: templates.handoverMedicationList,
};

export const OtherVisitTemplateSivNsvt = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { patientId } = z.object({ patientId: z.string() }).parse(useParams());
  const queryClient = useQueryClient();

  const methods = useForm<
    ITimeSlotsPickerFields &
      Pick<IDateInputFields, "startDate"> & {
        bloodSampleOneTime: boolean;
        medicationReview: boolean;
        socialFollowUp: boolean;
        vitalParameters: boolean;
        handoverMedicationList: boolean;
      }
  >({
    defaultValues: {
      ...templates.otherVisit,
      bloodSampleOneTime: false,
      medicationReview: false,
      socialFollowUp: false,
      vitalParameters: false,
      handoverMedicationList: false,
    },
  });

  const {
    formState: { errors, isDirty },
    getValues,
    handleSubmit,
    register,
    reset,
    setError,
  } = methods;

  const { mutate, isPending } = useMutation({
    mutationFn: async (activities: INewActivity[]) => {
      await addActivitiesFromGroupTemplate({
        templateId: TEMPLATE_ID,
        templateRevision: TEMPLATE_REVISION,
        newActivities: activities,
      });
    },
    onError: (error) => {
      setError("root.server", {
        message: deducedError(error),
      });
      // Reset `isDirty` to support only showing server error when the form is not changed.
      reset(getValues(), {
        keepErrors: true,
        keepIsSubmitted: true,
        keepTouched: true,
        keepIsValid: true,
        keepSubmitCount: true,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries();
      setIsExpanded(false);
    },
  });

  const validateAndSubmit = handleSubmit((formData) => {
    const {
      startDate,
      recurrencesPerDay,
      timeCategory,
      timeslots,
      timeSensitivity,
      socialFollowUp,
      medicationReview,
      bloodSampleOneTime,
      vitalParameters,
      handoverMedicationList,
    } = formData;

    const timeConfig = {
      recurrencesPerDay,
      timeCategory,
      timeslots,
      timeSensitivity,
    };

    const activities: INewActivity[] = [];

    if (socialFollowUp) {
      activities.push(
        newActivityFromTemplateSivNsvt({
          template: {
            ...otherActivities.socialFollowUp,
            ...timeConfig,
            requiredCompetences: [
              medicalCompetenceSchema.Values.NursePractitioner,
            ],
          },
          startDate,
          patientId,
        }),
      );
    }
    if (bloodSampleOneTime) {
      activities.push(
        newActivityFromTemplateSivNsvt({
          template: {
            ...otherActivities.bloodSampleOneTime,
            ...timeConfig,
            requiredCompetences: [
              medicalCompetenceSchema.Values.NursePractitioner,
            ],
          },
          startDate,
          patientId,
        }),
      );
    }
    if (medicationReview) {
      activities.push(
        newActivityFromTemplateSivNsvt({
          template: {
            ...otherActivities.medicationReview,
            ...timeConfig,
            requiredCompetences: [
              medicalCompetenceSchema.Values.NursePractitioner,
            ],
          },
          startDate,
          patientId,
        }),
      );
    }
    if (vitalParameters) {
      activities.push(
        newActivityFromTemplateSivNsvt({
          template: {
            ...otherActivities.vitalParameters,
            ...timeConfig,
            requiredCompetences: [
              medicalCompetenceSchema.Values.NursePractitioner,
            ],
          },
          startDate,
          patientId,
        }),
      );
    }
    if (handoverMedicationList) {
      activities.push(
        newActivityFromTemplateSivNsvt({
          template: {
            ...otherActivities.handoverMedicationList,
            ...timeConfig,
            requiredCompetences: [
              medicalCompetenceSchema.Values.NursePractitioner,
            ],
          },
          startDate,
          patientId,
        }),
      );
    }

    mutate(activities);
  });

  return (
    <ExpansionPanel
      // Don't translate user-generated content
      // eslint-disable-next-line lingui/no-unlocalized-strings
      trigger={<GroupTemplateHeadingDefault title={`Övrigt besök`} />}
      // Don't translate user-generated content
      // eslint-disable-next-line lingui/no-unlocalized-strings
      triggerAriaLabel={`Övrigt besök`}
      isExpanded={isExpanded}
      setIsExpanded={setIsExpanded}
    >
      <div className={styles.content}>
        <FormProvider {...methods}>
          <Form onSubmit={validateAndSubmit}>
            <div>
              <TemplateHeadingSivNsvt
                template={{
                  ...templates.otherVisit,
                  requiredCompetences: [
                    medicalCompetenceSchema.Values.NursePractitioner,
                  ],
                }}
              />
              <div className={styles.dayAndTime}>
                <DateInput hasEndDate={false} />
                <TimeSlotsPicker isRecurring={false} />
              </div>
              <div className={styles.checkboxes}>
                <Checkboxes>
                  <Checkbox
                    errorMessage={errors.socialFollowUp?.message}
                    label={{
                      text: otherActivities.socialFollowUp.title,
                    }}
                    {...register("socialFollowUp")}
                  />
                  <Checkbox
                    errorMessage={errors.medicationReview?.message}
                    label={{
                      text: otherActivities.medicationReview.title,
                    }}
                    {...register("medicationReview")}
                  />
                  <Checkbox
                    errorMessage={errors.vitalParameters?.message}
                    label={{
                      text: otherActivities.vitalParameters.title,
                    }}
                    {...register("vitalParameters")}
                  />
                  <Checkbox
                    errorMessage={errors.handoverMedicationList?.message}
                    label={{
                      text: otherActivities.handoverMedicationList.title,
                    }}
                    {...register("handoverMedicationList")}
                  />
                  <Checkbox
                    errorMessage={errors.bloodSampleOneTime?.message}
                    label={{
                      text: otherActivities.bloodSampleOneTime.title,
                    }}
                    {...register("bloodSampleOneTime")}
                  />
                </Checkboxes>
              </div>
            </div>
            {errors.root?.server?.message && !isDirty ? (
              <ErrorMessage
                message={errors.root.server.message}
                weight="bold"
              />
            ) : null}
            <FilledButton type="submit" disabled={isPending}>
              <Trans>Lägg till aktiviteter</Trans>
            </FilledButton>
          </Form>
        </FormProvider>
      </div>
    </ExpansionPanel>
  );
};
