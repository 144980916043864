import { t } from "@lingui/core/macro";
import { useFormContext } from "react-hook-form";
import { useLingui } from "@lingui/react";
import styles from "./CustomRecurringInput.module.scss";
import { Text } from "@components/Text/Text";
import { Plural, Trans } from "@lingui/react/macro";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import InputField from "@/components/InputField/InputField";
import Select from "@/components/Select/Select";
import {
  customRecurrenceIntervalUnitSchema,
  type ICustomRecurrenceInterval,
  type ICustomRecurrenceIntervalUnit,
} from "./schedulingModels";

export type ICustomRecurringIntervalFields = {
  customRecurrenceInterval: ICustomRecurrenceInterval | null;
  customRecurrenceIntervalUnit: ICustomRecurrenceIntervalUnit | null;
};

export const CustomRecurringInterval = () => {
  const {
    formState: { errors },
    register,
    watch,
  } = useFormContext<ICustomRecurringIntervalFields>();

  const { _ } = useLingui();

  const interval = watch("customRecurrenceInterval");

  return (
    <fieldset className={styles.fieldset}>
      <legend className={styles.legend}>
        <Trans>Anpassad upprepning</Trans>
      </legend>
      {errors.customRecurrenceInterval?.message ? (
        <div className={styles.errorMessage}>
          <ErrorMessage
            message={errors.customRecurrenceInterval.message}
            weight="bold"
          />
        </div>
      ) : null}
      {errors.customRecurrenceIntervalUnit?.message ? (
        <div className={styles.errorMessage}>
          <ErrorMessage
            message={errors.customRecurrenceIntervalUnit.message}
            weight="bold"
          />
        </div>
      ) : null}
      <div className={styles.inputWrapper}>
        <Text element="div">
          <Trans>Var</Trans>
        </Text>
        <InputField
          label={t`Upprepningsintervall`}
          hideLabel
          showOptionalLabel={false}
          type="number"
          outerWidth="fit"
          {...register("customRecurrenceInterval", {
            required: {
              value: true,
              message: t`Upprepningsintervall måste sättas`,
            },
            min: {
              value: 1,
              message: t`Upprepningsintervall måste vara minst 1`,
            },
          })}
        />
        <Select
          label={t`Enhet för upprepningsintervall`}
          hideLabel
          {...register("customRecurrenceIntervalUnit", {
            required: {
              value: true,
              message: t`Välj en enhet för upprepningsintervallet`,
            },
          })}
        >
          <option value={customRecurrenceIntervalUnitSchema.Values.weeks}>
            <Plural
              value={interval ? interval : "0"}
              _1="vecka"
              other="vecka"
            />
          </option>
          <option value={customRecurrenceIntervalUnitSchema.Values.days}>
            <Plural value={interval ? interval : "0"} _1="dag" other="dag" />
          </option>
        </Select>
      </div>
    </fieldset>
  );
};
