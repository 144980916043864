import { useEmployees } from "@/api/Employee";
import {
  assignEmployeeToShift,
  shiftQueryOptions,
  removeAssignedEmployeeFromShift,
  NO_EMPLOYEE,
  shiftKeys,
} from "@/api/Shifts";
import { Trans, useLingui } from "@lingui/react/macro";
import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import { z } from "zod";
import styles from "./ShiftDetails.module.scss";
import { CompetenceChip } from "@/components/Chips/CompetenceChip";
import { ShiftDisplayName } from "./ShiftsDisplayName";
import Form from "@/components/Form/Form";
import { Loading } from "@components/Loading/Loading";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import Select from "@/components/Select/Select";
import { FilledButton, PlainButton } from "@components/Button/Button";
import { Heading } from "@components/Heading/Heading";
import { useState } from "react";
import { ShiftTime } from "@/components/Time/ShiftTime";
import { deducedError, displayErrorMessageAlert } from "@/Utils/ErrorUtils";
import type { IScheduledShift } from "@models/shifts";
import * as Sentry from "@sentry/react";

export const MOCK_WAIT_TIME_FOR_SCHEDULING_SYSTEM_UPDATE_1 = 7000;
export const MOCK_WAIT_TIME_FOR_SCHEDULING_SYSTEM_UPDATE_2 = 3000;

const useShiftIdFromUrl = () => {
  const { shiftId: shiftIdAsString } = z
    .object({ shiftId: z.string() })
    .parse(useParams());
  return parseInt(shiftIdAsString, 10);
};

const ShiftSwap = ({ shift }: { shift: IScheduledShift }) => {
  const { t } = useLingui();
  const shiftId = useShiftIdFromUrl();
  const { data: employees, isPending, isError } = useEmployees();
  const { register, handleSubmit, watch } = useForm<{
    scheduledEmployeeId: number;
  }>();

  const [isChangingEmployee, setIsChangingEmployee] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState<string | null>(null);

  const queryClient = useQueryClient();

  const { mutate, isPending: isChangingShiftAssignment } = useMutation({
    mutationFn: ({
      employeeId,
    }: Parameters<typeof assignEmployeeToShift>[0]) => {
      if (NO_EMPLOYEE) {
        setLoadingMessage(t`Tar bort medarbetare`);
        return removeAssignedEmployeeFromShift({ shiftId });
      }
      setLoadingMessage(t`Byter medarbetare`);
      return assignEmployeeToShift({ employeeId, shiftId });
    },
    onSuccess: () => {
      // Keep the user waiting for a while for scheduling system to update
      // and new data to be fetched
      // Most of the time, 10 seconds will do it. Sometimes, the data will not have
      // been updated by then, but this is a rare case and the user can just refresh
      // the page if they need to.
      setLoadingMessage(t`Uppdaterar schema`);
      setTimeout(() => {
        setLoadingMessage(t`Hämtar nytt schema`);
        setTimeout(() => {
          setLoadingMessage(null);
          setIsChangingEmployee(false);
          queryClient.invalidateQueries({ queryKey: shiftKeys.all });
        }, MOCK_WAIT_TIME_FOR_SCHEDULING_SYSTEM_UPDATE_2);
      }, MOCK_WAIT_TIME_FOR_SCHEDULING_SYSTEM_UPDATE_1);
    },
    onError: (error) => {
      setLoadingMessage(null);
      displayErrorMessageAlert(
        `${t`Gick inte att genomföra ändringen.`} ${deducedError(error)}`,
      );
    },
  });

  const disableButtons = isChangingShiftAssignment || loadingMessage !== null;

  return (
    <>
      <div className={styles.employeeNameAndCompetence}>
        {shift.employee ? (
          <ShiftDisplayName
            assigned={Boolean(shift.employee)}
            employeeName={`${shift.employee.firstName} ${shift.employee.lastName}`}
          />
        ) : (
          <ShiftDisplayName assigned={false} />
        )}
      </div>
      <div className={styles.actions}>
        {isChangingEmployee ? (
          <PlainButton
            size="small"
            onClick={() => setIsChangingEmployee(false)}
            disabled={disableButtons}
          >
            <Trans>Avbryt</Trans>
          </PlainButton>
        ) : (
          <PlainButton
            size="small"
            onClick={() => setIsChangingEmployee(true)}
            disabled={disableButtons}
          >
            {shift.employee ? <Trans>Ändra</Trans> : <Trans>Lägg till</Trans>}
          </PlainButton>
        )}
      </div>
      {isChangingEmployee ? (
        <div className={styles.editContainer}>
          <Form
            onSubmit={handleSubmit((validatedFormData) => {
              mutate({
                employeeId: validatedFormData.scheduledEmployeeId,
                shiftId,
              });
            })}
          >
            {isPending ? (
              <Loading message={t`Hämtar tillgängliga medarbetare`} />
            ) : null}
            {isError ? (
              <ErrorMessage
                message={t`Kunde inte hämta tillgängliga medarbetare`}
              />
            ) : null}
            {loadingMessage ? (
              <Loading message={loadingMessage} />
            ) : (
              <Select
                label={t`Välj medarbetare`}
                {...register("scheduledEmployeeId")}
              >
                <option value={0}>
                  <Trans>Ingen</Trans>
                </option>
                {employees?.map((employee) => {
                  return (
                    <option value={employee.id} key={employee.id}>
                      {`${employee.firstName} ${employee.lastName}`}
                    </option>
                  );
                })}
              </Select>
            )}
            {watch("scheduledEmployeeId") === shift.employee?.id ? null : (
              <FilledButton disabled={disableButtons} type="submit">
                <Trans>Spara ändringar</Trans>
              </FilledButton>
            )}
          </Form>
        </div>
      ) : null}
    </>
  );
};

export const ShiftDetails = () => {
  const { t } = useLingui();
  const shiftId = useShiftIdFromUrl();
  const {
    data: shift,
    isPending,
    isError,
    error,
  } = useQuery(shiftQueryOptions({ shiftId }));

  if (isPending) {
    return <Loading message={t`Hämtar arbetspass`} />;
  }

  if (isError) {
    Sentry.captureException(error);
    return <ErrorMessage message={t`Gick inte att hämta arbetspasset.`} />;
  }

  return (
    <article className={styles.detailsContainer} key={shift.id}>
      <Heading level="h1">
        <Trans>Hantera</Trans>
      </Heading>

      <section>
        <Heading level="h2" margin="bottom">
          <Trans>Arbetspass</Trans>
        </Heading>

        <dl className={styles.dataList}>
          <dt>
            <Trans>Kompetens</Trans>
          </dt>
          <dd>
            <CompetenceChip
              competence={shift.competence}
              state="neutral"
              variant="long"
              size="medium"
            />
          </dd>
          <dt>
            <Trans>Tid</Trans>
          </dt>
          <dd>
            <ShiftTime
              startDateTime={shift.startDateTime}
              endDateTime={shift.endDateTime}
            />
          </dd>
          <dt>
            <Trans context="singular">Medarbetare</Trans>
          </dt>
          <dd>
            <ShiftSwap shift={shift} />
          </dd>
        </dl>
      </section>
    </article>
  );
};
