import {
  isGroup as isGroupCheck,
  timeOfDaySchema,
  type IActivityOccurrenceOrGroupWithPatient,
} from "@models/activities";
import styles from "./ActivityCalendarItem.module.scss";
import { CategoryIcon } from "@/components/CategoryIcon/CategoryIcon";
import { Text } from "@components/Text/Text";
import { formattedTime } from "@/components/Time/timeUtils";
import { UnstyledLink } from "@components/Link/Link";
import { useParams } from "react-router";
import clsx from "clsx";

type IActivityCalendarItem = {
  activityOccurrenceOrGroup: IActivityOccurrenceOrGroupWithPatient;
};

const ActivityCalendarItem = ({
  activityOccurrenceOrGroup,
}: IActivityCalendarItem) => {
  const isGroup = isGroupCheck(activityOccurrenceOrGroup);
  const params = useParams();

  const activeItem = isGroup ? params.groupId : params.occurrenceId;
  const activeSeriesItem = isGroup ? undefined : params.activityId;

  const Title = () => {
    if (isGroup) {
      return (
        <div className={styles.groupTitle}>
          <Text element="span" size="smallest">
            {activityOccurrenceOrGroup.occurrences
              .map((occurrence) => occurrence.title)
              .join(", ")}
          </Text>
          <div className={styles.occurrenceCount}>
            {activityOccurrenceOrGroup.occurrences.length}
          </div>
        </div>
      );
    } else {
      return (
        <Text element="span" size="smallest">
          {activityOccurrenceOrGroup.title}
        </Text>
      );
    }
  };

  const { category, start, timeOfDay } = activityOccurrenceOrGroup;
  const isAnyTimeOfDay = timeOfDay === timeOfDaySchema.Values.Any;

  const isActiveItem = isGroup
    ? activeItem === activityOccurrenceOrGroup.id
    : activeItem === activityOccurrenceOrGroup.id &&
      activeSeriesItem === activityOccurrenceOrGroup.activityId;
  const isActiveSeriesItem = isGroup
    ? undefined
    : activeSeriesItem === activityOccurrenceOrGroup.activityId;

  return (
    <UnstyledLink
      to={
        isGroup
          ? `occurrence-groups/${activityOccurrenceOrGroup.id}`
          : `${activityOccurrenceOrGroup.activityId}/occurrences/${activityOccurrenceOrGroup.id}`
      }
    >
      <div
        className={clsx([
          styles.activityCalendarItem,
          isActiveSeriesItem && styles.activeSeriesItem,
          isActiveItem && styles.activeItem,
        ])}
      >
        <CategoryIcon size="tiny" category={category} />
        {isAnyTimeOfDay ? null : (
          <Text element="span" size="smallest">
            {formattedTime(start)}
          </Text>
        )}
        <Title />
      </div>
    </UnstyledLink>
  );
};

const ActivityCalendarItemPlaceholder = () => (
  <div className={styles.activityCalendarItemPlaceholder} />
);

export { ActivityCalendarItem, ActivityCalendarItemPlaceholder };
