import WarningChip from "@/components/Chips/WarningChip";
import { Text } from "@components/Text/Text";
import { useLingui } from "@lingui/react/macro";

export const ShiftDisplayName = (
  props: { assigned: false } | { assigned: true; employeeName: string },
) => {
  const { t } = useLingui();
  if (!props.assigned) {
    return <WarningChip>{t`Obemannad`}</WarningChip>;
  }
  return <Text element="span">{props.employeeName}</Text>;
};
