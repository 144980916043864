import { plural, t } from "@lingui/core/macro";
import { z } from "zod";
import { timespanSchema, type ITimespan } from "./schedulingModels";
import type { ITimeSensitivity } from "./TimeSensitivityPicker";

export const recurrencesPerDayOptions = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
] as const;

export const recurrencesPerDayOptionSchema = z.enum(recurrencesPerDayOptions);
export type IRecurrencesPerDayOption = z.infer<
  typeof recurrencesPerDayOptionSchema
>;

/**
 * Returns a string with hours and minutes from a string with hours.
 *
 * @param hours
 * @returns A string on the format "= x timmar och y minuter", "= x timmar" or "= y minuter"
 */
export const hoursToHoursAndMinutes = (hours: string): string => {
  const hoursFloat = parseFloat(hours.replace(",", "."));
  const hoursInt = Math.floor(hoursFloat);
  const minutes = Math.round((hoursFloat - hoursInt) * 60);

  const hoursPlural = plural(hoursInt, {
    one: "# timme",
    other: "# timmar",
  });
  const minutesPlural = plural(minutes, {
    one: "# minut",
    other: "# minuter",
  });

  if (hoursInt === 0) {
    return minutesPlural;
  }

  if (minutes === 0) {
    return hoursPlural;
  }

  return t`${hoursPlural} och ${minutesPlural}`;
};

// Go from data format ("HH:MM:SS") to input format (hours as string)
export const timespanToHours = (timespan: ITimespan): ITimeSensitivity => {
  const [hours, minutes] = timespan.split(":").map((time) => parseInt(time));
  const hoursFloat = (hours ? hours : 0) + (minutes ? minutes / 60 : 0);
  return hoursFloat.toFixed(2);
};

// Go from input format (hours as string) to data format ("HH:MM:SS")
export const hoursToTimespan = (
  timeSensitivityInHours: ITimeSensitivity,
): ITimespan => {
  const timeSensitivityAsNumber = parseFloat(
    timeSensitivityInHours.replace(",", "."),
  );

  const [hours, minutes] = [
    Math.floor(timeSensitivityAsNumber),
    Math.floor((timeSensitivityAsNumber % 1) * 60),
  ].map((time) => time.toString().padStart(2, "0"));

  return timespanSchema.parse(`${hours}:${minutes}:00`);
};
