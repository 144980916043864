import { Trans } from "@lingui/react/macro";
import type { ITimeSlotsPickerFields } from "@/forms/AddActivityForm/Scheduling/TimeSlotsPicker";
import { TimeSlotsPicker } from "@/forms/AddActivityForm/Scheduling/TimeSlotsPicker";
import { useParams } from "react-router";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import z from "zod";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  addActivitiesFromGroupTemplate,
  type INewActivity,
} from "@/api/Activities";
import { ExpansionPanel } from "@components/ExpansionPanel/ExpansionPanel";
import styles from "../ActivityTemplatesSivNsvt.module.scss";
import Form from "@/components/Form/Form";
import { FilledButton } from "@components/Button/Button";
import Checkboxes from "@/components/Checkbox/Checkboxes";
import Checkbox from "@/components/Checkbox/Checkbox";
import { deducedError } from "@/Utils/ErrorUtils";
import { timeOfDaySchema } from "@models/activities";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import type { IDateInputFields } from "@/forms/AddActivityForm/Scheduling/DateInput";
import { DateInput } from "@/forms/AddActivityForm/Scheduling/DateInput";
import { medicalCompetenceSchema } from "@models/shifts";
import {
  templates,
  unitSivNsvtGroupTemplateKeysSchema,
} from "@/pages/commandcenter/Patients/Patient/Activities/AddActivity/Templates/UnitSivNsvt/templatesSivNsvt";
import { newActivityFromTemplateSivNsvt } from "@/pages/commandcenter/Patients/Patient/Activities/AddActivity/Templates/UnitSivNsvt/newActivityFromTemplateSivNsvt";
import { GroupTemplateHeadingDefault } from "@/pages/commandcenter/Patients/Patient/Activities/AddActivity/Templates/UnitDefault/TemplateHeadingDefault";
import { TemplateHeadingSivNsvt } from "@/pages/commandcenter/Patients/Patient/Activities/AddActivity/Templates/UnitSivNsvt/TemplateHeadingSivNsvt";
import {
  templateUnitsSchema,
  type ITemplateId,
  type ITemplateRevision,
} from "../../templateModels";
import { generateTemplateId } from "../../templateUtils";

/**
 * Discharge Template Id and revision number
 * NEVER change the Id.
 * Increment revision number when you make changes to the template.
 */
const TEMPLATE_ID: ITemplateId = await generateTemplateId({
  unit: templateUnitsSchema.Values.sivnsvt,
  templateTitle: unitSivNsvtGroupTemplateKeysSchema.Values.discharge,
});
const TEMPLATE_REVISION: ITemplateRevision = 1;

const dischargeActivities = {
  referral: templates.referral,
  finalNote: templates.finalNote,
  updateMedicationList: templates.updateMedicationList,
  informationToPatient: templates.informationToPatient,
};

export const DischargeTemplateSivNsvt = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { patientId } = z.object({ patientId: z.string() }).parse(useParams());
  const queryClient = useQueryClient();

  const methods = useForm<
    ITimeSlotsPickerFields &
      Pick<IDateInputFields, "startDate"> & {
        referral: boolean;
        finalNote: boolean;
        updateMedicationList: boolean;
        informationToPatient: boolean;
      }
  >({
    defaultValues: {
      ...templates.dischargeAdmin,
      referral: false,
      finalNote: false,
      updateMedicationList: false,
      informationToPatient: false,
    },
  });

  const {
    formState: { errors, isDirty },
    getValues,
    handleSubmit,
    register,
    reset,
    setError,
  } = methods;

  const { mutate, isPending } = useMutation({
    mutationFn: async (activities: INewActivity[]) => {
      await addActivitiesFromGroupTemplate({
        templateId: TEMPLATE_ID,
        templateRevision: TEMPLATE_REVISION,
        newActivities: activities,
      });
    },
    onError: (error) => {
      setError("root.server", {
        message: deducedError(error),
      });
      // Reset `isDirty` to support only showing server error when the form is not changed.
      reset(getValues(), {
        keepErrors: true,
        keepIsSubmitted: true,
        keepTouched: true,
        keepIsValid: true,
        keepSubmitCount: true,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries();
      setIsExpanded(false);
    },
  });

  const validateAndSubmit = handleSubmit((formData) => {
    const {
      startDate,
      recurrencesPerDay,
      timeCategory,
      timeslots,
      timeSensitivity,
      referral,
      finalNote,
      updateMedicationList,
      informationToPatient,
    } = formData;

    const timeConfig = {
      recurrencesPerDay,
      timeCategory,
      timeslots,
      timeSensitivity,
    };

    const activities: INewActivity[] = [];

    if (referral) {
      activities.push(
        newActivityFromTemplateSivNsvt({
          template: {
            ...dischargeActivities.referral,
            ...timeConfig,
            timeCategory: timeOfDaySchema.Values.Any,
          },
          startDate,
          patientId,
        }),
      );
    }
    if (finalNote) {
      activities.push(
        newActivityFromTemplateSivNsvt({
          template: {
            ...dischargeActivities.finalNote,
            ...timeConfig,
            timeCategory: timeOfDaySchema.Values.Any,
          },
          startDate,
          patientId,
        }),
      );
    }
    if (updateMedicationList) {
      activities.push(
        newActivityFromTemplateSivNsvt({
          template: {
            ...dischargeActivities.updateMedicationList,
            ...timeConfig,
            timeCategory: timeOfDaySchema.Values.Any,
          },
          startDate,
          patientId,
        }),
      );
    }
    if (informationToPatient) {
      activities.push(
        newActivityFromTemplateSivNsvt({
          template: {
            ...dischargeActivities.informationToPatient,
            ...timeConfig,
            timeCategory: timeOfDaySchema.Values.Any,
          },
          startDate,
          patientId,
        }),
      );
    }

    mutate(activities);
  });

  return (
    <ExpansionPanel
      // Don't translate user-generated content
      // eslint-disable-next-line lingui/no-unlocalized-strings
      trigger={<GroupTemplateHeadingDefault title={`Utskrivning Admin`} />}
      // Don't translate user-generated content
      // eslint-disable-next-line lingui/no-unlocalized-strings
      triggerAriaLabel={`Utskrivning Admin`}
      isExpanded={isExpanded}
      setIsExpanded={setIsExpanded}
    >
      <div className={styles.content}>
        <FormProvider {...methods}>
          <Form onSubmit={validateAndSubmit}>
            <div>
              <TemplateHeadingSivNsvt
                template={{
                  ...templates.dischargeAdmin,
                  requiredCompetences: [
                    medicalCompetenceSchema.Values.NursePractitioner,
                  ],
                }}
              />
              <div className={styles.dayAndTime}>
                <DateInput hasEndDate={false} />
                <TimeSlotsPicker isRecurring={false} />
              </div>
              <div className={styles.checkboxes}>
                <Checkboxes>
                  <Checkbox
                    errorMessage={errors.referral?.message}
                    label={{
                      text: dischargeActivities.referral.title,
                    }}
                    {...register("referral")}
                  />
                  <Checkbox
                    errorMessage={errors.finalNote?.message}
                    label={{
                      text: dischargeActivities.finalNote.title,
                    }}
                    {...register("finalNote")}
                  />
                  <Checkbox
                    errorMessage={errors.updateMedicationList?.message}
                    label={{
                      text: dischargeActivities.updateMedicationList.title,
                    }}
                    {...register("updateMedicationList")}
                  />
                  <Checkbox
                    errorMessage={errors.informationToPatient?.message}
                    label={{
                      text: dischargeActivities.informationToPatient.title,
                    }}
                    {...register("informationToPatient")}
                  />
                </Checkboxes>
              </div>
            </div>
            {errors.root?.server?.message && !isDirty ? (
              <ErrorMessage
                message={errors.root.server.message}
                weight="bold"
              />
            ) : null}
            <FilledButton type="submit" disabled={isPending}>
              <Trans>Lägg till aktiviteter</Trans>
            </FilledButton>
          </Form>
        </FormProvider>
      </div>
    </ExpansionPanel>
  );
};
