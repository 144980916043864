/* eslint-disable lingui/no-unlocalized-strings */
// Don't translate user-generated content

const homeVisitSuggestions = [
  `Andningsgymnastik`,
  `Asctiesdränage`,
  `Basal omvårdnad`,
  `Behandlingsmeddelande`,
  `Bladderscan`,
  `Blodtransfusion`,
  `Byte av pump`,
  `Byte larmklocka`,
  `Byte materiallåda`,
  `Central infart`,
  `EKG`,
  `Förskrivning av hjälpmedel`,
  `Fysisk undersökning`,
  `Förflyttningsbedömning`,
  `Hjälpmedel`,
  `Hämtning materiallåda`,
  `Hämtning tekniklåda`,
  `Infusion dropp`,
  `Koppla pump`,
  `KAD - kateter`,
  `Kontroll infart`,
  `Koppla bort pump`,
  `Leverans läkemedel`,
  `Leverans materiallåda`,
  `Läkemedel subkutan injektion`,
  `Läkemedel intravenöst`,
  `Läkemedel per oralt`,
  `Läkemedelslista`,
  `Medicinsk apparatur i hemmet`,
  `Nutrition`,
  `Nyckelhantering`,
  `Nyckelkvittens`,
  `Omläggning central infart`,
  `Omläggning dränage`,
  `Omläggning perifer infart`,
  `Patientenkät`,
  `Perfier infart`,
  `Picc-line`,
  `Pleuradränage`,
  `PVK - perifer infart`,
  `Provtagning`,
  `Patienttransport`,
  `Rehab bedömning`,
  `Respirationsbedömning`,
  `Riskbedömning`,
  `Samtal anhörig`,
  `Subkutan injektion`,
  `SVP - subkutan venport`,
  `Stödstrumpor`,
  `Sårodling`,
  `Sårvård`,
  `Träningsbedömning`,
  `Träningsprogram`,
  `Urintappning`,
  `Urinodling`,
  `Utskrivning`,
  `Vätske- och urinmätning`,
] as const;

const videoCallSuggestions = [
  `Anhörigsamtal`,
  `Andningsbedömning`,
  `Andningsgymnastik`,
  `Funktionsbedömning`,
  `Förskrivning av hjälpmedel`,
  `Förflyttningsbedömning`,
  `Hjälpmedelsbedömning`,
  `Hjälpmedelsöversyn`,
  `Läkemedelsgenomgång`,
  `Patientundervisning`,
  `Pumpkontroll digital`,
  `Rehab bedömning`,
  `Rond`,
  `Träningsprogram`,
  `Uppföljande samtal`,
] as const;

const patientTaskSuggestions = [
  `Självskattning symptom`,
  `Ta medicin`,
  `Utvärdering`,
  `Vätske- och urinmätning`,
] as const;

const adminTaskSuggestions = [
  `Anhörigsamtal`,
  `Annan vårdgivare`,
  `Behandling på sjukhus`,
  `Bedömning vårdavd`,
  `Bilvård`,
  `Beredning läkemedel`,
  `Diagnostik på sjukhus`,
  `Dosettdelning`,
  `Information till patient`,
  `Inskrivning`,
  `Kontakt annan vårdgivare`,
  `Kontrollera nya flaggningar`,
  `Narkotikakontroll`,
  `Lifecare`,
  `Meddela provsvar`,
  `Möte`,
  `Personalmöte`,
  `Påminnelse medicin`,
  `Påminnelse möte/rond`,
  `Påminnelse vitalparametrar`,
  `Patientundervisning`,
  `Remiss hemrehab`,
  `Uppföljande samtal`,
  `Veckoavstämning`,
] as const;

const patientMeasurementTaskSuggestions = [
  `Egen mätning`,
  `Egen mätning morgon`,
  `Egen mätning kväll`,
  `Egen mätning andningsfrekvens`,
  `Egen mätning blodsocker`,
  `Egen mätning puls`,
  `Egen mätning syremättnad`,
  `Egen mätning temperatur`,
  `Egen mätning vikt`,
] as const;

export const titleSuggestions = {
  homeVisitSuggestions,
  videoCallSuggestions,
  patientTaskSuggestions,
  adminTaskSuggestions,
  patientMeasurementTaskSuggestions,
};
