import { t } from "@lingui/core/macro";
import RadioButton from "@/components/RadioButton/RadioButton";
import RadioButtons from "@/components/RadioButton/RadioButtons";
import { useFormContext } from "react-hook-form";
import { type IRecurringOption, recurringOptions } from "./schedulingModels";
import { recurringDictionary } from "./schedulingModels";
import { useLingui } from "@lingui/react";

export type IRecurrencePickerFields = {
  recurrence: IRecurringOption | null;
};

export const RecurrencePicker = () => {
  const { _ } = useLingui();
  const {
    formState: { errors },
    register,
  } = useFormContext<IRecurrencePickerFields>();

  return (
    <RadioButtons
      errorMessage={errors.recurrence?.message}
      orientation="horizontal"
      legend={t`Upprepning`}
    >
      {recurringOptions.map((option) => (
        <RadioButton
          key={option}
          label={{ text: _(recurringDictionary[option]) }}
          {...register("recurrence", {
            required: {
              value: true,
              message: t`Upprepningsmönster måste sättas`,
            },
          })}
          value={option}
          visualStyle="framed"
        />
      ))}
    </RadioButtons>
  );
};
