import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import {
  activityOccurrenceAndGroupKeys,
  useActivityOccurrence,
} from "@/api/Activities";
import { CompetenceChip } from "@/components/Chips/CompetenceChip";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import { Loading } from "@components/Loading/Loading";
import { deducedError } from "@/Utils/ErrorUtils";
import { useNavigate, useParams } from "react-router";
import { z } from "zod";
import styles from "./SingleActivityOccurrence.module.scss";
import * as Sentry from "@sentry/react";
import { StatusTagWithDropdown } from "@/components/StatusTagWithDropdown/StatusTagWithDropdown";
import { ChangeGroup } from "@/forms/ChangeGroup";
import ArrowLeftCurveIcon from "@components/icons/ArrowLeftCurveIcon";
import { Text } from "@components/Text/Text";
import { EditButton } from "./EditButton";
import { ActivityInformation } from "./ActivityInformation";
import { useCurrentlyEditing } from "./useCurrentlyEditing";
import { EditContainer } from "./EditContainer";
import {
  activityOccurrenceStatusSchema,
  categorySchema,
  type IActivityOccurrenceWithPatient,
} from "@models/activities";
import { isUnfinishedOccurrence } from "@/forms/changeGroupUtils";
import { knownFeatureFlagsSchema, useFeatureFlag } from "@/api/FeatureFlags";
import { useOccurrenceInvoicingCodes } from "@/api/Invoicing";
import { Heading } from "@components/Heading/Heading";
import { useLingui } from "@lingui/react";
import { RemoveActivityOccurrence } from "./RemoveActivityOccurrence";
import { useQueryClient } from "@tanstack/react-query";

const InvoicingCodes = ({
  occurrence,
}: {
  occurrence: IActivityOccurrenceWithPatient;
}) => {
  const { _ } = useLingui();

  const { data: occurrenceInvoicingCodes } = useOccurrenceInvoicingCodes(
    occurrence.id,
  );
  return (
    <section className={styles.informationSection}>
      <div className={styles.editableSection}>
        <Heading level="h3">
          <Trans>Faktureringskoder</Trans>
        </Heading>
      </div>
      {occurrenceInvoicingCodes &&
      (occurrenceInvoicingCodes.procedureCodes.length > 0 ||
        occurrenceInvoicingCodes.productCodes.length > 0) ? (
        <Text element="div">
          <>
            {occurrenceInvoicingCodes.procedureCodes.length > 0 && (
              <>
                <Trans>Åtgärd (KVÅ)</Trans>:
                {occurrenceInvoicingCodes.procedureCodes
                  .map(
                    (procedureCode) =>
                      ` ${procedureCode.code} - ${_(procedureCode.name)}`,
                  )
                  .join(", ")}
              </>
            )}
            {occurrenceInvoicingCodes.productCodes.length > 0 && (
              <>
                <Trans>Produkt:</Trans>
                {occurrenceInvoicingCodes.productCodes
                  .map(
                    (productCode) =>
                      ` ${productCode.code} - ${_(productCode.name)}`,
                  )
                  .join(", ")}
              </>
            )}
          </>
        </Text>
      ) : (
        <Trans>Ingen</Trans>
      )}
    </section>
  );
};

const SingleActivityOccurrence = () => {
  const { activityId, occurrenceId } = z
    .object({ activityId: z.string(), occurrenceId: z.string() })
    .parse(useParams());

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const {
    data: occurrence,
    isPending: occurrenceIsPending,
    isError: occurrenceIsError,
    error: occurrenceError,
  } = useActivityOccurrence(activityId, occurrenceId);

  const {
    data: invoicingActivated,
    isPending: invoicingActivatedIsPending,
    isError: invoicingActivatedIsError,
  } = useFeatureFlag(
    knownFeatureFlagsSchema.Values.PERMANENT_ShowInvoicingInUI,
  );

  const { currentlyEditing, setCurrentlyEditing } = useCurrentlyEditing();

  if (occurrenceIsPending) {
    return <Loading message={t`Hämtar aktivitet`} padding={24} />;
  }

  if (occurrenceIsError) {
    Sentry.captureException(occurrenceError);
    return (
      <ErrorMessage message={deducedError(occurrenceError)} padding={24} />
    );
  }

  const finishedOccurrence =
    occurrence.status === activityOccurrenceStatusSchema.Values.finished;

  const shouldShowInvoicingCodes =
    finishedOccurrence &&
    // if standalone, invoicing codes are handled by ActivityInformation
    occurrence.recurring &&
    invoicingActivated &&
    "patient" in occurrence &&
    (occurrence.category === categorySchema.Values.HomeVisit ||
      occurrence.category === categorySchema.Values.AdminTask);

  return (
    <div>
      <div className={styles.statusAndInfoHeader}>
        <div className={styles.statusAndActionButtons}>
          <div className={styles.statusAndCompetences}>
            <StatusTagWithDropdown
              status={occurrence.status}
              activityId={occurrence.activityId}
              occurrenceId={occurrence.id}
              category={occurrence.category}
              variant="icon-and-text"
            />
            {occurrence.recurring &&
              (occurrence.category === categorySchema.Values.VideoCall ||
                occurrence.category === categorySchema.Values.HomeVisit ||
                occurrence.category === categorySchema.Values.AdminTask) && (
                <ul className={styles.chips}>
                  {occurrence.requiredCompetences.map((competence) => (
                    <li key={competence}>
                      <CompetenceChip competence={competence} state="neutral" />
                    </li>
                  ))}
                </ul>
              )}
            {!finishedOccurrence ? (
              <RemoveActivityOccurrence
                occurrence={occurrence}
                onSuccess={async () => {
                  // This component is always rendered in the context of a list.
                  // Once removal is done, we want to refresh the list, and then navigate to it.
                  // Notably, we don't invalidate the activity occurrence detail, since that causes NotFound errors.
                  await queryClient.invalidateQueries({
                    queryKey: activityOccurrenceAndGroupKeys.lists(),
                  });
                  // Important to navigate back to the list URL since we can't display the detail view any more.
                  // See `index.tsx` for route structure.
                  navigate("../..");
                }}
              />
            ) : null}
          </div>
        </div>
        {occurrence.category === categorySchema.Values.HomeVisit &&
        isUnfinishedOccurrence(occurrence) ? (
          <section className={styles.informationSection}>
            <div className={styles.editableSection}>
              <Text element="p">
                <Trans>Tillfället tillhör ett besök</Trans>
              </Text>

              <EditButton
                attribute="move"
                iconStart={<ArrowLeftCurveIcon />}
                label={t`Flytta`}
                isEditing={currentlyEditing === "move"}
                toggleIsEditing={() =>
                  setCurrentlyEditing(
                    currentlyEditing === "move" ? null : "move",
                  )
                }
              />
            </div>
            {currentlyEditing === "move" ? (
              <EditContainer>
                <ChangeGroup
                  activityOccurrence={occurrence}
                  onSubmitSuccess={() => {
                    setCurrentlyEditing(null);
                  }}
                />
              </EditContainer>
            ) : null}
          </section>
        ) : null}
        {invoicingActivatedIsPending ||
        invoicingActivatedIsError ? null : shouldShowInvoicingCodes ? (
          <InvoicingCodes occurrence={occurrence} />
        ) : null}
      </div>
      <ActivityInformation
        isRecurringTab={false}
        activityId={activityId}
        occurrenceId={occurrenceId}
      />
    </div>
  );
};

export default SingleActivityOccurrence;
