import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import clsx from "clsx";
import styles from "./MessageGroup.module.scss";
import CrossIcon from "../icons/CrossIcon";
import { isToday, isYesterday } from "date-fns";
import Portrait from "../Portrait/Portrait";
import { i18n } from "@lingui/core";

// Duplicated from `chat` package until we figure out best sharing pattern.
type IMessageStatus =
  // The event is in the process of being sent.
  | "sending"
  // The event has been sent to the server, and echo is received.
  | "sent"
  // The event was not sent and will no longer be retried.
  | "not-sent";

// The person using the chat, unless in Center, see below.
type MyMessage = {
  children: React.ReactNode;
  sender: "me";
  timestamp: string;
  status: IMessageStatus;
  readIndicator: Date | boolean | undefined;
  isLatestMessage: boolean;
};

// The person using the (Center) chat, or another person writing from the Center account
type MessageFromCenterShownInCenter = {
  children: React.ReactNode;
  sender: "center-user";
  timestamp: string;
  status: IMessageStatus;
  readIndicator: Date | boolean | undefined;
  isLatestMessage: boolean;
  senderName: string;
  senderTitle: string | null;
};

// If chatting with a patient, this type represents their messages
type PatientMessage = {
  children: React.ReactNode;
  sender: "patient";
  senderName: string;
  timestamp: string;
  status: IMessageStatus;
};

// If chatting with anyone else, this type represents their messages
type OtherPersonsMessage = {
  children: React.ReactNode;
  sender: "other-person";
  senderName: string;
  senderTitle: string | null;
  timestamp: string;
  status: IMessageStatus;
  senderPhotoURL: string | null;
};

export type IMessageGroup =
  | MyMessage
  | OtherPersonsMessage
  | MessageFromCenterShownInCenter
  | PatientMessage;

export const MessageGroup = (props: IMessageGroup) => {
  const { children, sender, status, timestamp } = props;
  const senderName = sender !== "me" ? props.senderName : null;
  const senderTitle =
    sender !== "me" && sender !== "patient" ? props.senderTitle : null;
  const readIndicator =
    sender === "me" || sender === "center-user"
      ? props.readIndicator
      : undefined;
  const senderPhotoURL =
    sender === "other-person" ? props.senderPhotoURL : null;
  const isLatestMessage =
    sender === "me" || sender === "center-user"
      ? props.isLatestMessage
      : undefined;

  const showComplementaryInformation =
    status === "not-sent" || sender === "other-person";

  const placement =
    sender === "other-person" || sender === "patient" ? "left" : "right";

  return (
    <div
      className={clsx(
        styles.messageGroup,
        styles[`placement-${placement}`],
        styles[`status-${status}`],
        !showComplementaryInformation && styles.noComplementaryInformation,
      )}
    >
      <div className={styles.senderAndTimestamp}>
        {sender !== "me" ? (
          <span className={styles.sender}>{senderName}, </span>
        ) : null}
        {senderTitle !== null ? <span>{senderTitle}, </span> : null}
        {status === "sent" ? (
          <time>{timestamp}</time>
        ) : status === "sending" ? (
          <i>
            <Trans>Skickar...</Trans>
          </i>
        ) : (
          <i>
            <Trans>Ej levererat</Trans>
          </i>
        )}
      </div>
      {typeof readIndicator === "object" ? (
        <div className={styles.readIndicator}>
          <span>{formatReadMessageTimestamp(readIndicator)}</span>
        </div>
      ) : readIndicator ? (
        <div className={styles.readIndicator}>
          <span>
            <Trans>Läst</Trans>
          </span>
        </div>
      ) : isLatestMessage && status === "sent" ? (
        <div className={styles.deliveredNotRead}>
          <span>
            <Trans>Levererat, ännu ej läst</Trans>
          </span>
        </div>
      ) : null}
      {showComplementaryInformation ? (
        <div className={styles.complementaryInformation}>
          {status === "not-sent" ? (
            <CrossIcon />
          ) : (
            // showComplementaryInformation implies that senderName is defined
            <Portrait name={senderName!} photoUrl={senderPhotoURL} />
          )}
        </div>
      ) : null}
      <div className={styles.messages}>{children}</div>
    </div>
  );
};

const formatReadMessageTimestamp = (timestamp: Date) => {
  const time = i18n.date(timestamp, { hour: "numeric", minute: "2-digit" });
  const dateName = i18n.date(timestamp, { day: "numeric", month: "long" });
  if (isToday(timestamp)) {
    return t`Läst ${time}`;
  } else if (isYesterday(timestamp)) {
    return t`Lästes igår`;
  } else {
    return t`Lästes ${dateName}`;
  }
};
