import z from "zod";
import { logisticsApi } from "@/api/ApiClient";
import { generateQueryString } from "@/api/Helpers";
import { type IPatientType } from "@models/patients";
import { queryOptions, useQuery } from "@tanstack/react-query";
import { fetchPatient, fetchPatients } from "@/api/Patients";
import {
  activityOccurrenceAndGroupKeys,
  type INewActivity,
} from "@/api/Activities";
import { categorySchema, type IActivityCategory } from "@models/activities";
import {
  type IInvoiceStatus,
  type IInvoicingCodeType,
  type IOptionalInvoicingCodes,
  invoicingActivityOccurrenceOrGroupSchema,
  type IInvoicingActivityOccurrenceOrGroup,
  invoicingActivityOccurrenceOrGroupWithPatientSchema,
  invoicingCodeSchema,
  activityInvoicingCodesSchema,
  occurrenceInvoicingCodesSchema,
} from "@models/invoicing";

export const invoicingActivityOccurrenceOrGroupKeys = {
  all: () => [...activityOccurrenceAndGroupKeys.all(), "invoicing"] as const,
  lists: () =>
    [...invoicingActivityOccurrenceOrGroupKeys.all(), "list"] as const,
  list: (filters: Record<string, unknown>) =>
    [...invoicingActivityOccurrenceOrGroupKeys.lists(), filters] as const,
};

export type INewActivityWithOptionalInvoicingCodes = INewActivity &
  IOptionalInvoicingCodes;

export async function fetchInvoicingCodes({
  patientType,
  codeType,
}: {
  patientType: IPatientType | null;
  codeType: IInvoicingCodeType | null;
}) {
  const queryString = generateQueryString({ codeType, patientType });
  const response = await logisticsApi.get(
    `/invoicing/invoicing-codes${queryString}`,
  );

  return z.array(invoicingCodeSchema).parse(response.data);
}

export const useInvoicingCodes = ({
  patientId,
  codeType,
}: {
  patientId: string | undefined;
  codeType: IInvoicingCodeType | null;
}) => {
  return useQuery({
    queryKey: ["invoicingCodes", patientId, codeType],
    queryFn: async () => {
      if (!patientId) {
        return [];
      }
      const patient = await fetchPatient(patientId);

      return fetchInvoicingCodes({
        patientType: patient.patientType,
        codeType,
      });
    },
  });
};

type ISaveActivityInvoicingCode = {
  activityId: string;
  patientId: string;
  procedureCodes: string[];
  productCodes: string[];
};

export const saveActivityInvoicingCodes = async ({
  activityId,
  patientId,
  procedureCodes,
  productCodes,
}: ISaveActivityInvoicingCode) => {
  await logisticsApi.post(`/invoicing/activities/${activityId}`, {
    patientId,
    procedureCodes,
    productCodes,
  });
};

export const saveMultipleActivitiesInvoicingCodes = async (
  activities: ISaveActivityInvoicingCode[],
) => {
  await logisticsApi.post(`/invoicing/activities/bulk`, {
    activities,
  });
};

export const approveInvoicingOccurrences = async (occurrenceIds: string[]) => {
  await logisticsApi.post(`/invoicing/occurrences/approve`, {
    occurrenceIds,
  });
};

export const fetchOccurrenceInvoiceCodes = async (occurrenceId: string) => {
  const response = await logisticsApi.get(
    `/invoicing/occurrences/${occurrenceId}/invoicing-codes`,
  );

  return occurrenceInvoicingCodesSchema.parse(response.data);
};

export const fetchActivityInvoicingCodes = async (activityId: string) => {
  const response = await logisticsApi.get(
    `/invoicing/activities/${activityId}/invoicing-codes`,
  );

  return activityInvoicingCodesSchema.parse(response.data);
};

export const useActivityInvoicingCodes = (activityId: string) => {
  return useQuery({
    queryKey: ["activityInvoicingCodes", activityId],
    queryFn: async () => fetchActivityInvoicingCodes(activityId),
  });
};

export const useOccurrenceInvoicingCodes = (occurrenceId: string) => {
  return useQuery({
    queryKey: ["occurrenceInvoicingCodes", occurrenceId],
    queryFn: async () => fetchOccurrenceInvoiceCodes(occurrenceId),
  });
};

export const shouldSaveInvoice = ({
  invoicingActivated,
  category,
  procedureCodes,
  productCodes,
}: {
  invoicingActivated: boolean;
  category: IActivityCategory;
  procedureCodes: string[];
  productCodes: string[];
}) => {
  return (
    invoicingActivated &&
    (category === categorySchema.Values.HomeVisit ||
      category === categorySchema.Values.AdminTask) &&
    (procedureCodes.length > 0 || productCodes.length > 0)
  );
};

export async function fetchInvoicingOccurrencesAndGroups({
  invoiceStatuses,
}: {
  invoiceStatuses: IInvoiceStatus[];
}) {
  const queryString = generateQueryString({ status: invoiceStatuses });
  const response = await logisticsApi.get(
    `/invoicing/occurrences${queryString}`,
  );

  const parsedData = z
    .array(invoicingActivityOccurrenceOrGroupSchema)
    .parse(response.data);

  return parsedData;
}

const enrichAndParseInvocingOccurrencesAndGroups = async (
  invoicingOccurrencesAndGroups: IInvoicingActivityOccurrenceOrGroup[],
) => {
  const patientIds = invoicingOccurrencesAndGroups
    .map((invoicingOccurrenceOrGroup) => invoicingOccurrenceOrGroup.patientId)
    .filter((patientId) => !!patientId);

  const patients = await fetchPatients({ patientIds });

  const enrichedInvoicingOccurrencesAndGroups =
    invoicingOccurrencesAndGroups.map((invoicingOccurrenceOrGroup) => {
      const patient = patients.find(
        (patient) => patient.id === invoicingOccurrenceOrGroup.patientId,
      );

      return {
        ...invoicingOccurrenceOrGroup,
        patient,
      };
    });

  const parsedEnrichedInvoicingOccurrencesAndGroups =
    enrichedInvoicingOccurrencesAndGroups.map(
      (enrichedInvoicingOccurrenceOrGroup) =>
        invoicingActivityOccurrenceOrGroupWithPatientSchema.parse(
          enrichedInvoicingOccurrenceOrGroup,
        ),
    );

  return parsedEnrichedInvoicingOccurrencesAndGroups;
};

export const invoicingOccurrencesAndGroupsOptions = ({
  invoicingStatuses,
}: {
  invoicingStatuses: IInvoiceStatus[];
}) =>
  queryOptions({
    queryKey: invoicingActivityOccurrenceOrGroupKeys.list({
      status: invoicingStatuses,
    }),
    queryFn: async () =>
      enrichAndParseInvocingOccurrencesAndGroups(
        await fetchInvoicingOccurrencesAndGroups({
          invoiceStatuses: invoicingStatuses,
        }),
      ),
  });

export async function fetchInvoicingOccurrencesAndGroupsToApprove() {
  const response = await logisticsApi.get(`/invoicing/occurrences/to-approve`);

  const parsedData = z
    .array(invoicingActivityOccurrenceOrGroupSchema)
    .parse(response.data);

  return parsedData;
}

export const invoicingOccurrencesAndGroupsToApproveOptions = queryOptions({
  queryKey: invoicingActivityOccurrenceOrGroupKeys.list({
    perspective: "to-approve",
  }),
  queryFn: async () =>
    enrichAndParseInvocingOccurrencesAndGroups(
      await fetchInvoicingOccurrencesAndGroupsToApprove(),
    ),
});
