const whitespaceOrHyphen = /[\s-]+/;
const whitespace = /\s+/;

// \P{L} matches a single code point NOT in the category "letter".
const removeNonLetters = (word: string): string => {
  return word.replace(/\P{L}/gu, "");
};

const getFirstLetterOfName = (word: string): string => {
  if (!word[0]) {
    return "";
  }
  return word[0];
};

/**
 * Accepts a name (string) and returns the initials of the string.
 *
 * @param name
 * @returns The initials of a name. E.g. "John Doe" -> "JD", "John-Marcus Doe" -> "JMD", "John Marcus Doe" -> "JMD", "John" -> "J"
 */
export const getInitials = (
  name: string,
  options?: { withWhitespace: true },
): string => {
  const initials = name
    .split(whitespaceOrHyphen)
    .map(removeNonLetters)
    .map(getFirstLetterOfName)
    .join(options?.withWhitespace ? " " : "")
    .toUpperCase();
  return initials;
};

/**
 * Accepts a name (string) and returns the first part of the name in full + initals of the last part of the name.
 *
 * @param name
 * @returns The initials of a name. E.g. "John Doe" -> "John D", "John-Marcus Doe" -> "John-Marcus D", "John Marcus Doe" -> "John M D", "John" -> "John"
 */
export const getFirstPartOfNameAndInitials = (name: string): string => {
  const firstPart = name.split(whitespace)[0];
  const lastPart = name.split(whitespace).slice(1).join(" ");
  const initials = getInitials(lastPart, { withWhitespace: true });
  return `${firstPart} ${initials}`;
};
